/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import HeaderContainer from 'layouts/MainLayout/Headers/HeadersComponents/HeaderContainer'
import HeaderGroupContainer from 'layouts/MainLayout/Headers/HeadersComponents/HeaderGroupContainer'
import GoToCasePageButton from 'components/IconButtons/GoToCasePageButton/GoToCasePageButton'
import OpenQuickActionsButton from 'components/IconButtons/OpenQuickActionsButton/OpenQuickActionsButton'
import ReportStatusButton from 'components/ReportStatusButton/ReportStatusButton'

/* Type imports ------------------------------------------------------------- */
import type { EtatRapport } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const HeaderTitleContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;

  display: flex;
  align-items: center;
  font-size: 1rem;
`

const ButtonGroupContainer = styled(HeaderGroupContainer)`
  gap: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarHeaderProps {
  title: string;
  caseId: string;
  reportId?: string;
  reportStatus?: EtatRapport;
  handleClose: () => void;
}

const CaseSidebarHeader: React.FC<CaseSidebarHeaderProps> = ({
  title,
  caseId,
  handleClose,
  reportId,
  reportStatus,
}) => {
  return (
    <HeaderContainer>
      <HeaderGroupContainer>
        <HeaderTitleContainer onClick={handleClose}>
          {title}
          {caseId}
        </HeaderTitleContainer>
      </HeaderGroupContainer>
      <ButtonGroupContainer>
        {
          reportId && reportStatus &&
            <ReportStatusButton
              reportStatus={reportStatus}
              reportId={reportId}
              caseId={caseId}
            />
        }
        <GoToCasePageButton
          caseId={caseId}
          variant="outlined"
        />
        <OpenQuickActionsButton
          caseId={caseId}
        />
      </ButtonGroupContainer>
    </HeaderContainer>
  )
}

export default CaseSidebarHeader
