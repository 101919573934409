/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor'
import CloseButton from 'components/CloseButton/CloseButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'

/* Type declarations -------------------------------------------------------- */
const newPostItSchema = Yup.object({
  message: Yup.string(),
})

type NewPostItRequest = Yup.InferType<typeof newPostItSchema>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

const CardContainer = styled(Card)`
  margin-bottom: 15px;
  background-color: #F6F9FC;
`

/* Component declaration ---------------------------------------------------- */
interface PostItModalProps {
  open: boolean;
  handleClose: () => void;
  postIt?: string | null;
}

const PostItModal: React.FC<PostItModalProps> = ({
  open,
  handleClose,
  postIt,
}) => {
  const [ editorRef, setEditorRef ] = useState<RichTextEditorComponent | null>()

  const onSubmit = (values: NewPostItRequest, { setSubmitting }: FormikHelpers<NewPostItRequest>): void => {
    console.log('values', values)
    console.log('editor value', editorRef?.value)
    setSubmitting(false)
  }

  const formikForm = useForm<NewPostItRequest>(
    {
      initialValues: {
        message: '',
      },
      onSubmit: onSubmit,
      validationSchema: newPostItSchema,
    },
  )

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      maxWidth="sm"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Post It
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContentContainer>
          <CardContainer>
            <CardContent>
              {postIt}
            </CardContent>
          </CardContainer>
          <CardContent>
            <FormBoldTitle>
              Nouveau Post It :
            </FormBoldTitle>
            <RichTextEditorComponent
              value='<div>test
                  <br></br>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGeDA0AyKqNdYRz1ai2a3NgQgLaeuBYKgTdBqQLAM&s" height="60px" width="60px" alt="euroInfoLogo" id="photo">
                  </img>
                </div>'
              id="defaultRTE"
              ref={setEditorRef}
              toolbarSettings={
                {
                  items:
                    [ 'Bold', 'Italic', 'Underline', 'StrikeThrough',
                      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                      '|', 'Undo', 'Redo',
                      'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                      'Outdent', 'Indent', '|',
                      'LowerCase', 'UpperCase', 'ClearFormat', 'FullScreen',
                    ],
                }
              }
            >
              <Inject services={[ HtmlEditor, Toolbar, Image, Link, QuickToolbar ]} />
            </RichTextEditorComponent>
          </CardContent>
        </DialogContentContainer>
        <DialogActionContainer>
          <FormButton onClick={handleClose}>
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default PostItModal
