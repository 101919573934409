/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useIsConnected } from 'helpers/hooks/useIsConnected'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import ReportClosureModal from './ReportClosureModal/ReportClosureModal'

/* Type imports ------------------------------------------------------------- */
import type { SelectChangeEvent } from '@mui/material'
import { EtatRapport } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type StatusColor = {color: string; backgroundColor: string}
type Color = {[x: string]: StatusColor}

/* Internal variables ------------------------------------------------------- */
const colors: Color = {
  [EtatRapport.Qualification]: {
    color: '#BD2323',
    backgroundColor: '#FCECEC',
  },
  [EtatRapport.Gestion]: {
    color: '#0A145F',
    backgroundColor: '#E0E3FB',
  },
  [EtatRapport.Cloture]: {
    color: '#125A26',
    backgroundColor: '#D5F5DE',
  },
}

/* Styled components -------------------------------------------------------- */
interface EtatRapportColorProps {
  customcolor: string;
  custombackgroundcolor?: string;
}

const SelectColoredArrow = styled(KeyboardArrowDown)<EtatRapportColorProps>`
  color: ${(props) => props.customcolor};
  font-size: 28px;
  margin-left: -30px;
  position: absolute !important;
  right: 0 !important;
  pointer-events: none !important;
`

const ColoredOutlinedInput = styled(OutlinedInput)<EtatRapportColorProps>`
  color: ${(props) => props.customcolor};
  background-color: ${(props) => props.custombackgroundcolor};
  font-weight: bold;
  border: 2px solid ${(props) => props.customcolor};

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &:hover {
    background-color: ${(props) => `${props.custombackgroundcolor}55`};
  }
`

const ColoredButton = styled(Button)<EtatRapportColorProps>`
  color: ${(props) => props.customcolor};
  background-color: ${(props) => props.custombackgroundcolor};
  font-size: 16px;
  border: 2px solid ${(props) => props.customcolor};
  padding: 5px 15px;
  height: 100%;
`

const ColoredMenuItem = styled(MenuItem)<EtatRapportColorProps>`
  color: ${(props) => props.customcolor};
`

const EtatRapportContainer = styled.div`
  margin-left: 20px;

  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall {
    font-size: 14px;
  }

  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root {
    font-size: 14px;
    height: 44px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface EtatRapportButtonProps {
  caseId: string;
  reportId: string;
  reportStatus: EtatRapport;
}

const EtatRapportButton: React.FC<EtatRapportButtonProps> = ({
  caseId,
  reportId,
  reportStatus,
}) => {
  const isConnected = useIsConnected()
  const [ newStatus, setNewStatus ] = useState<EtatRapport>(reportStatus)
  const [ isClosureModalOpen, setIsClosureModalOpen ] = useState<boolean>(false)

  useEffect(() => {
    setNewStatus(reportStatus)
  }, [ reportStatus ])

  const handleChange = (event: SelectChangeEvent): void => {
    if (event.target.value as EtatRapport !== EtatRapport.Cloture) {
      setNewStatus(event.target.value as EtatRapport)
    }
  }

  const handleClose = (isReportClosed?: boolean): void => {
    if (isReportClosed) {
      setNewStatus(EtatRapport.Cloture)
    }
    setIsClosureModalOpen(false)
  }

  const onReportSubmit = (): void => {
    setIsClosureModalOpen(true)
  }

  return (
    <EtatRapportContainer onClick={(e): void => e.stopPropagation()}>
      {
        newStatus === EtatRapport.Cloture ?
          <ColoredButton
            customcolor={colors[newStatus].color}
            custombackgroundcolor={colors[newStatus].backgroundColor}
          >
            Clôturé
          </ColoredButton> :
          <Select
            value={newStatus}
            onChange={handleChange}
            input={
              <ColoredOutlinedInput
                customcolor={colors[newStatus].color}
                custombackgroundcolor={colors[newStatus].backgroundColor}
                size="small"
              />
            }
            IconComponent={(): React.ReactElement => <SelectColoredArrow customcolor={colors[newStatus].color} />}
          >
            <ColoredMenuItem
              key={EtatRapport.Qualification}
              value={EtatRapport.Qualification}
              customcolor={colors[EtatRapport.Qualification].color}
            >
              Qualification
            </ColoredMenuItem>
            <ColoredMenuItem
              key={EtatRapport.Gestion}
              value={EtatRapport.Gestion}
              customcolor={colors[EtatRapport.Gestion].color}
            >
              Gestion
            </ColoredMenuItem>
            <ColoredMenuItem
              key={EtatRapport.Cloture}
              value={EtatRapport.Cloture}
              customcolor={colors[EtatRapport.Cloture].color}
              onClick={onReportSubmit}
              disabled={!isConnected}
            >
              Clôture
            </ColoredMenuItem>
          </Select>
      }
      {
        isClosureModalOpen &&
          <ReportClosureModal
            caseId={caseId}
            reportId={reportId}
            handleClose={handleClose}
          />
      }
    </EtatRapportContainer>
  )
}

export default EtatRapportButton
