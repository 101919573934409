/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { handleNumberVerificationAllowEmpty } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import PriceDiv from 'components/FieldWithInputAdornment/PriceDiv'
import CompensationTableRow from './CompensationTableComponents/CompensationTableRow'
import CompensationTableBoldUppercaseTitle from './CompensationTableComponents/CompensationTableBoldUppercaseTilte'
import CompensationTableBoldSeparator from './CompensationTableComponents/CompensationTableBoldSeparator'
import CompensationPersonTableCard from './CompensationTableComponents/CompensationPersonTableCard'

/* Type imports ------------------------------------------------------------- */
import type { DommageSinapps } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const TableDommageSinappsRow = styled(CompensationTableRow)`
  grid-template-columns: 11% repeat(5, 9%) 11% 12%;
  gap: 2.5%;
`

/* Component declaration ---------------------------------------------------- */
interface CompensationPersonSinappsDamageTableProps {
  personIndexName: string;
  dommagesSinapps: DommageSinapps[];
  isHT: boolean;
  disabled: boolean;
  handleValue: (type: string, value: number | '') => void;
}

const CompensationPersonSinappsDamageTable: React.FC<CompensationPersonSinappsDamageTableProps> = ({
  personIndexName,
  dommagesSinapps,
  isHT,
  disabled,
  handleValue,
}) => {

  const getLibelle = (line: DommageSinapps) => {
    const nature = line.natureDuBien?.libelle || line?.natureDuBien?.code || ''
    const detail = line.detailDuBien?.libelle || line.detailDuBien?.code || ''

    const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

    return `${capitalizeFirstLetter(nature)}${nature && detail ? ' / ' : ''}${capitalizeFirstLetter(detail)}`
  }

  return (
    <>
      <FormBoldTitle bigger>
        Récapitulatif des dommages Sinapps
      </FormBoldTitle>
      <CompensationPersonTableCard>
        <CompensationTableBoldUppercaseTitle>
          <TableDommageSinappsRow>
            <div>
              Détail des dommages
            </div>
            <div>
              Dommages HT
            </div>
            <div>
              Montant TVA
            </div>
            <div>
              Dommages TTC
            </div>
            <div>
              Montant Vétusté
            </div>
            <div>
              Vétusté déduite
            </div>
            <div>
              Plafond
            </div>
            <div>
              Mode d'indemnisation
            </div>
          </TableDommageSinappsRow>
        </CompensationTableBoldUppercaseTitle>
        <CompensationTableBoldSeparator />
        {
          dommagesSinapps.map((dommage, index) => (
            <div key={`${dommage.id}-${index}`}>
              <TableDommageSinappsRow border={index !== (dommagesSinapps?.length || 0) -1}>
                <div>
                  {getLibelle(dommage)}
                </div>
                <PriceDiv price={dommage.montantHT} />
                <PriceDiv price={dommage.montantTva} />
                <PriceDiv price={dommage.montantTTC} />
                <PriceDiv price={isHT ? dommage.montantVetusteHT : dommage.montantVetusteTTC} />
                <PriceDiv price={isHT ? dommage.montantVetusteDeduiteHT : dommage.montantVetusteDeduiteTTC} />
                <PriceField
                  placeholder="Plafond"
                  size="small"
                  value={dommage.plafond || ''}
                  name={`${personIndexName}.dommagesSinapps.[${index}].plafond`}
                  onChange={(e) => handleValue(`${personIndexName}.dommagesSinapps.[${index}].plafond`, handleNumberVerificationAllowEmpty(e.target.value, 2))}
                  disabled={disabled}
                />
                <div>
                  {dommage.modeIndemnisation?.libelle}
                </div>
              </TableDommageSinappsRow>
            </div>
          ))
        }
        <CompensationTableBoldSeparator />
        <FormBoldTitle smaller>
          <TableDommageSinappsRow>
            <div>
              Totaux
            </div>
            <PriceDiv price={dommagesSinapps.reduce((a, b) => a + Number(b.montantHT), 0)} />
            <PriceDiv price={dommagesSinapps.reduce((a, b) => a + Number(b.montantTva), 0)} />
            <PriceDiv price={dommagesSinapps.reduce((a, b) => a + Number(b.montantTTC), 0)} />
            <PriceDiv price={dommagesSinapps.reduce((a, b) => a + Number(isHT ? b.montantVetusteHT : b.montantVetusteTTC), 0)} />
            <PriceDiv price={dommagesSinapps.reduce((a, b) => a + Number(isHT ? b.montantVetusteDeduiteHT : b.montantVetusteDeduiteTTC), 0)} />
            <PriceDiv price={dommagesSinapps.reduce((a, b) => a + Number(b.plafond || 0), 0)} />
          </TableDommageSinappsRow>
        </FormBoldTitle>
      </CompensationPersonTableCard>
    </>
  )
}

export default CompensationPersonSinappsDamageTable
