/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  MenuItem,
  Select,
} from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import PriceDiv from 'components/FieldWithInputAdornment/PriceDiv'
import CompensationTableRow from './CompensationTableComponents/CompensationTableRow'
import CompensationTableBoldUppercaseTitle from './CompensationTableComponents/CompensationTableBoldUppercaseTilte'
import CompensationTableBoldSeparator from './CompensationTableComponents/CompensationTableBoldSeparator'
import CompensationPersonTableCard from './CompensationTableComponents/CompensationPersonTableCard'

/* Type imports ------------------------------------------------------------- */
import type {
  CodeLabel,
  Garantie,
  Indemnisation,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface TableIndemnisationRowProps {
  isSinapps: boolean;
}

const TableIndemnisationRow = styled(CompensationTableRow)<TableIndemnisationRowProps>`
  grid-template-columns: ${(props) => props.isSinapps ? '12% repeat(3, 8%) 12% 10% 18%' : '12% repeat(3, 8%) 12% 10%'};
  gap: ${(props) => props.isSinapps ? '4%' : '8%'};
`

/* Component declaration ---------------------------------------------------- */
interface CompensationPersonCompensationTableProps {
  indemnisations: Indemnisation[];
  sinappsGarantieList: CodeLabel[];
  garanties: Garantie[];
  personIndexName: string;
  handleValue: (type: string, value?: CodeLabel) => void;
  disabled: boolean;
}

const CompensationPersonCompensationTable: React.FC<CompensationPersonCompensationTableProps> = ({
  indemnisations,
  sinappsGarantieList,
  garanties,
  personIndexName,
  handleValue,
  disabled,
}) => {

  const handleGarantieSelect = (value: string, index: number): void => {
    handleValue(
      `${personIndexName}.indemnisations.[${index}].garantie`,
      garanties.find((garantie) => garantie.code.code === value)?.code || undefined,
    )
    indemnisations.forEach((indemn, indexIndemn) => {
      if (indexIndemn === index) {
        return
      }
      if (!isValidString(indemn.garantie?.code)) {
        handleValue(
          `${personIndexName}.indemnisations.[${indexIndemn}].garantie`,
          garanties.find((garantie) => garantie.code.code === value)?.code || undefined,
        )
      }
    })
  }

  const getLibelle = (line: Indemnisation) => {
    const nature = line.natureDuBien?.libelle || line.natureDuBien?.code || ''
    const detail = line.detailDuBien?.libelle || line.detailDuBien?.code || ''

    const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

    return `${capitalizeFirstLetter(nature)}${nature && detail ? ' / ' : ''}${capitalizeFirstLetter(detail)}`
  }

  return (
    <>
      <FormBoldTitle bigger>
        Indemnisation
      </FormBoldTitle>
      <CompensationPersonTableCard>
        <CompensationTableBoldUppercaseTitle>
          <TableIndemnisationRow isSinapps={sinappsGarantieList.length > 0}>
            <div>
              Libellé
            </div>
            <div>
              Montant HT
            </div>
            <div>
              Montant TTC
            </div>
            <div>
              VVD TTC
            </div>
            <div>
              Vétusté récupérable
            </div>
            <div>
              Découvert
            </div>
            {
              sinappsGarantieList.length > 0 &&
                <div>
                  Garantie
                </div>
            }
          </TableIndemnisationRow>
        </CompensationTableBoldUppercaseTitle>
        <CompensationTableBoldSeparator />
        {
          indemnisations.map((indemnisation, index) => (
            <TableIndemnisationRow
              border={index !== (indemnisations?.length || 0) -1}
              key={`${indemnisation.id}-${index}`}
              isSinapps={sinappsGarantieList.length > 0}
            >
              <div>
                {getLibelle(indemnisation)}
              </div>
              <PriceDiv price={indemnisation.montantHT} />
              <PriceDiv price={indemnisation.montantTTC} />
              <PriceDiv price={indemnisation.montantVetusteDeduiteTTC} />
              <PriceDiv price={indemnisation.montantVetusteRecuperable} />
              <PriceDiv price={indemnisation.montantDecouvert} />
              {
                sinappsGarantieList.length > 0 &&
                  <Select
                    value={indemnisation.garantie?.code || ''}
                    onChange={(e): void => handleGarantieSelect(e.target.value, index)}
                    disabled={disabled}
                    size="small"
                    displayEmpty
                  >
                    <MenuItem
                      value=""
                      key=""
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      garanties.map((value, index) => (
                        <MenuItem
                          value={value.code.code}
                          key={`${value.code.code}-${index}`}
                        >
                          {value.code.libelle}
                        </MenuItem>
                      ))
                    }
                  </Select>
              }
            </TableIndemnisationRow>
          ))
        }
        <CompensationTableBoldSeparator />
        <FormBoldTitle smaller>
          <TableIndemnisationRow isSinapps={sinappsGarantieList.length > 0}>
            <div>
              Totaux
            </div>
            <PriceDiv price={indemnisations.reduce((a, b) => a + Number(b.montantHT), 0)} />
            <PriceDiv price={indemnisations.reduce((a, b) => a + Number(b.montantTTC), 0)} />
            <PriceDiv price={indemnisations.reduce((a, b) => a + Number(b.montantVetusteDeduiteTTC), 0)} />
            <PriceDiv price={indemnisations.reduce((a, b) => a + Number(b.montantVetusteRecuperable), 0)} />
            <PriceDiv price={indemnisations.reduce((a, b) => a + Number(b.montantDecouvert), 0)} />
          </TableIndemnisationRow>
        </FormBoldTitle>
      </CompensationPersonTableCard>
    </>
  )
}

export default CompensationPersonCompensationTable
