/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import CaseSidebarItemComponents from 'layouts/CaseSidebar/CaseSidebarComponents/CaseSidebarItemComponents/CaseSidebarItemComponents'

/* Type imports ------------------------------------------------------------- */
import type { Tache } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DateContainer = styled.div`
  display: inline;
  float: right;
`

const Line = styled.h5`
  font-weight: normal;
  margin: 5px 0px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarTaskItemProps {
  task: Tache;
}

const CaseSidebarTaskItem: React.FC<CaseSidebarTaskItemProps> = ({ task }) => {

  return (
    <CaseSidebarItemComponents.Card>
      <Line>
        <b>
          {task.libelle}
        </b>
        <DateContainer>
          {DateUtils.APIStrToLocalDateString(task.dateCreation)}
        </DateContainer>
      </Line>
      {
        isValidString(task.prevuPour) &&
          <Line>
            Échéance :
            {' '}
            <b>
              {DateUtils.APIStrToLocalDateString(task.prevuPour)}
            </b>
          </Line>
      }
    </CaseSidebarItemComponents.Card>
  )
}

export default CaseSidebarTaskItem
