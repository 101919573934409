/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Type imports ------------------------------------------------------------- */
import type { EventContentArg } from '@fullcalendar/core'
import type { Planning } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface ColoredBarProps {
  color: string;
}

const MonthBar = styled.div<ColoredBarProps>`
  background-color: ${(props) => props.color};
  border: ${(props) => props.color === '#FFFFFF' ? '1px solid #C0C0C0' : 'none'};
  width: 100%;
  height: 8px;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarEventMonthViewProps {
  eventInfo: EventContentArg;
  event?: Planning;
}

const PlanningCalendarEventMonthView: React.FC<PlanningCalendarEventMonthViewProps> = ({
  eventInfo,
  event,
}) => {

  return (
    <MonthBar
      id={event ? `${event.commentaire}#${event.typeRDV?.libelle}` : `${eventInfo.event.id}`}
      color={eventInfo.event.backgroundColor}
    />
  )
}

export default PlanningCalendarEventMonthView
