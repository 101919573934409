/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useGetEpassPortalQuery,
  useGetIrsiPortalQuery,
  useGetSinappsPortalQuery,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
} from '@mui/material'
import CaseSidebarItemComponents from 'layouts/CaseSidebar/CaseSidebarComponents/CaseSidebarItemComponents/CaseSidebarItemComponents'

/* Type imports ------------------------------------------------------------- */
import type {
  Dossier,
  PersonneResumeAvecIntervenants,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const PlatformContainer = styled.div`
  margin-top: 20px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
`

const CaseSidebarPersonName = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarPersonProps {
  caseId: string;
  isFetching: boolean;
  caseInfos?: Dossier;
  insuredPerson?: PersonneResumeAvecIntervenants;
  isConnected: boolean;
}

const CaseSidebarPerson: React.FC<CaseSidebarPersonProps> = ({
  caseId,
  isFetching,
  caseInfos,
  insuredPerson,
  isConnected,
}) => {

  const {
    currentData: sinappsPortal,
    isFetching: isFetchingSinappsPortal,
  } = useGetSinappsPortalQuery(caseId, { skip: !caseId || caseInfos?.mission.origine?.code !== 'SIN' })
  const {
    currentData: irsiPortal,
    isFetching: isFetchingIrsiPortal,
  } = useGetIrsiPortalQuery(caseId, { skip: !caseId || caseInfos?.conventionApplicable?.code !== 'F' })
  const {
    currentData: epassPortal,
    isFetching: isFetchingEpassPortal,
  } = useGetEpassPortalQuery(caseId, { skip: !caseId })

  if (isFetching || isFetchingSinappsPortal || isFetchingIrsiPortal || isFetchingEpassPortal) {
    return <CircularProgress />
  } else if (caseInfos === undefined) {
    return null
  }

  return (
    <CaseSidebarItemComponents.Card>
      <CaseSidebarPersonName>
        {`${insuredPerson?.nom || ''} ${insuredPerson?.prenom || ''}`}
      </CaseSidebarPersonName>
      <div>
        {`${caseInfos.sinistre?.nature?.libelle || ''} le ${DateUtils.APIStrToLocalDateString(caseInfos.sinistre?.date)}`}
      </div>
      <div>
        {
          `${caseInfos.sinistre?.adresse?.adresse1 || ''} ${caseInfos.sinistre?.adresse?.adresse2 || ''} ${caseInfos.sinistre?.adresse?.adresse3 || ''}\
          ${caseInfos.sinistre?.adresse?.codePostal || ''} ${caseInfos.sinistre?.adresse?.ville || ''}`
        }
      </div>
      {
        caseInfos.compagnie.libelle &&
          <div>
            {`Mandant : ${caseInfos.compagnie.libelle}`}
          </div>
      }
      <PlatformContainer>
        Accès plateformes :
        <ButtonContainer>
          {
            sinappsPortal &&
              <Button
                variant="outlined"
                disabled={!isConnected}
                fullWidth
                onClick={() => window.open(sinappsPortal, '_blank', 'noreferrer')}
              >
                SINAPPS
              </Button>
          }
          {
            irsiPortal &&
              <Button
                variant="outlined"
                fullWidth
                disabled={!isConnected}
                onClick={() => window.open(irsiPortal, '_blank', 'noreferrer')}
              >
                IRSI
              </Button>
          }
          <Button
            variant="outlined"
            fullWidth
            disabled={!isConnected || !epassPortal}
            onClick={() => window.open(epassPortal, '_blank', 'noreferrer')}
          >
            Assureur
          </Button>
        </ButtonContainer>
      </PlatformContainer>
    </CaseSidebarItemComponents.Card>
  )
}

export default CaseSidebarPerson
