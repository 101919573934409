/* Module imports ----------------------------------------------------------- */
import {
  createSinappsGUID,
  getGarantie,
} from './helpers/indemnisationSinnaps'

/* Type imports ------------------------------------------------------------- */
import {
  TypeDommageSinapps,
  type Dommage,
  type DommageBienSinapps,
  type Indemnisation,
} from './types/DommageIndemnisation'

/* Function declarations ---------------------------------------------------- */
export const getListeBiensSinapps = (dommages: Dommage[], indemnisations: Indemnisation[], existingBienSinapps: DommageBienSinapps[]): DommageBienSinapps[] => {
  const biensSinapps: DommageBienSinapps[] = []
  const natureSinappsGUIDdictionary: { [key: string]: string } = {}

  dommages.forEach((dmg) => {
    if (dmg.sinapps.typeDommageSinapps === TypeDommageSinapps.Bien && dmg.valeur.montantTTC > 0) {
      if (!natureSinappsGUIDdictionary[dmg.sinapps.natureDuBienSinapps.code]) {
        natureSinappsGUIDdictionary[dmg.sinapps.natureDuBienSinapps.code] = createSinappsGUID()
      }

      biensSinapps.push({
        ...dmg,
        ...dmg.valeur,
        ...dmg.vetuste,
        montantVetusteHT: dmg.vetuste.montantVetuste / (1 + dmg.valeur.tauxTva.taux / 100),
        montantVetusteTTC: dmg.vetuste.montantVetuste,
        montantVetusteRecuperableHT: dmg.vetuste.montantVetusteRecuperable / (1 + dmg.valeur.tauxTva.taux / 100),
        montantVetusteRecuperableTTC: dmg.vetuste.montantVetusteRecuperable,
        plafond: existingBienSinapps.find((d) => d.idDetailDommageSinapps === dmg.sinapps.idDetailDommageSinapps)?.plafond,
        idDommageSinapps: natureSinappsGUIDdictionary[dmg.sinapps.natureDuBienSinapps.code],
        idDetailDommageSinapps: dmg.sinapps.idDetailDommageSinapps || createSinappsGUID(),
        garantie: getGarantie(dmg, indemnisations),
        natureDuBienSinapps: dmg.sinapps.natureDuBienSinapps,
        reparabilite: dmg.vetuste.reparation?.reparabilite,
        justificatif: dmg.vetuste.reparation.justificatif,
        dateAchat: dmg.vetuste.dateAchat,
      })
    }
  })

  return biensSinapps
}
