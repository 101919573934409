/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'
import type { FileType } from 'helpers/getMediaFileType'

/* Styled components -------------------------------------------------------- */
interface MediaLibraryContainerProps {
  sheetHeight: number;
}

const MediaLibraryContainer = styled.div<MediaLibraryContainerProps>`
  height: ${(props) => props.sheetHeight}px;
  text-align: center;
  overflow: hidden;
`

/* Component declaration ---------------------------------------------------- */
interface MediaLibraryDataProps {
  displayType: FileType;
  sheetHeight: number;
  document: PieceJointe;
}

const MediaLibraryData: React.FC<MediaLibraryDataProps> = ({
  displayType,
  sheetHeight,
  document,
}) => {

  return (
    <MediaLibraryContainer sheetHeight={sheetHeight}>
      {
        displayType === 'img' ?
          <img
            src={document?.url || ''}
            alt={document?.fileName}
            height="100%"
          /> :
          displayType === 'pdf' &&
            <iframe
              src={document?.url || ''}
              width="100%"
              height="100%"
              title={document.libelle}
            />
      }
    </MediaLibraryContainer>
  )
}

export default MediaLibraryData
