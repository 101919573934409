/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import HeaderTitleContainer from './HeaderTitleContainer'

/* Component declaration ---------------------------------------------------- */
interface HeaderTitleProps {
  title: string;
}

const HeaderTitle: React.FC<HeaderTitleProps> = ({ title }) => {
  return (
    <HeaderTitleContainer>
      {title}
    </HeaderTitleContainer>
  )
}

export default HeaderTitle
