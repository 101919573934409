/* Module imports ----------------------------------------------------------- */
import {
  useAppSelector,
  useAppDispatch,
} from 'store/hooks'
import {
  open,
  close,
  toggle,
  getCaseSidebarState,
} from 'store/slices/caseSidebarSlice'

/* Type imports ------------------------------------------------------------- */
import type { CaseSidebarState } from 'store/slices/caseSidebarSlice'

/* Hook declarations -------------------------------------------------------- */
export const useCaseSidebar = () => {
  const dispatch = useAppDispatch()

  const toggleCaseSidebar = (): { payload: undefined; type: string } => dispatch(toggle())
  const openCaseSidebar = (payload: CaseSidebarState): { payload: CaseSidebarState; type: string } => dispatch(open(payload))
  const closeCaseSidebar = (): { payload: undefined; type: string } => dispatch(close())

  const caseSidebarState: CaseSidebarState = useAppSelector(getCaseSidebarState)

  return {
    toggle: toggleCaseSidebar,
    open: openCaseSidebar,
    close: closeCaseSidebar,
    state: caseSidebarState,
  }
}
