/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  Collapse,
  Card,
  CardContent,
} from '@mui/material'
import { ExpandMoreRounded } from '@mui/icons-material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import StakeholdersPersonLine from './StakeholdersLines/StakeholdersPersonLine'

/* Type imports ------------------------------------------------------------- */
import type {
  ActeurTraveller,
  RecoursTraveller,
  RendezVousTraveller,
} from 'API/__generated__/Api'
import type {
  RdvActeur,
  RdvIntervenant,
} from 'types/Stakeholders'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const CardTitleContainer = styled(CardContent)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 0;
`

const CardContentContainer = styled(CardContent)`
  padding-top: 0;
`

const RdvTitle = styled(FormBoldTitle)`
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 20px;
`

const RdvStatusChip = styled(ColoredSquareChip)`
  min-width: 90px;
`

interface DropDownArrowProps {
  expanded: string;
}

const DropDownArrow = styled(ExpandMoreRounded)<DropDownArrowProps>`
  font-size: 2.2rem;
  margin-left: auto;

  transform: scaleY(${(props) => props.expanded === 'true' ? -1 : 1});
  transition: transform .2s;
  color: ${(props) => props.theme.palette.secondary.main};
`

const BoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  margin-top: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface StakeholdersRdvCardProps {
  rdv: RendezVousTraveller;
  acteurs: ActeurTraveller[];
  index: number;
  handleFormValue: (type: string, value: string | boolean) => void;
  handleDelete: (type: string, value: boolean | RecoursTraveller[] | ActeurTraveller) => void;
  disabled: boolean;
}

const StakeholdersRdvCard: React.FC<StakeholdersRdvCardProps> = ({
  rdv,
  index,
  acteurs,
  handleFormValue,
  handleDelete,
  disabled,
}) => {
  const [ expanded, setExpanded ] = useState<boolean>(index === 0)
  const [ rdvActeurs, setRdvActeurs ] = useState<RdvActeur[]>([])

  useEffect(() => {
    const newRdvActeurs: RdvActeur[] = []

    rdv.acteursStatus.forEach((rdvActeur) => {
      const found = acteurs.find((acteur) => acteur.id === rdvActeur.acteurId)
      if (found !== undefined && !found.isDeleted) {
        const newIntervenants: RdvIntervenant[] = []
        found.intervenants?.forEach((intervenant) => {
          const foundIntervenant = rdv.acteursStatus.find((rdvIntervenant) => rdvIntervenant.acteurId === intervenant.id)
          if (foundIntervenant) {
            newIntervenants.push({ intervenant: intervenant, statut: foundIntervenant })
          }
        })
        newRdvActeurs.push({ acteur: found, statut: rdvActeur, intervenants: newIntervenants })
      }
    })
    setRdvActeurs(newRdvActeurs)
  }, [ acteurs, rdv ])

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  const handleActeurStatutChange = (acteurId: string, type: string, value: string | boolean): void => {
    handleFormValue(`acteursStatus[${rdv.acteursStatus.findIndex((acteur) => acteur.acteurId === acteurId)}].${type}`, value)
  }

  const handlePersonDelete = (rdvActeurIndex: number, stakeholderId?: string) => {
    const personIndex = acteurs.findIndex((person) => rdvActeurs[rdvActeurIndex].acteur.id === person.id)

    if (isValidString(stakeholderId)) {
      const stakeholderIndex = acteurs[personIndex].intervenants?.findIndex((stakeholder) => stakeholder.id === stakeholderId)
      handleDelete(`acteurs[${personIndex}].intervenants[${stakeholderIndex}].isDeleted`, true)
      handleDelete(`acteurs[${personIndex}].recours`, acteurs[personIndex].recours?.filter((recourse) => recourse.cibleRecours.assureurCible?.id !== stakeholderId) || [])
    }
    if (!isValidString(stakeholderId)) {
      const cleanedPerson: ActeurTraveller = {
        ...acteurs[personIndex],
        isDeleted: true,
        dommagesMobilierDivers: [],
        pieces: [],
        recours: [],
        intervenants: acteurs[personIndex].intervenants?.map((stakeholder) => ({ ...stakeholder, isDeleted: true })),
        indemnisation: {
          ...acteurs[personIndex].indemnisation,
          indemnisations: [],
        },
      }
      handleDelete(`acteurs[${personIndex}]`, cleanedPerson)
    }
  }

  return (
    <CardContainer>
      <CardTitleContainer onClick={handleExpandClick}>
        <RdvTitle>
          {
            rdv.libelle ||
              <>
                Rendez-vous du
                {' '}
                {
                  DateUtils.APIStrToLocalDateString(rdv.dateRDV, {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })
                }
              </>
          }
        </RdvTitle>
        <RdvStatusChip color={rdv.enCours ? 'orange' : 'green'}>
          {rdv.enCours ? 'À venir' : 'Réalisé'}
        </RdvStatusChip>
        <DropDownArrow expanded={expanded.toString()} />
      </CardTitleContainer>
      <BoldSeparator />
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <CardContentContainer>
          {
            rdvActeurs.map((rdvActeur, rdvActeurIndex) => (
              <StakeholdersPersonLine
                key={`${rdvActeur.acteur.id}-${rdvActeurIndex}`}
                person={rdvActeur}
                separator={rdvActeurIndex !== rdvActeurs.length -1}
                handleActeurStatutChange={handleActeurStatutChange}
                handleDelete={(stakeholderId) => handlePersonDelete(rdvActeurIndex, stakeholderId)}
                disabled={disabled}
              />
            ))
          }
        </CardContentContainer>
      </Collapse>
    </CardContainer>
  )
}

export default StakeholdersRdvCard
