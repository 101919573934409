/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Component declaration ---------------------------------------------------- */
interface QaSinappsLiftServiceEndProps {}

const QaSinappsLiftServiceEnd: React.FC<QaSinappsLiftServiceEndProps> = () => {

  return (
    <>
      <FormBoldTitle>
        Commentaire lever fin de prestation
      </FormBoldTitle>
      <Field
        component={TextField}
        name="liftServiceEnd.commentaire"
        placeholder="Votre message"
        rows={3}
        multiline
      />
    </>
  )
}

export default QaSinappsLiftServiceEnd
