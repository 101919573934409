/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostSinappsCloseDissatisfactionMutation,
  usePostSinappsCloseSolicitationMutation,
  usePostSinappsDealDissatisfactionMutation,
  usePostSinappsLiftDifficultyMutation,
  usePostSinappsLiftServiceEndMutation,
  usePostSinappsRequestServiceEndMutation,
  usePostSinappsSignalDifficultyMutation,
  usePostSinappsSolicitStakeholderMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
} from '@mui/material'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import Loader from 'components/Loader/Loader'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import QaSinappsRequestServiceEnd from './QaSinappsRequestServiceEnd/QaSinappsRequestServiceEnd'
import QaSinappsLiftServiceEnd from './QaSinappsLiftServiceEnd/QaSinappsLiftServiceEnd'
import QaSinappsSignalDifficulty from './QaSinappsSignalDifficulty/QaSinappsSignalDifficulty'
import QaSinappsLiftDifficulty from './QaSinappsLiftDifficulty/QaSinappsLiftDifficulty'
import QaSinappsDealDissatisfaction from './QaSinappsDealDissatisfaction/QaSinappsDealDissatisfaction'
import QaSinappsCloseDissatisfaction from './QaSinappsCloseDissatisfaction/QaSinappsCloseDissatisfaction'
import QaSinappsSolicitStakeholder from './QaSinappsSolicitStakeholder/QaSinappsSolicitStakeholder'
import QaSinappsCloseSolicitation from './QaSinappsCloseSolicitation/QaSinappsCloseSolicitation'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type {
  DocumentAvecTypeRequest,
  CommandeCloturerSollicitationRequest,
  CommandeLeverDifficulteRequest,
  DestinataireSollicitation,
} from 'API/__generated__/Api'
import {
  Criticite,
  NatureOperation,
  SousTypeIntervention,
  TypeDestinataireSollicitation,
  TypeIntervention,
  TypeSollicitation,
} from 'API/__generated__/Api'
import {
  SinappsCommandList,
  SinappsCommandsSchema,
} from 'types/SinappsCommands'
import type {
  SinappsCommandType,
  SinappsCommandsRequest,
} from 'types/SinappsCommands'
import type { ApiResponse } from 'helpers/fetchHelpers'

/* Type declarations -------------------------------------------------------- */
type CommandElement = {
  [key in SinappsCommandType]: React.ReactElement | null;
}

/* Styled components -------------------------------------------------------- */
const CommandGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 1fr;
  }
  gap: 10px;

  align-items: stretch;
  justify-content: stretch;

  margin-bottom: 40px;
`

const CardTitle = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 20px;
`

const CardContainer = styled(Card)`
  margin-bottom: 40px;
`

/* Component declaration ---------------------------------------------------- */
interface QaSinappsCommandPageProps {}

const QaSinappsCommandPage: React.FC<QaSinappsCommandPageProps> = () => {
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ closeDissatisfaction, setCloseDissatisfaction ] = useState<boolean>(false)

  const [
    submitRequestServiceEnd,
  ] = usePostSinappsRequestServiceEndMutation()
  const [
    submitLiftServiceEnd,
  ] = usePostSinappsLiftServiceEndMutation()
  const [
    submitSignalDifficulty,
  ] = usePostSinappsSignalDifficultyMutation()
  const [
    submitLiftDifficulty,
  ] = usePostSinappsLiftDifficultyMutation()
  const [
    submitDealDissatisfaction,
  ] = usePostSinappsDealDissatisfactionMutation()
  const [
    submitCloseDissatisfaction,
  ] = usePostSinappsCloseDissatisfactionMutation()
  const [
    submitSolicitStakeholder,
  ] = usePostSinappsSolicitStakeholderMutation()
  const [
    submitCloseSolicitation,
  ] = usePostSinappsCloseSolicitationMutation()

  const onSubmit = async (values: SinappsCommandsRequest, { setSubmitting, resetForm }: FormikHelpers<SinappsCommandsRequest>): Promise<void> => {
    let response = null

    if (caseId === undefined) {
      setSubmitting(false)
      return
    }

    const onFinish = (response: ApiResponse<boolean>): void => {
      if (!isApiError(response)) {
        resetForm()
      } else {
        toast.error(`Une erreur est survenue lors de l'envoi de la commande "${values.command}".`)
        setSubmitting(false)
      }
    }

    switch (values.command) {
      case 'Demander fin prestation':
        if (values.requestServiceEnd !== undefined) {
          response = await submitRequestServiceEnd({
            caseId,
            data: values.requestServiceEnd,
          })
        }
        break
      case 'Lever fin prestation':
        if (values.liftServiceEnd !== undefined) {
          response = await submitLiftServiceEnd({
            caseId,
            data: values.liftServiceEnd,
          })
        }
        break
      case 'Signaler une difficulté':
        if (values.signalDifficulty !== undefined) {
          response = await submitSignalDifficulty({
            caseId,
            data: values.signalDifficulty,
          })
        }
        break
      case 'Lever une difficulté':
        if (values.liftDifficulty !== undefined) {
          response = await submitLiftDifficulty({
            caseId,
            data: values.liftDifficulty,
          })
        }
        break
      case 'Traiter une insatisfaction':
        if (values.dealDissatisfaction !== undefined) {
          response = await submitDealDissatisfaction({
            caseId,
            data: values.dealDissatisfaction,
          })
          if (!isApiError(response) && closeDissatisfaction && values.closeDissatisfaction !== undefined) {
            response = await submitCloseDissatisfaction({
              caseId,
              data: values.closeDissatisfaction,
            })
          }
        }
        break
      case 'Cloturer une insatisfaction':
        if (values.closeDissatisfaction !== undefined) {
          response = await submitCloseDissatisfaction({
            caseId,
            data: values.closeDissatisfaction,
          })
        }
        break
      case 'Cloturer une prestation (expertise libérale)':
        break
      case 'Lever une cloture de prestation (expertise libérale)':
        break
      case 'Solliciter un intervenant':
        if (values.solicitStakeholder !== undefined) {
          response = await submitSolicitStakeholder({
            caseId,
            data: values.solicitStakeholder,
          })
        }
        break
      case 'Clôturer une sollicitation':
        if (values.closeSolicitation !== undefined) {
          response = await submitCloseSolicitation({
            caseId,
            data: values.closeSolicitation,
          })
        }
        break
      default:
        setSubmitting(false)
        break
    }

    if (response !== null) {
      onFinish(response)
    }
  }

  const formikForm = useForm<SinappsCommandsRequest>(
    {
      initialValues: {
        command: SinappsCommandList[0],
        requestServiceEnd: {
          motif: '',
          commentaire: '',
        },
        liftServiceEnd: {
          commentaire: '',
        },
        signalDifficulty: {
          etape: '',
          motif: '',
          commentaire: '',
          type: '',
          datePrevisionnelleReprise: '',
        },
        liftDifficulty: [],
        dealDissatisfaction: {
          commentaire: '',
          documents: [],
        },
        closeDissatisfaction: {
          estSatisfait: true,
          commentaire: '',
          motif: '',
        },
        solicitStakeholder: {
          // Shared fields
          typeSollicitation: TypeSollicitation.DemandeNouvelleIntervention,
          destinataire: {
            typeDestinataire: {
              code: TypeDestinataireSollicitation.Assureur,
              libelle: 'Assureur',
            },
            destinataire: { code: '', libelle: '' },
          },
          commentaire: '',
          // New intervention
          intervention: TypeIntervention.EAD,
          complementPrestation: '',
          natureDommages: '',
          sousTypeIntervention: SousTypeIntervention.NettoyageDecontaminationAssechement,
          natureOperation: NatureOperation.NonDestructive,
          estComplexe: false,
          jePeuxIntervenir: false,
          criticite: Criticite.Normale,
          motifUrgence: '',
          convention: '',
          detailConvention: '',
          // Other
          etapeConcernee: '',
          motifCorrection: '',
        },
        closeSolicitation: [],
      },
      onSubmit: onSubmit,
      validationSchema: SinappsCommandsSchema,
    },
  )

  const handleValue = (type: string, value?: string | string[] | boolean | CommandeCloturerSollicitationRequest[] | CommandeLeverDifficulteRequest[] | DocumentAvecTypeRequest[] | DestinataireSollicitation): void => {
    formikForm.setFieldValue(type, value).catch(console.error)
  }

  const commandElements: CommandElement = {
    'Demander fin prestation': <QaSinappsRequestServiceEnd />,
    'Lever fin prestation': <QaSinappsLiftServiceEnd />,
    'Signaler une difficulté': (
      <QaSinappsSignalDifficulty
        {...formikForm.values.signalDifficulty}
        onReasonTypeChange={(value: string): void => handleValue('signalDifficulty.type', value)}
      />
    ),
    'Lever une difficulté': (
      <QaSinappsLiftDifficulty
        handleChange={(value: CommandeLeverDifficulteRequest[]): void => handleValue('liftDifficulty', value)}
        values={[ ...formikForm.values.liftDifficulty || [] ]}
        caseId={caseId}
      />
    ),
    'Traiter une insatisfaction': (
      <QaSinappsDealDissatisfaction
        caseId={caseId}
        closeDissatisfaction={closeDissatisfaction}
        setCloseDissatisfaction={setCloseDissatisfaction}
        closeDissatisfactionComponent={
          <QaSinappsCloseDissatisfaction
            {...formikForm.values.closeDissatisfaction}
            onChangeIsCustomerSatisfied={(value): void => handleValue('closeDissatisfaction.estSatisfait', value)}
            options={[ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]}
          />
        }
        documents={formikForm.values.dealDissatisfaction?.documents || []}
        handleFileChange={(value: DocumentAvecTypeRequest[]): void => handleValue('dealDissatisfaction.documents', value)}
      />
    ),
    'Cloturer une insatisfaction': (
      <QaSinappsCloseDissatisfaction
        {...formikForm.values.closeDissatisfaction}
        onChangeIsCustomerSatisfied={(value): void => handleValue('closeDissatisfaction.estSatisfait', value)}
        options={[ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]}
      />
    ),
    'Cloturer une prestation (expertise libérale)': null,
    'Lever une cloture de prestation (expertise libérale)': null,
    'Solliciter un intervenant': (
      <QaSinappsSolicitStakeholder
        handleChange={(type: string, value: string | boolean | DestinataireSollicitation): void => handleValue(`solicitStakeholder.${type}`, value)}
        {...formikForm.values.solicitStakeholder}
      />
    ),
    'Clôturer une sollicitation': (
      <QaSinappsCloseSolicitation
        handleChange={(value: CommandeCloturerSollicitationRequest[]): void => handleValue('closeSolicitation', value)}
        values={[ ...formikForm.values.closeSolicitation || [] ]}
        caseId={caseId}
      />
    ),
  }

  return (
    <>
      <HeaderAction
        title="Commandes Manuelles Sinapps"
        onSubmit={formikForm.handleSubmit}
      >
        <SubmitFormButton
          type="submit"
          variant="contained"
          disabled={formikForm.isSubmitting}
        >
          Valider
        </SubmitFormButton>
      </HeaderAction>
      <PageContainer>
        {formikForm.isSubmitting && <Loader /> }
        <Form form={formikForm}>
          <FormBoldTitle required>
            Commande à effectuer
          </FormBoldTitle>
          <CommandGridContainer>
            {
              SinappsCommandList.map((command) => (
                <CheckableButton
                  key={command}
                  checked={formikForm.values.command === command}
                  onChange={() => handleValue('command', command)}
                  label={command}
                  type="radio"
                />
              ))
            }
          </CommandGridContainer>
          {
            commandElements[formikForm.values.command] !== null &&
              <CardContainer>
                <CardContent>
                  <CardTitle>
                    {formikForm.values.command}
                  </CardTitle>
                  {commandElements[formikForm.values.command]}
                </CardContent>
              </CardContainer>
          }
        </Form>
      </PageContainer>
    </>
  )
}

export default QaSinappsCommandPage
