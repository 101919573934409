/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import ServiceWorkerUpdateModal from 'App/ServiceWorkerUpdateModal/ServiceWorkerUpdateModal'
import Router from 'routers/Router/Router'

/* Component declaration ---------------------------------------------------- */
const AppMain = styled.main`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`

const App: React.FC = () => {

  return (
    <AppMain>
      <ServiceWorkerUpdateModal />
      <Router />
    </AppMain>
  )
}

export default App
