/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component declaration ---------------------------------------------------- */
interface HeaderContainerProps {
  justifyContent?: 'start' | 'space-between' | 'space-around' | 'space-evenly' | 'end';
}

const HeaderContainer = styled.div<HeaderContainerProps>`
  bottom: 0;
  width: 100%;
  height: 70px;
  min-height: 70px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  align-items: center;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  z-index: 200;
  overflow-x: overlay;
  overflow-y: hidden;

  padding-left: 1rem;
  padding-right: .5rem;
  @media screen and (max-width: 768px) {
    padding: 0 .5rem !important;
  }
`

export default HeaderContainer
