/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'

/* Component declaration ---------------------------------------------------- */
const CompensationPersonTableCard = styled(Card)`
  font-size: 14px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: -10px;
  padding-left: 10px;
  padding-right: 10px;
`

export default CompensationPersonTableCard
