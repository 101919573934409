/* Framework imports -------------------------------------------------------- */
import React from 'react'
import { Outlet } from 'react-router-dom'

/* Module imports ----------------------------------------------------------- */
import { useMobileStatusBarStyle } from 'helpers/hooks/useMobileStatusBarStyle'

/* Component imports -------------------------------------------------------- */
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import RouteTitle from 'routers/Router/RouteTitle'

/* Component declaration ---------------------------------------------------- */
interface AuthLayoutProps {}

const AuthLayout: React.FC<AuthLayoutProps> = () => {
  useMobileStatusBarStyle('black-translucent')

  return (
    <BaseLayout className="AuthLayout">
      <RouteTitle title="Connexion" />
      <Outlet />
    </BaseLayout>
  )
}

export default AuthLayout
