/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component declaration ---------------------------------------------------- */
interface CaseItemContainerProps {
  disabled?: boolean;
}

const CaseItemContainer = styled.div<CaseItemContainerProps>`
  display: flex;
  flex-direction: column;
  padding: .5rem;
  border: 1px solid ${(props) => props.theme.colors.grey};
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.common.white};

  &:hover {
    cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
    filter: brightness(95%);
  }
`

export default CaseItemContainer
