/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useParams,
  useLocation,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseInfosQuery,
  useGetCaseReportsQuery,
  useGetCaseTravelerQuery,
  useGetTravelerIrsiQuery,
} from 'store/api'
import {
  useAppDispatch,
  useSelectedReport,
  useReports,
  useAppSelector,
} from 'store/hooks'
import TravelerService from 'services/TravelerService'
import { calculateAllCompensations } from 'services/CompensationService'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import Loader from 'components/Loader/Loader'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import { EtatRapport } from 'API/__generated__/Api'
import type {
  TravelerAndUtils,
  TravelerForm,
} from 'types/TravelerForm'
import TravelerFormInitialValues from 'types/TravelerFormInitialValues'
import { travelerSchema } from 'types/TravelerForm'
import {
  getTravelerCaseReport,
  setCaseReportEdition,
  setCaseReportFormikForm,
  setCaseReportSummary,
  setCaseSelectedReport,
} from 'store/slices/travelerSlice'

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutProps {}

const CaseLayout: React.FC<CaseLayoutProps> = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { caseId = '' } = useParams<{ caseId: string }>()
  const selectedReport = useSelectedReport(caseId)
  const cachedReport = useAppSelector(getTravelerCaseReport(caseId, selectedReport || ''))
  const reports = useReports(caseId)
  const formRef = useRef<HTMLFormElement>()
  const [ firstTravelerSubmit, setFirstTravelerSubmit ] = useState<boolean>(false)

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId)
  const {
    currentData: reportList = [],
    isFetching: isFetchingReportList,
  } = useGetCaseReportsQuery(caseId)
  const {
    currentData: travelerData,
    isFetching: isFetchingTravelerData,
  } = useGetCaseTravelerQuery(selectedReport || '', { skip: !selectedReport })
  const {
    currentData: irsiData,
    isFetching: isFetchingIrsiData,
  } = useGetTravelerIrsiQuery(caseId)

  const onSubmit = (values: TravelerAndUtils, { setSubmitting }: FormikHelpers<TravelerAndUtils>): void => {
    console.log('CaseLayout onSubmit values', values)
    setSubmitting(false)
  }

  const formikForm: TravelerForm = useForm<TravelerAndUtils>(
    {
      initialValues: TravelerFormInitialValues as TravelerAndUtils,
      onSubmit: onSubmit,
      validationSchema: travelerSchema,
    },
  )

  const errorForm: TravelerForm = useForm<TravelerAndUtils>(
    {
      initialValues: TravelerFormInitialValues as TravelerAndUtils,
      onSubmit: onSubmit,
      validationSchema: travelerSchema,
    },
  )

  useEffect(() => {
    if (!isFetchingReportList && reportList.length > 0) {
      reportList.forEach((report) => {
        dispatch(setCaseReportSummary({ caseId, reportId: report.id, summary: report }))
      })
      if (!selectedReport) dispatch(setCaseSelectedReport({ caseId, selectedReport: reportList[reportList.length - 1].id }))
    }
  }, [ isFetchingReportList ])

  useEffect(() => {
    if (!isFetchingTravelerData && travelerData !== undefined && !isFetchingCaseInfos) {
      const takeApiData = () => {
        const data = structuredClone(travelerData)

        data.acteurs = data.acteurs.map((person) => {
          return ({
            ...person,
            indemnisation: calculateAllCompensations({
              damages: [
                ...person.pieces?.flatMap((room) => room.dommagesImmobilierEmbellissement) || [],
                ...person.dommagesMobilierDivers || [],
              ],
              isSinapps: caseInfos?.mission.origine?.code === 'SIN',
              oldCompensation: person.indemnisation,
            }),
          })
        })

        formikForm.setValues({
          ...formikForm.values,
          ...data,
        })
        formikForm.setTouched({})
      }

      const takeCachedData = () => {
        formikForm.setValues({
          ...formikForm.values,
          ...cachedReport.formikForm?.values,
        })
        formikForm.setTouched({})
        errorForm.setValues({ ...formikForm.values, ...cachedReport.errorForm?.values })
        errorForm.setTouched({})
      }

      const dateToTake = DateUtils.getMostRecentDate([ cachedReport.sync.edition, travelerData.derniereSauvegardeA, travelerData.derniereValidationA ])

      if (dateToTake?.index === 0) {
        takeCachedData()
      } else {
        takeApiData()
      }
    }
  }, [ isFetchingTravelerData, travelerData, isFetchingCaseInfos ])

  useEffect(() => {
    if (!isFetchingIrsiData && irsiData !== undefined) {
      formikForm.setFieldValue('irsiData', irsiData)
    }
  }, [ isFetchingIrsiData, irsiData ])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (reports.find((report) => report.id === selectedReport)?.etat.code === EtatRapport.Cloture || !caseId || !selectedReport) {
        return
      }
      if (firstTravelerSubmit && location.pathname.indexOf('/nouveau') === -1 && location.pathname.indexOf('/modification') === -1) {
        TravelerService.submitTraveler({
          formikForm,
          caseId,
          reportId: selectedReport,
          validation: false,
        })
        dispatch(setCaseReportFormikForm({ caseId, reportId: selectedReport, formikForm }))
        dispatch(setCaseReportEdition({ caseId, reportId: selectedReport, edition: new Date().toISOString() }))
      } else if (!firstTravelerSubmit) {
        setFirstTravelerSubmit(true)
      }
    }, 3000)

    return () => clearTimeout(timer)
  }, [
    formikForm.values,
    location.pathname,
  ])

  useEffect(() => {
    if (reports.find((report) => report.id === selectedReport)?.etat.code === EtatRapport.Cloture) {
      formikForm.setFieldValue('disabled', true)
    } else {
      formikForm.setFieldValue('disabled', false)
    }
  }, [ selectedReport ])

  return (
    <Form
      form={formikForm}
      innerRef={formRef}
      style={{ display: 'contents' }}
    >
      {(isFetchingTravelerData || isFetchingIrsiData) && <Loader />}
      <Outlet context={{ formikForm, errorForm }} />
    </Form>
  )
}

export default CaseLayout
