/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Styled components -------------------------------------------------------- */
const PriceContainer = styled.div`
  justify-self: right;
`

/* Component declaration ---------------------------------------------------- */
interface PriceDivProps {
  price: number | undefined | null;
}

const PriceDiv: React.FC<PriceDivProps> = ({ price }) => {
  return (
    <PriceContainer>
      {(price || 0).toFixed(2)}
      €
    </PriceContainer>
  )
}

export default PriceDiv
