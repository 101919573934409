/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'

/* Type imports ------------------------------------------------------------- */

interface PlanningFilterButtonElementProps {
  selected?: boolean;
}

const PlanningFilterButtonElement = styled(Button)<PlanningFilterButtonElementProps>`
  cursor: pointer;
  border-radius: 50vh;
  border: solid ${(props) => props.selected ? `2px ${props.theme.palette.primary.main}` : `1px ${props.theme.colors.grey}`};

  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.selected ? 'bold' : 'normal'};
  min-height: 40px;
  padding: 0 1rem;
  min-width: 140px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.palette.background.paper};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    outline-color: ${(props) => props.theme.palette.secondary.main};
    outline-width: 3px;
  }
`

interface PlanningButtonColoredBarProps {
  color: string;
}

const PlanningButtonColoredBar = styled.div<PlanningButtonColoredBarProps>`
  background-color: ${(props) => props.color};
  border-radius: 50vh;
  width: 100%;
  height: 3px;
  margin-top: -3px;
`

const TextContainer = styled.div`
  width: 140px;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningFilterButtonProps {
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  color: string;
  text: string;
}

const PlanningFilterButton: React.FC<PlanningFilterButtonProps> = ({
  selected = false,
  onClick,
  color,
  text,
}) => {

  return (
    <PlanningFilterButtonElement
      selected={selected}
      onClick={onClick}
    >
      <TextContainer>
        {text}
      </TextContainer>
      <PlanningButtonColoredBar color={color} />
    </PlanningFilterButtonElement>
  )
}

export default PlanningFilterButton
