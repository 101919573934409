/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import OfflineDataManagerCase from './OfflineDataManagerCase'

/* Type imports ------------------------------------------------------------- */
import type { TravelerState } from 'store/slices/travelerSlice'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

/* Component declaration ---------------------------------------------------- */
interface OfflineDataManagerModalProps {
  handleClose: (edit?: boolean) => void;
  offlinesCases: string[];
  travelerState: TravelerState;
}

const OfflineDataManagerModal: React.FC<OfflineDataManagerModalProps> = ({
  handleClose,
  offlinesCases,
  travelerState,
}) => {

  const handleQuit = (): void => {
    handleClose(false)
  }

  return (
    <Dialog
      open
      onClose={handleQuit}
      maxWidth="md"
      fullWidth
    >
      <DialogTitleContainer>
        Mes dossiers hors-ligne
        <CloseButton handleClose={handleQuit} />
      </DialogTitleContainer>
      <DialogContent>
        {
          offlinesCases.map((caseId) => (
            <OfflineDataManagerCase
              key={caseId}
              caseId={caseId}
              travelerCase={travelerState[caseId]}
            />
          ))
        }
      </DialogContent>
    </Dialog>
  )
}

export default OfflineDataManagerModal
