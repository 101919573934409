/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useGetSinappsDifficultyStepListQuery,
  useGetSinappsDifficultyReasonListQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  ListSubheader,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import {
  TextField,
  Select,
} from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import Loader from 'components/Loader/Loader'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'

/* Type imports ------------------------------------------------------------- */
import type { SelectChangeEvent } from '@mui/material'

/* Styled components -------------------------------------------------------- */
const ColoredSubHeader = styled(ListSubheader)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
  text-transform: uppercase;
`

/* Component declaration ---------------------------------------------------- */
interface QaSinappsSignalDifficultyProps {
  type?: string | null;
  onReasonTypeChange: (value: string) => void;
}

const QaSinappsSignalDifficulty: React.FC<QaSinappsSignalDifficultyProps> = ({
  type,
  onReasonTypeChange,
}) => {
  const {
    currentData: difficultySteps = [],
    isFetching: isFetchingSteps,
  } = useGetSinappsDifficultyStepListQuery()

  const {
    currentData: difficultyReasons = [],
    isFetching: isFetchingReasons,
  } = useGetSinappsDifficultyReasonListQuery()

  const handleSelectReason = (reason: SelectChangeEvent): void => {
    const reasonType = difficultyReasons.find((value) => value.motif.code === reason.target.value)?.typeDifficulte
    onReasonTypeChange(reasonType || '')
  }

  return (
    <>
      {(isFetchingSteps || isFetchingReasons) && <Loader />}
      <FormBoldTitle required>
        Etape
      </FormBoldTitle>
      <Field
        component={Select}
        name="signalDifficulty.etape"
        displayEmpty
      >
        <MenuItem
          value=""
          disabled
        >
          Sélectionner
        </MenuItem>
        {
          difficultySteps.map((step) => (
            <MenuItem
              value={step.code}
              key={step.code}
            >
              {step.libelle}
            </MenuItem>
          ))
        }
      </Field>
      <FormBoldTitle required>
        Difficulté rencontrée
      </FormBoldTitle>
      <Field
        component={Select}
        name="signalDifficulty.motif"
        onChange={handleSelectReason}
        displayEmpty
      >
        <MenuItem
          value=""
          disabled
        >
          Sélectionner
        </MenuItem>
        {
          difficultyReasons.map((reason, index) =>
            reason.motif?.code === null ?
              (
                <ColoredSubHeader key={`${reason.motif.libelle}-${index}`}>
                  {reason.motif.libelle}
                </ColoredSubHeader>
              ) :
              (
                <MenuItem
                  value={reason.motif?.code || ''}
                  key={`${reason.motif?.code}-${index}`}
                >
                  {reason.motif?.libelle}
                </MenuItem>
              ))
        }
      </Field>
      {
        type === 'DELAI' ?
          <>
            <FormBoldTitle required>
              Date prévisionelle de reprise
            </FormBoldTitle>
            <FormikDatePicker name="signalDifficulty.datePrevisionnelleReprise" />
          </>:
          <div />
      }
      <FormBoldTitle>
        Commentaire
      </FormBoldTitle>
      <Field
        component={TextField}
        name="signalDifficulty.commentaire"
        placeholder="Votre message"
        rows={3}
        multiline
      />
    </>
  )
}

export default QaSinappsSignalDifficulty
