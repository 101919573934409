/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  // Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { useGetDocumentQuery } from 'store/api'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import HeaderWithBackArrow from 'layouts/MainLayout/Headers/HeaderWithBackArrow'
import PageContainer from 'layouts/PageContainer/PageContainer'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import ImageProcessor from 'components/MediaProcessors/ImageProcessor/ImageProcessor'
import MediaProcessorFallback from 'components/MediaProcessors/MediaProcessorFallback/MediaProcessorFallback'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'

/* Lazy component imports --------------------------------------------------- */
const PdfProcessor = React.lazy(() => import(/* webpackPrefetch: true */ 'components/MediaProcessors/PdfProcessor/PdfProcessor' /* webpackChunkName: "pdf" */))

/* Type declarations -------------------------------------------------------- */
const newMediaLibraryAnnotateSchema = Yup.object({
  document: Yup.string(),
}).required()

type NewMediaLibraryAnnotateRequest = Yup.InferType<typeof newMediaLibraryAnnotateSchema>

/* Styled components -------------------------------------------------------- */
const HeaderForm = styled.form`
  margin-left: auto;
  margin-right: 1rem;
`

/* Component declaration ---------------------------------------------------- */
interface MediaLibraryAnnotatePageProps {}

const MediaLibraryAnnotatePage: React.FC<MediaLibraryAnnotatePageProps> = () => {
  const navigate = useNavigate()
  const { mediaId } = useParams<{mediaId: string}>()
  const [ source, setSource ] = useState<{url: string; type: string}>({ url: '', type: '' })

  const {
    currentData: document,
    isFetching: isFetchingDocument,
  } = useGetDocumentQuery(mediaId || '')

  useEffect(() => {
    if (document === null || document === undefined || isFetchingDocument) {
      return
    }

    const fileType = document.fileType.split('/')
    let type = ''

    if (fileType[0] === 'image') {
      type = 'img'
    }
    else if (fileType[1] === 'msword') {
      type = 'word'
    }
    else if (fileType[1] === 'pdf') {
      type = 'pdf'
    }

    setSource({ url: document.url || '', type })
  }, [
    document,
    isFetchingDocument,
  ])

  const onSubmit = (values: NewMediaLibraryAnnotateRequest, { setSubmitting }: FormikHelpers<NewMediaLibraryAnnotateRequest>): void => {
    console.log('values', values)
    setSubmitting(false)
  }

  const formikForm = useForm<NewMediaLibraryAnnotateRequest>(
    {
      initialValues: {
        document: '',
      },
      onSubmit: onSubmit,
      validationSchema: newMediaLibraryAnnotateSchema,
    },
  )

  return (
    <>
      <HeaderWithBackArrow
        onClick={() => navigate(-1)}
        title="Editer un document"
      >
        <HeaderForm onSubmit={formikForm.handleSubmit}>
          <SubmitFormButton
            type="submit"
            variant="contained"
            disabled={formikForm.isSubmitting}
          >
            Valider
          </SubmitFormButton>
        </HeaderForm>
      </HeaderWithBackArrow>
      <PageContainer>
        {
          source.type === 'pdf' && isValidString(source.url) &&
            <MediaProcessorFallback type="pdf">
              <PdfProcessor documentPath={source.url} />
            </MediaProcessorFallback>
        }
        {source.type === 'img' && <ImageProcessor source={source.url} />}
      </PageContainer>
    </>
  )
}

export default MediaLibraryAnnotatePage
