/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useGetCasesChipListQuery,
  useGetCaseStateCodeListQuery,
  useGetDisasterNatureListQuery,
  useGetNotificationsQuery,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { isValidString } from 'helpers/isValidString'
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
} from '@mui/material'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderWithSearch from 'layouts/MainLayout/Headers/HeaderWithSearch'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import NotificationsMessage from './NotificationMessage/NotificationMessage'

/* Type imports ------------------------------------------------------------- */
import type {
  EchangeInterne,
  EchangeInterneEmetteur,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  gap: 20px;
  align-items: center;
  justify-content: stretch;

  grid-template-columns: repeat(2, 1fr);
`

const PaginationGridContainer = styled(GridContainer)`
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-bottom: 20px;
`

const SelectLimitContainer = styled.div`
  justify-self: right;
`

const LoaderContainer = styled.div`
  text-align: center;
  padding-top: 100px;
`

const OuterGridContainer = styled(GridContainer)`
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface NotificationsPageProps {}

const NotificationsPage: React.FC<NotificationsPageProps> = () => {
  const authInfo = useAuthInfo()
  const [ filteredList, setFilteredList ] = useState<EchangeInterne[]>([])
  const [ pagination, setPagination ] = useState<{startIndex: number; limit: number}>({ startIndex: 0, limit: 20 })
  const [ emetteurList, setEmetteurList ] = useState<EchangeInterneEmetteur[]>([])
  const [ mandantList, setMandantList ] = useState<string[]>([])
  const [ emetteurFilter, setEmetteurFilter ] = useState<string>('')
  const [ mandantFilter, setMandantFilter ] = useState<string>('')
  const [ typeFilter, setTypeFilter ] = useState<string>('')
  const [ statutFilter, setStatutFilter ] = useState<string>('')

  const {
    currentData: notifications = [],
    isFetching: isFetchingNotifications,
  } = useGetNotificationsQuery(
    {
      destinataire: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '',
      startIndex: pagination.startIndex,
      limit: pagination.limit,
    },
    { skip: !isValidString(authInfo?.currentCollaborateur?.refAnnuaire.refComplete) },
  )
  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterNatureListQuery()
  const {
    currentData: caseStateCodeList = [],
    isFetching: isFetchingCaseStateCodeList,
  } = useGetCaseStateCodeListQuery()
  const {
    currentData: casesChips = [],
    isFetching: isFetchingCasesChips,
  } = useGetCasesChipListQuery(
    {
      modeSimple: true,
      dossiers: notifications.map((notification) => notification.dossier.id),
    },
    { skip: notifications.length === 0 },
  )

  useEffect(() => {
    if (!isFetchingNotifications && notifications.length > 0) {
      setFilteredList(notifications)
      // .map((v) => [ v, v ])).values() => removes duplicates
      setEmetteurList(
        [ ...new Map(notifications.map((notification) => notification.emetteur).map((v) => [ v.id, v ])).values() ]
          .sort((a, b) => a.nom.localeCompare(b.nom)),
      )
      setMandantList(
        [ ...new Set(notifications.map((notification) => notification.dossier.mandant)) ]
          .sort((a, b) => a.localeCompare(b)),
      )
    }
  }, [
    isFetchingNotifications,
    notifications,
  ])

  useEffect(() => {
    if (!isFetchingNotifications && notifications.length > 0) {
      setFilteredList(notifications.filter((notification) =>
        (emetteurFilter === '' || notification.emetteur.id === emetteurFilter) &&
        (mandantFilter === '' || notification.dossier.mandant === mandantFilter) &&
        (typeFilter === '' || notification.dossier.natureSinistre.code === typeFilter) &&
        (statutFilter === '' || notification.dossier.etat.code === statutFilter),
      ))
    }
  }, [
    emetteurFilter,
    mandantFilter,
    typeFilter,
    statutFilter,
    notifications,
    isFetchingNotifications,
  ])

  const onClickStartIndexButtonPrev = (): void => {
    const newStartIndex = pagination.startIndex - parseInt(pagination.limit.toString()) >= 0 ? pagination.startIndex - parseInt(pagination.limit.toString()) : 0
    setPagination({
      ...pagination,
      startIndex: newStartIndex,
    })
    setMandantFilter('')
  }

  const onClickStartIndexButtonNext = (): void => {
    const newStartIndex = pagination.startIndex + parseInt(pagination.limit.toString())
    setPagination({ ...pagination, startIndex: newStartIndex })
    setMandantFilter('')
  }

  const handleLimitChange = (newLimit: number): void => {
    setPagination({ ...pagination, limit: newLimit })
    setMandantFilter('')
  }

  return (
    <>
      <HeaderWithSearch title="Notifications" />
      <PageContainer>
        <OuterGridContainer>
          <GridContainer>
            <div>
              <FormBoldTitle>
                Emetteur
              </FormBoldTitle>
              <Select
                value={emetteurFilter}
                onChange={(e): void => setEmetteurFilter(e.target.value)}
                fullWidth
                renderValue={verifySelectFieldValue(emetteurFilter)}
                displayEmpty
                disabled={isFetchingNotifications || isFetchingCaseStateCodeList || isFetchingDisasterNatureList}
              >
                <MenuItem
                  value=""
                  key=""
                >
                  Sélectionner
                </MenuItem>
                {
                  emetteurList.map((option) => (
                    <MenuItem
                      value={option.id}
                      key={option.id}
                    >
                      {option.nom}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
            <div>
              <FormBoldTitle>
                Mandant du dossier
              </FormBoldTitle>
              <Select
                value={mandantFilter}
                onChange={(e): void => setMandantFilter(e.target.value)}
                fullWidth
                renderValue={verifySelectFieldValue(mandantFilter)}
                displayEmpty
                disabled={isFetchingNotifications || isFetchingCaseStateCodeList || isFetchingDisasterNatureList}
              >
                <MenuItem
                  value=""
                  key=""
                >
                  Sélectionner
                </MenuItem>
                {
                  mandantList.map((option) => (
                    <MenuItem
                      value={option}
                      key={option}
                    >
                      {option}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
          </GridContainer>
          <GridContainer>
            <div>
              <FormBoldTitle>
                Type de sinistre
              </FormBoldTitle>
              <Select
                value={typeFilter}
                onChange={(e): void => setTypeFilter(e.target.value)}
                fullWidth
                renderValue={verifySelectFieldValue(typeFilter)}
                displayEmpty
                disabled={isFetchingNotifications || isFetchingCaseStateCodeList || isFetchingDisasterNatureList}
              >
                <MenuItem
                  value=""
                  key=""
                >
                  Sélectionner
                </MenuItem>
                {
                  disasterNatureList.map((option, index) => (
                    <MenuItem
                      value={option.code}
                      key={`${option.code}-${index}`}
                    >
                      {option.libelle}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
            <div>
              <FormBoldTitle>
                Statut du dossier
              </FormBoldTitle>
              <Select
                value={statutFilter}
                onChange={(e): void => setStatutFilter(e.target.value)}
                fullWidth
                renderValue={verifySelectFieldValue(statutFilter)}
                displayEmpty
                disabled={isFetchingNotifications || isFetchingCaseStateCodeList || isFetchingDisasterNatureList}
              >
                <MenuItem
                  value=""
                  key=""
                >
                  Sélectionner
                </MenuItem>
                {
                  caseStateCodeList.map((option, index) => (
                    <MenuItem
                      value={option.code?.code}
                      key={`${option.code?.code}-${index}`}
                    >
                      {option.code?.libelle}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
          </GridContainer>
        </OuterGridContainer>
        <PaginationGridContainer>
          <Button
            variant="outlined"
            disabled={pagination.startIndex === 0}
            onClick={onClickStartIndexButtonPrev}
            startIcon={<ArrowBackIosNewRoundedIcon />}
          >
            Notifications précédentes
          </Button>
          <Button
            variant="outlined"
            disabled={notifications.length < parseInt(pagination.limit.toString())}
            onClick={onClickStartIndexButtonNext}
            endIcon={<ArrowForwardIosRoundedIcon />}
          >
            Notifications suivantes
          </Button>
          <SelectLimitContainer>
            <Select
              value={pagination.limit}
              onChange={(e): void => handleLimitChange(parseInt(e.target.value.toString()))}
              size="small"
            >
              {
                [ 5, 10, 20, 50 ].map((limit, index) => (
                  <MenuItem
                    value={limit}
                    key={`${limit}-${index}`}
                  >
                    {limit}
                  </MenuItem>
                ))
              }
            </Select>
          </SelectLimitContainer>
        </PaginationGridContainer>
        {
          (isFetchingNotifications || isFetchingCaseStateCodeList || isFetchingDisasterNatureList) && notifications.length === 0 ?
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer> :
            filteredList.map((notification) => (
              <NotificationsMessage
                key={notification.id}
                notification={notification}
                caseChips={casesChips.find((chips) => chips.dossier === notification.dossier.id)?.listePastilles}
                isFetchingChip={isFetchingCasesChips}
              />
            ))
        }
      </PageContainer>
    </>
  )
}

export default NotificationsPage
