/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import DrawerContent from 'components/Drawer/DrawerContent'
import CaseSidebarNotificationMenuItem from './CaseSidebarNotificationMenuItem'

/* Type imports ------------------------------------------------------------- */
import type { EchangeInterne } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 1rem;
  padding: 10px 10px 0px 20px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarNotificationMenuProps {
  notifications?: EchangeInterne[];
  isFetching: boolean;
}

const CaseSidebarNotificationMenu: React.FC<CaseSidebarNotificationMenuProps> = ({
  notifications = [],
  isFetching,
}) => {

  if (isFetching) {
    return <CircularProgress />
  }

  return (
    <>
      <TitleContainer>
        Notifications
      </TitleContainer>
      <DrawerContent>
        {
          isFetching ?
            <CircularProgress /> :
            notifications.map((notification: EchangeInterne, index: number) => (
              <CaseSidebarNotificationMenuItem
                key={`notification-${notification.id}-${index}`}
                notification={notification}
              />
            ))
        }
      </DrawerContent>
    </>
  )
}

export default CaseSidebarNotificationMenu
