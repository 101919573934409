/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Collapse,
  Menu,
  MenuItem,
} from '@mui/material'
import MoreVertRounded from '@mui/icons-material/MoreVertRounded'
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import PriceDiv from 'components/FieldWithInputAdornment/PriceDiv'
import AmountDiv from 'components/FieldWithInputAdornment/AmountDiv'

/* Type imports ------------------------------------------------------------- */
import type { Dommage } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface TableRowProps {
  border?: boolean;
}

const TableRow = styled.div<TableRowProps>`
  height: 100%;
  margin-bottom: 10px;
  margin-right: 0px;
  border-bottom: ${(props) => props.border ? `1px solid ${props.theme.colors.grey}` : undefined};
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 12% 13% 10% 8% 12% 14% 10% 65px;
  gap: 2%;
  justify-content: stretch;
  align-items: center;
`

interface DropDownArrowProps {
  open: boolean;
}

const ButtonsContainer = styled.div`
  display: flex;
`

const IconButton = styled(Button)`
  align-self: center;
  min-width: auto;
  width: 30px !important;
`

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  font-size: 2.2rem;
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  transition: transform .2s;
  color: ${(props) => props.theme.palette.secondary.main};
`

const MoreDots = styled(MoreVertRounded)`
  font-size: 1.8rem;
  color: ${(props) => props.theme.palette.secondary.main};
`

const CollapseContainer = styled(Collapse)`
  padding-bottom: 10px;
`

const InLine = styled.div`
  display: flex;
  gap: 15px;
`

interface BoldTextProps {
  sideBorder?: boolean;
}

const BoldText = styled.div<BoldTextProps>`
  font-weight: bold;
  display: inline;
  padding-right: 15px;
  border-right: ${(props) => props.sideBorder ? `2px solid ${props.theme.colors.grey}` : 'none'};
`

const NameContainer = styled.div`
  line-break: anywhere;
`

/* Component declaration ---------------------------------------------------- */
interface DamageLineProps {
  damage: Dommage;
  border?: boolean;
  disabled: boolean;
  onDelete: () => void;
  onEdit: () => void;
}

const DamageLine: React.FC<DamageLineProps> = ({
  damage,
  border,
  disabled,
  onDelete,
  onEdit,
}) => {
  const [ open, setOpen ] = useState<boolean>(false)
  const [ anchorMenu, setAnchorMenu ] = useState<null | SVGElement>(null)
  const menuOpen = Boolean(anchorMenu)

  const handleMenuClick = (event: React.MouseEvent<SVGElement>): void => {
    event.stopPropagation()
    setAnchorMenu(event.currentTarget)
  }

  const handleMenuClose = (): void => {
    setAnchorMenu(null)
  }

  return (
    <TableRow
      border={border}
      onClick={() => setOpen(!open)}
    >
      <GridContainer>
        <NameContainer>
          {damage.libelle}
        </NameContainer>
        <div>
          {damage.typeDeReparation?.libelle || damage.typeDeReparation?.code}
        </div>
        <PriceDiv price={damage.valeur.montantHT} />
        <AmountDiv amount={damage.valeur.tauxTva?.tva?.libelle} />
        <PriceDiv price={damage.valeur.montantTTC} />
        <PriceDiv price={damage.vetuste.montantVetuste} />
        <PriceDiv price={damage.vetuste.montantVetusteDeduite} />
        <ButtonsContainer>
          <IconButton
            variant="text"
            size="small"
          >
            <DropDownArrow open={open} />
          </IconButton>
          <IconButton
            variant="text"
            size="small"
          >
            <MoreDots onClick={handleMenuClick} />
          </IconButton>
          <Menu
            anchorEl={anchorMenu}
            open={menuOpen}
            onClose={handleMenuClose}
            onClick={(e): void => e.stopPropagation()}
          >
            <MenuItem
              onClick={onEdit}
              disabled={disabled}
            >
              Modifier
            </MenuItem>
            <MenuItem
              onClick={onDelete}
              disabled={disabled}
            >
              Supprimer
            </MenuItem>
          </Menu>
        </ButtonsContainer>
      </GridContainer>
      <CollapseContainer
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <FormBoldTitle>
          Valeur
        </FormBoldTitle>
        <InLine>
          <div>
            {'Quantité : '}
            <BoldText sideBorder>
              {damage.valeur.quantite}
            </BoldText>
          </div>
          <div>
            {'Unité : '}
            <BoldText sideBorder>
              {damage.valeur.uniteMesure?.libelle}
            </BoldText>
          </div>
          <div>
            {'Prix unitaire : '}
            <BoldText>
              {(damage.valeur.prixUnitaire || 0).toFixed(2)}
              {' €'}
            </BoldText>
          </div>
        </InLine>
        <FormBoldTitle>
          {'Vétusté : '}
        </FormBoldTitle>
        <InLine>
          <div>
            {'% vétusté : '}
            <BoldText sideBorder>
              {damage.vetuste.tauxVetuste}
              {' %'}
            </BoldText>
          </div>
          {/* <div>
            {'Age : '}
            <BoldText sideBorder>
              {damage.vetuste.age}
            </BoldText>
          </div> */}
          <div>
            {'Limite vétusté : '}
            <BoldText sideBorder>
              {damage.vetuste.tauxLimiteVetuste}
              {' %'}
            </BoldText>
          </div>
          <div>
            {'Vétusté récupérable : '}
            <BoldText>
              {(damage.vetuste.montantVetusteRecuperable || 0).toFixed(2)}
              {' €'}
            </BoldText>
          </div>
        </InLine>
      </CollapseContainer>
    </TableRow>
  )
}

export default DamageLine
