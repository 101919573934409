/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Styled components -------------------------------------------------------- */
const AmountContainer = styled.div`
  justify-self: right;
`

/* Component declaration ---------------------------------------------------- */
interface AmountDivProps {
  amount: number | undefined | null | string;
  isRate?: boolean;
}

const AmountDiv: React.FC<AmountDivProps> = ({ amount, isRate = false }) => {
  return (
    <AmountContainer>
      {amount}
      {isRate && '%'}
    </AmountContainer>
  )
}

export default AmountDiv
