/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'

/* Type imports ------------------------------------------------------------- */
import type {
  Dommage,
  RecoursTraveller,
} from 'API/__generated__/Api'
import type { RdvIntervenant } from 'types/Stakeholders'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

const DangerContainer = styled.div`
  margin-bottom: 10px;
  b {
    color: red;
  }
`

/* Component declaration ---------------------------------------------------- */
interface StakeholderPersonDeleteModalProps {
  open: boolean;
  handleClose: (deleteStakeholder?: boolean) => void;
  name: string;
  recourses?: RecoursTraveller[];
  stakeholders?: RdvIntervenant[];
  damages?: Dommage[];
}

const StakeholderPersonDeleteModal: React.FC<StakeholderPersonDeleteModalProps> = ({
  open,
  handleClose,
  name,
  recourses,
  stakeholders,
  damages,
}) => {

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleContainer>
        {`Suppression de ${name}`}
        <CloseButton handleClose={() => handleClose(false)} />
      </DialogTitleContainer>
      <DialogContentContainer>
        {
          ((recourses?.length !== undefined && recourses.length > 0) ||
          (damages?.length !== undefined && damages.length > 0) ||
          (stakeholders?.length !== undefined && stakeholders.length > 0)) &&
            <DangerContainer>
              <b>
                Attention !
              </b>
              {
                stakeholders?.length !== undefined && stakeholders.length > 0 && (
                  <div>
                    <b>
                      Les intervenants suivants seront aussi supprimés :
                    </b>
                    {
                      stakeholders.map((stakeholder, index) => (
                        <i key={`${stakeholder.intervenant.id}-${index}`}>
                          <br />
                          {`- ${stakeholder.intervenant.nom}`}
                        </i>
                      ))
                    }
                  </div>
                )
              }
              {
                damages?.length !== undefined && damages.length > 0 && (
                  <div>
                    <b>
                      Les dommages suivants seront aussi supprimés :
                    </b>
                    {
                      damages.map((damage, index) => (
                        <i key={`${damage.libelle}-${index}`}>
                          <br />
                          {`- ${damage.typeBien} : ${damage.libelle}`}
                        </i>
                      ))
                    }
                  </div>
                )
              }
              {
                recourses?.length !== undefined && recourses.length > 0 && (
                  <div>
                    <b>
                      Les recours suivants seront aussi supprimés :
                    </b>
                    {
                      recourses.map((recours, index) => (
                        <i key={`${recours.guid}-${index}`}>
                          <br />
                          {`- ${recours.type} N°${recours.compteur} - ${recours.cibleRecours.nom}`}
                        </i>
                      ))
                    }
                  </div>
                )
              }
            </DangerContainer>
        }
        {`Confirmez-vous la suppression de ${name} ? Cette action est irréversible.`}
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton
          variant="outlined"
          onClick={() => handleClose(false)}
        >
          Annuler
        </FormButton>
        <SubmitFormButton
          variant="contained"
          color="error"
          onClick={() => handleClose(true)}
        >
          Supprimer
        </SubmitFormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default StakeholderPersonDeleteModal
