/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import BackButton from 'components/BackButton/BackButton'
import HeaderContainer from './HeadersComponents/HeaderContainer'

/* Component declaration ---------------------------------------------------- */
interface HeaderWithBackArrowProps {
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}

const HeaderWithBackArrow: React.FC<HeaderWithBackArrowProps> = ({
  title,
  onClick,
  children,
}) => {
  return (
    <HeaderContainer justifyContent="start">
      <BackButton
        id="header-back-button"
        onClick={onClick}
      >
        {title}
      </BackButton>
      {children}
    </HeaderContainer>
  )
}

export default HeaderWithBackArrow
