/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import CaseSidebarArrowTitle from '../CaseSidebarComponents/CaseSidebarArrowTitle/CaseSidebarArrowTitle'
import CaseSidebarInternalExchangeItem from './CaseSidebarInternalExchangeItem'

/* Type imports ------------------------------------------------------------- */
import type { EchangeInterne } from 'API/__generated__/Api'

/* Internal variables ------------------------------------------------------- */
const STR_LEN_DEFAULT_LIMIT: number = 250

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarInternalExchangeProps {
  exchanges?: EchangeInterne[];
  isFetching: boolean;
  strLenLimit?: number;
  setOpenedMenu: () => void;
}

const CaseSidebarInternalExchange: React.FC<CaseSidebarInternalExchangeProps> = ({
  exchanges = [],
  isFetching,
  strLenLimit = STR_LEN_DEFAULT_LIMIT,
  setOpenedMenu,
}) => {
  return (
    <>
      <CaseSidebarArrowTitle
        setOpenedMenu={setOpenedMenu}
        title="Échanges internes"
      />
      {
        isFetching ?
          <CircularProgress /> :
          exchanges.filter((exchange) => !exchange.lu).map((exchange: EchangeInterne, index: number) => (
            <CaseSidebarInternalExchangeItem
              key={`exchange-${exchange.id}-${index}`}
              exchange={exchange}
              strLenLimit={strLenLimit}
            />
          ))
      }
    </>
  )
}

export default CaseSidebarInternalExchange
