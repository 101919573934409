/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'

/* Component imports -------------------------------------------------------- */
import { Collapse } from '@mui/material'
import CaseSidebarItemComponents from 'layouts/CaseSidebar/CaseSidebarComponents/CaseSidebarItemComponents/CaseSidebarItemComponents'
import ShowMore from 'components/ShowMore/ShowMore'
import CaseSidebarTiersItem from '../CaseSidebarTiersItem/CaseSidebarTiersItem'

/* Type imports ------------------------------------------------------------- */
import type { PersonneResumeAvecIntervenants } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarTiersCardProps {
  person?: PersonneResumeAvecIntervenants;
}

const CaseSidebarTiersCard: React.FC<CaseSidebarTiersCardProps> = ({ person }) => {
  const [ isOpen, setIsOpen ] = useState<boolean>(false)

  if (person === undefined) {
    return null
  }

  return (
    <CaseSidebarItemComponents.Card>
      <CaseSidebarTiersItem
        role={person.qualite?.libelle}
        firstName={person.prenom}
        lastName={person.nom}
        telephone={person.tel}
      />
      {
        person.intervenants.length > 0 &&
          <ShowMore
            onClick={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
          >
            {
              isOpen === false ?
                person.intervenants.length === 1 ?
                  `Afficher 1 intervenant` :
                  `Afficher ${person.intervenants.length} intervenants` :
                'Réduire'
            }
          </ShowMore>
      }
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
      >
        {
          person.intervenants?.map((stakeholder) => (
            <div key={stakeholder.id}>
              <CaseSidebarTiersItem
                role={stakeholder.role}
                lastName={stakeholder.nom}
                telephone={stakeholder.tel}
                child
              />
            </div>
          ))
        }
      </Collapse>
    </CaseSidebarItemComponents.Card>
  )
}

export default CaseSidebarTiersCard
