/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useOutletContext } from 'react-router-dom'
import { useGetDetailBienRexListQuery } from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { findCodeLabel } from 'helpers/findCodeLabel'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import {
  Select,
  TextField,
} from 'formik-mui'
import CloseButton from 'components/CloseButton/CloseButton'
import NumberField from 'components/FieldWithInputAdornment/NumberField'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'

/* Type imports ------------------------------------------------------------- */
import { Field } from 'formik'
import type { TravelerForm } from 'types/TravelerForm'
import type {
  ActeurTraveller,
  ContratRex, 
} from 'API/__generated__/Api'

/* Internal variables ------------------------------------------------------- */
const getFranchiseLibelle = (franchise?: string | null): string | undefined => {
  if (isValidString(franchise)) {
    return `${franchise} - `
  }
  return
}

const getFranchiseMontant = (montant?: number): string | undefined => {
  if (montant !== undefined && montant > 0) {
    return `${montant}€`
  }
  return
}

const getFranchiseTaux = (taux?: number): string | undefined => {
  if (taux !== undefined && taux > 0) {
    return `${taux}%`
  }
  return
}

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin-bottom: 10px;
  font-size: 13px;
`

const ContentContainer = styled(CardContent)`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr .75fr .75fr;
  gap: 5px;
  align-items: center;
  justify-content: stretch;
  align-items: flex-start;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
`

const GarantieContentContainer = styled(ContentContainer)`
  grid-template-columns: 1fr 3.5fr;
`

const GarantieListContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: stretch;
  align-items: flex-start;
  margin-bottom: 15px;
`

const PlafondListContainer = styled(GarantieListContainer)`
  grid-template-columns: 1fr .75fr;
  margin-bottom: 0px;
`

const BoldText = styled.div`
  font-weight: bold;
`

const BoldUppercaseText = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`

const Title = styled(FormBoldTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px 0px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

const DualGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface ContractInfoProps {
  contractInfo: ContratRex;
  assure: ActeurTraveller;
}

const RiskComplianceContractInfo: React.FC<ContractInfoProps> = ({ contractInfo, assure }) => {
  const formikForm = useOutletContext<TravelerForm>()
  const [ edit, setEdit ] = useState<boolean>(false)

  const {
    currentData: detailBienRexList = [],
    isFetching: isFetchingDetailBienRexList,
  } = useGetDetailBienRexListQuery()

  const setEditContract = () => {
    formikForm.setFieldValue('newRexContract', formikForm.values.conformiteDuRisque.contratRex)
    setEdit(true)
  }

  const onAddNewPlafonds = () => {
    formikForm.setFieldValue('newRexContract.plafondsDetailDeBien', [ ...(formikForm.values.newRexContract.plafondsDetailDeBien || []), { value: 0, code: '', libelle: '' } ])
  }

  const onValidate = () => {
    const newContract = structuredClone(formikForm.values.newRexContract)

    newContract.plafondsDetailDeBien?.forEach((value, index) => {
      if (newContract.plafondsDetailDeBien?.[index]) {
        newContract.plafondsDetailDeBien[index] = { ...value, ...findCodeLabel(detailBienRexList, value.code) }
      }
    })

    formikForm.setFieldValue('conformiteDuRisque.contratRex', newContract)
    setEdit(false)
  }

  return (
    <div>
      <Title bigger>
        Infos contrat
        {
          contractInfo.modifiable &&
            <Button
              variant="contained"
              onClick={setEditContract}
            >
              Modifier
            </Button>
        }
      </Title>
      <CardContainer>
        <ContentContainer>
          Nom du contrat
          <BoldText>
            {contractInfo.nom}
          </BoldText>
        </ContentContainer>
      </CardContainer>
      <CardContainer>
        <ContentContainer>
          Qualité de l'assuré
          <BoldText>
            {assure.qualite?.libelle}
          </BoldText>
        </ContentContainer>
      </CardContainer>
      <CardContainer>
        <ContentContainer>
          Date de souscription
          <BoldText>
            {DateUtils.APIStrToLocalDateString(contractInfo.dateSouscription)}
          </BoldText>
        </ContentContainer>
      </CardContainer>
      <CardContainer>
        <ContentContainer>
          Franchises
          <BoldText>
            Franchise absolue
          </BoldText>
          <BoldText>
            {(contractInfo.franchise || -1) < 0 ? 'Inconnue' : `${contractInfo.franchise}€` }
          </BoldText>
        </ContentContainer>
      </CardContainer>
      {
        contractInfo.plafondsDetailDeBien &&
        contractInfo.plafondsDetailDeBien.length > 0 && (
          <CardContainer>
            <ContentContainer>
              Plafonds
              {
                contractInfo.plafondsDetailDeBien?.map((plafond, index) => (
                  <React.Fragment key={`title-${plafond.code}-${index}`}>
                    <BoldText>
                      {plafond.libelle}
                    </BoldText>
                    <BoldText>
                      {plafond.value}
                      €
                    </BoldText>
                    <div />
                    <div />
                  </React.Fragment>
                ))
              }
            </ContentContainer>
          </CardContainer>
        )
      }
      {
        contractInfo.garanties &&
        contractInfo.garanties.length > 0 && (
          <CardContainer>
            <GarantieContentContainer>
              Garanties
              <div>
                {
                  contractInfo.garanties.map((garantie, index) => (
                    <GarantieListContainer key={`${garantie.code}-${index}`}>
                      <PlafondListContainer>
                        <BoldUppercaseText>
                          {garantie.libelle}
                        </BoldUppercaseText>
                        <BoldText>
                          {
                            garantie.franchises?.map((franchise, franchiseIndex) => (
                              <div key={`${franchise.typeFranchise?.code}-${franchiseIndex}`}>
                                {getFranchiseLibelle(garantie.franchises?.[franchiseIndex]?.typeFranchise?.libelle)}
                                {getFranchiseMontant(garantie.franchises?.[franchiseIndex]?.montantFranchise)}
                                {getFranchiseTaux(garantie.franchises?.[franchiseIndex]?.tauxFranchise)}
                              </div>
                            ))
                          }
                        </BoldText>
                      </PlafondListContainer>
                      {
                        garantie.plafonds?.map((plafond, plafondIndex) => (
                          <PlafondListContainer key={`${plafond.code}-${plafondIndex}`}>
                            <BoldText>
                              {plafond.libelle}
                            </BoldText>
                            <BoldText>
                              {`${plafond.value}€`}
                            </BoldText>
                          </PlafondListContainer>
                        ))
                      }
                    </GarantieListContainer>
                  ))
                }
              </div>
            </GarantieContentContainer>
          </CardContainer>
        )
      }
      {
        edit &&
          <Dialog
            open
            onClose={() => setEdit(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitleContainer>
              Modifier le contrat
              <CloseButton handleClose={() => setEdit(false)} />
            </DialogTitleContainer>
            <DialogContentContainer>
              <DualGrid>
                <div>
                  <FormBoldTitle>
                    Nom du contrat
                  </FormBoldTitle>
                  <Field
                    component={TextField}
                    placeholder="Nom"
                    name="newRexContract.nom"
                  />
                </div>
                <div>
                  <FormBoldTitle>
                    Date de souscription
                  </FormBoldTitle>
                  <FormikDatePicker name="newRexContract.dateSouscription" />
                </div>
              </DualGrid>
              <Title>
                Plafonds
                <Button
                  variant="outlined"
                  onClick={onAddNewPlafonds}
                >
                  Ajouter un plafond
                </Button>
              </Title>
              {
                formikForm.values.newRexContract.plafondsDetailDeBien?.map((value, index) => (
                  <DualGrid key={`${value.code}-${index}`}>
                    <Field
                      name={`newRexContract.plafondsDetailDeBien[${index}].code`}
                      component={Select}
                      displayEmpty
                      renderValue={verifySelectFieldValue(formikForm.values.newRexContract.plafondsDetailDeBien?.[index].code)}
                      disabled={isFetchingDetailBienRexList}
                    >
                      {
                        detailBienRexList.map((detail, index) => (
                          <MenuItem
                            value={detail.code}
                            key={`${detail.code}-${index}`}
                          >
                            {detail.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                    <NumberField name={`newRexContract.plafondsDetailDeBien[${index}].value`} />
                  </DualGrid>
                ))
              }
            </DialogContentContainer>
            <DialogActionContainer>
              <FormButton
                variant="contained"
                onClick={onValidate}
              >
                Valider
              </FormButton>
            </DialogActionContainer>
          </Dialog>
      }
    </div>
  )
}

export default RiskComplianceContractInfo
