/* Framework imports -------------------------------------------------------- */
import React from 'react'
import {
  css,
  cx,
} from '@emotion/css'
import { useTheme } from '@emotion/react'

/* Type imports ------------------------------------------------------------- */
import type { Theme as EmotionTheme } from '@emotion/react'

/* Styled components -------------------------------------------------------- */
const transitionTime: number = 0.1
const transitionEasing: string = 'cubic-bezier(0, 0.5, 0.5, 1)'

const drawerOverlayStyle = (width: string, open: boolean, emotionTheme: EmotionTheme): string => css`
  position: absolute;

  right: ${open ? '0' : `-${width}`};
  top: 0;
  bottom: 0;

  width: ${width};

  background-color: ${emotionTheme.palette.background.paper};

  overflow-y: auto;

  transition: right ${transitionTime}s ${transitionEasing};

  z-index: 301;

  @media screen and (max-width: ${width}) {
    width: 100%
  }
`

const drawerOverlayBgStyle = (width: string, open: boolean): string => css`
  position: absolute;

  right: ${open ? width : `0`};
  left: ${open ? '0' : '100%'};
  top: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, ${open ? '0.5' : '0'});

  transition: background-color ${transitionTime}s ${transitionEasing}, right ${transitionTime}s ${transitionEasing}${open ? '' : `, left 0s linear ${transitionTime}s`};

  z-index: 300;
`

/* Component declaration ---------------------------------------------------- */
interface DrawerProps {
  children: React.ReactNode;
  width: string;
  open: boolean;
  onClose: () => void;
  className?: string;
  id?: string;
}

const Drawer: React.FC<DrawerProps> = ({
  children,
  width,
  open,
  onClose,
  className,
  id,
}) => {
  const emotionTheme: EmotionTheme = useTheme()

  return (
    <>
      <aside
        className={
          cx(
            [
              drawerOverlayStyle(width, open, emotionTheme),
              className,
            ],
          )
        }
        id={id}
      >
        {children}
      </aside>
      <div
        className={drawerOverlayBgStyle(width, open)}
        onClick={() => {onClose()}}
      />
    </>
  )
}

export default Drawer
