/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { findCodeLabel } from 'helpers/findCodeLabel'

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import CloseButton from 'components/CloseButton/CloseButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Type imports ------------------------------------------------------------- */
import type { CodeLabel } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

/* Component declaration ---------------------------------------------------- */
interface AddRoomModalProps {
  open: boolean;
  handleClose: (edit?: boolean) => void;
  handleValue: (type: string, value: string) => void;
  selectList: CodeLabel[];
  disabled: boolean;
}

const AddRoomModal: React.FC<AddRoomModalProps> = ({
  open,
  handleClose,
  selectList,
  handleValue,
  disabled,
}) => {
  const [ type, setType ] = useState<CodeLabel>({ code: '', libelle: '' })

  const handleTypeChange = (newType: string): void => {
    handleValue('pieceLibelle', findCodeLabel(selectList, newType).libelle || '')
    setType(findCodeLabel(selectList, newType))
  }

  const handleQuit = (): void => {
    handleValue('pieceLibelle', '')
    handleClose(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleQuit}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitleContainer>
        Ajouter / Modifier une pièce
        <CloseButton handleClose={handleQuit} />
      </DialogTitleContainer>
      <DialogContent>
        <FormBoldTitle>
          Type de pièce :
        </FormBoldTitle>
        <Select
          displayEmpty
          fullWidth
          value={type.code}
          renderValue={verifySelectFieldValue(type.code)}
          onChange={(e): void => handleTypeChange(e.target.value)}
          id="roomType"
        >
          {
            selectList.map((option) => (
              <MenuItem
                value={option.code}
                key={option.code}
              >
                {option.libelle}
              </MenuItem>
            ))
          }
        </Select>
        <FormBoldTitle required>
          Nom :
        </FormBoldTitle>
        <Field
          component={TextField}
          placeholder="Nom de la pièce"
          name="newDamage.pieceLibelle"
        />
      </DialogContent>
      <DialogActionContainer>
        <FormButton
          variant="contained"
          onClick={() => handleClose(true)}
          disabled={disabled}
          id="validateNewRoom"
        >
          Valider
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default AddRoomModal
