/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from 'react-filerobot-image-editor'

/* Type declarations -------------------------------------------------------- */
type cropPresetItem = {
  titleKey: string;
  width?: number;
  height?: number;
  ratio?: string;
  descriptionKey?: string;
  icon?: string | HTMLElement | React.FunctionComponent;
  disableManualResize?: boolean;
}

/* Internal variables ------------------------------------------------------- */
const translations = {
  save: 'Enregistrer',
  resize: 'Modifier la taille',
  resizeTab: 'Modifier la taille',
  annotateTab: 'Annoter',
  flipX: 'Retourner horizontalement',
  flipY: 'Retourner verticalement',
  unFlipX: 'Retourner horizontalement',
  unFlipY: 'Retourner verticalement',
}

const defaultSourceUrl: string = 'https://scaleflex.cloudimg.io/v7/demo/river.png'

const presetItems: cropPresetItem[] = [
  {
    titleKey: 'classicTv',
    descriptionKey: '4:3',
    ratio: (4 / 3).toString(),
    // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
  },
  {
    titleKey: 'cinemascope',
    descriptionKey: '21:9',
    ratio: (21 / 9).toString(),
    // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
  },
]

/* Styled components -------------------------------------------------------- */
const ImageProcessorContainer = styled.div`
  height: 100%;
`

/* Component declaration ---------------------------------------------------- */
interface ImageProcessorProps {
  source: string;
}

const ImageProcessor: React.FC<ImageProcessorProps> = ({ source = defaultSourceUrl }) => {
  const downloadBase64File = (base64Data: string, fileName: string): void => {
    const downloadLink = document.createElement('a')
    downloadLink.href = base64Data
    downloadLink.download = fileName
    downloadLink.click()
  }

  return (
    <ImageProcessorContainer>
      {
        isValidString(source) &&
          <FilerobotImageEditor
            previewPixelRatio={0}
            savingPixelRatio={0}
            source={source}
            language="fr"
            translations={translations}
            onSave={(editedImageObject): void => downloadBase64File(editedImageObject.imageBase64 || '', editedImageObject.fullName || editedImageObject.name)}
            annotationsCommon={{ fill: '#ff0000' }}
            Text={{ text: 'Votre texte...' }}
            Rotate={{ angle: 90, componentType: 'slider' }}
            Crop={{ presetsItems: presetItems }}
            tabsIds={[ TABS.ADJUST, TABS.ANNOTATE, TABS.RESIZE ]} // or {['Adjust', 'Annotate', 'Watermark']}
            defaultTabId={TABS.ADJUST} // or 'Annotate'
            defaultToolId={TOOLS.TEXT}
          />
      }
    </ImageProcessorContainer>
  )
}

export default ImageProcessor
