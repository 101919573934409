/* Type declarations -------------------------------------------------------- */
export enum TypeDommageSinapps {
  Dommage = 'Dommage',
  Bien = 'Bien',
}

export enum Taxe {
  HT = 'HT',
  TTC = 'TTC',
}

export enum TypeDeBien {
  Immobilier = 'Immobilier',
  Embellissement = 'Embellissement',
  Mobilier = 'Mobilier',
  Autre = 'Autre',
}

export enum TypeDeReparation {
  AR = 'AR',
  IP = 'IP',
  REN = 'REN',
  RAN = 'RAN',
  RM = 'RM',
}

export enum ReparabiliteMob {
  Reparable = 'Reparable',
  NonReparable = 'NonReparable',
  Vole = 'Vole',
}

export enum Justifications {
  J = 'J',
  JE = 'JE',
  SJ = 'SJ',
}

export interface CodeLabel {
  code: string;
  libelle?: string | null;
}

export interface TauxTVA {
  tva?: CodeLabel;
  taux: number;
}

export interface TypeDeReparationEnumLabel {
  code: TypeDeReparation;
  libelle?: string | null;
}

export interface DommageValeur {
  quantite?: number;
  prixUnitaire?: number;
  uniteMesure?: CodeLabel;
  montantHT: number;
  tauxTva: TauxTVA;
  taxe?: Taxe;
  montantTva?: number;
  montantTTC: number;
}

export interface ReparabiliteMobEnumLabel {
  code: ReparabiliteMob;
  libelle?: string | null;
}

export interface JustificationsEnumLabel {
  code: Justifications;
  libelle?: string | null;
}

export interface Reparation {
  reparabilite: ReparabiliteMobEnumLabel;
  justificatif?: JustificationsEnumLabel;
}

export interface DommageVetuste {
  tauxVetuste?: number;
  age?: number | null;
  montantVetuste: number;
  tauxLimiteVetuste?: number;
  montantVetusteRecuperable: number;
  montantVetusteDeduite: number;
  dateAchat?: string | null;
  reparation: Reparation;
}

export interface DommageInfosSinapps {
  idDommageSinapps?: string | null;
  idDetailDommageSinapps?: string | null;
  natureDuBienSinapps: CodeLabel;
  detailDuBienSinapps: CodeLabel;
  typeDommageSinapps?: TypeDommageSinapps;
}

export interface Dommage {
  id: string;
  libelle?: string;
  typeBien?: TypeDeBien;
  natureDuBien?: CodeLabel;
  detailDuBien?: CodeLabel;
  typeDeReparation: TypeDeReparationEnumLabel;
  valeur: DommageValeur;
  vetuste: DommageVetuste;
  sinapps: DommageInfosSinapps;
}

export interface Indemnisation {
  id: string;
  typeDeReparation: TypeDeReparationEnumLabel;
  natureDuBien?: CodeLabel;
  detailDuBien?: CodeLabel;
  montantHT: number;
  montantTTC: number;
  montantVetusteDeduiteTTC: number;
  montantVetusteRecuperable: number;
  montantDecouvert: number;
  garantie?: CodeLabel;
}

export interface DommageSinapps {
  id: string;
  garantie?: CodeLabel;
  typeBien?: TypeDeBien;
  natureDuBien?: CodeLabel;
  detailDuBien?: CodeLabel;
  modeIndemnisation?: CodeLabel;
  montantHT?: number;
  tauxTva?: number;
  montantTva?: number;
  montantTTC?: number;
  tauxVetuste?: number;
  montantVetusteHT: number;
  montantVetusteTTC: number;
  montantVetusteDeduiteHT: number;
  montantVetusteDeduiteTTC: number;
  montantVetusteRecuperableHT: number;
  montantVetusteRecuperableTTC: number;
  plafond?: number;
  idDommageSinapps?: string | null;
  idDetailDommageSinapps?: string | null;
}

export interface DommageBienSinapps {
  id: string;
  garantie?: CodeLabel;
  typeBien?: TypeDeBien;
  justificatif?: JustificationsEnumLabel;
  montantHT: number;
  montantTva?: number;
  montantTTC: number;
  montantVetusteHT: number;
  montantVetusteTTC: number;
  montantVetusteRecuperableHT: number;
  montantVetusteRecuperableTTC: number;
  plafond?: number;
  dateAchat?: string | null;
  libelle?: string | null;
  reparabilite: ReparabiliteMobEnumLabel;
  idDommageSinapps?: string | null;
  idDetailDommageSinapps?: string | null;
  natureDuBienSinapps: CodeLabel;
}

export interface IndemnisationSinapps {
  garantie?: CodeLabel;
  montantIndemniteImmediateHTAvant: number;
  montantIndemniteImmediateTTCAvant: number;
  montantIndemniteImmediateHTApres: number;
  montantIndemniteImmediateTTCApres: number;
  montantIndemniteDiffereeHTAvant: number;
  montantIndemniteDiffereeTTCAvant: number;
  montantIndemniteDiffereeHTApres: number;
  montantIndemniteDiffereeTTCApres: number;
  tauxPenalite?: number;
  montantPenaliteImmediateHT?: number;
  montantPenaliteImmediateTTC?: number;
  montantPenaliteDiffereeHT?: number;
  montantPenaliteDiffereeTTC?: number;
  montantFranchiseImmediateHT?: number;
  montantFranchiseImmediateTTC: number;
  montantFranchiseDiffereeHT: number;
  montantFranchiseDiffereeTTC: number;
  montantFranchiseHT?: number;
  montantFranchiseTTC?: number;
}
