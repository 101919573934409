/* Framework imports -------------------------------------------------------- */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import DateUtils from 'helpers/DateUtils'
import {
  useLazyGetInternalExchangesRecipientListQuery,
  usePostViewedInternalExchangeMutation,
  usePostNotViewedInternalExchangeMutation,
} from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import {
  Button,
  CircularProgress,
} from '@mui/material'
import CaseSidebarItemComponents from 'layouts/CaseSidebar/CaseSidebarComponents/CaseSidebarItemComponents/CaseSidebarItemComponents'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ClickableColoredSquareChip from 'components/ColoredSquareChip/ClickableColoredSquareChip'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type {
  CodeLabel,
  EchangeInterne,
  EchangeInterneDestinataire,
  EchangeInterneEmetteur,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const messageSchema = Yup.object({
  message: Yup.string().required('Le message est obligatoire'),
  action: Yup.mixed<'transferer'>().required().nullable(),
  transfererA: Yup.mixed<CodeLabel>().when('action', {
    is: 'transferer',
    then: () => Yup.mixed<CodeLabel>().test(
      'is-not-empty',
      'Le destinataire est obligatoire',
      (value) => isValidString(value?.code),
    ).required('Le destinataire est obligatoire'),
  }),
})

type MessageRequest = Yup.InferType<typeof messageSchema>

/* Styled components -------------------------------------------------------- */
const ForwardContainer = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  justify-content: stretch;
  margin-bottom: 10px;
`

const ForwardTo = styled(FormBoldTitle)`
  margin-top: 0px;
  margin-bottom: 0px;
  align-self: center;
  font-size: .9rem;
`

const ButtonLine = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`

const FieldContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const AnswerButton = styled(Button)`
  min-width: 48%;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarNotificationMenuItemProps {
  notification: EchangeInterne;
}

const CaseSidebarNotificationMenuItem: React.FC<CaseSidebarNotificationMenuItemProps> = ({ notification }) => {
  const authInfo = useAuthInfo()

  const [
    getRecipientList,
    {
      currentData: recipientList = [],
      isFetching: isFetchingRecipientList,
    },
  ] = useLazyGetInternalExchangesRecipientListQuery()

  const [
    submitViewed,
    { isLoading: isSubmittingViewed },
  ] = usePostViewedInternalExchangeMutation()

  const [
    submitNotViewed,
    { isLoading: isSubmittingNotViewed },
  ] = usePostNotViewedInternalExchangeMutation()

  const onSubmit = (values: MessageRequest, { setSubmitting }: FormikHelpers<MessageRequest>): void => {
    console.log('values', values)
    setSubmitting(false)
  }

  const formikForm = useForm<MessageRequest>(
    {
      initialValues: {
        message: '',
        action: null,
        transfererA: { code: '', libelle: '' },
      },
      onSubmit: onSubmit,
      validationSchema: messageSchema,
    },
  )

  const handleValue = (type: string, value?: string | boolean | string[] | null): void => {
    formikForm.setFieldValue(type, value).catch(console.error)
  }

  useEffect(() => {
    if (formikForm.values.action === 'transferer') {
      getRecipientList({
        dossier: notification.dossier.id,
        assistante: true,
        expert: true,
      }).catch(console.error)
    }
  }, [
    formikForm.values.action,
    getRecipientList,
    notification.dossier.id,
  ])

  const isMe = (person: EchangeInterneDestinataire | EchangeInterneEmetteur) => person.id === authInfo?.currentCollaborateur?.refAnnuaire.refComplete ? 'Vous' : person.nom

  const handleChipClick = (): void => {
    if (isMe(notification.emetteur) === 'Vous' && isMe(notification.destinataire) !== 'Vous') return
    if (notification.lu) {
      submitNotViewed({ id: notification.id }).catch(console.error)
    } else {
      submitViewed({ id: notification.id }).catch(console.error)
    }
  }

  return (
    <Form form={formikForm}>
      <CaseSidebarItemComponents.Card>
        <CaseSidebarItemComponents.Title>
          <CaseSidebarItemComponents.PersonContainer>
            {isMe(notification.emetteur)}
            {
              notification.destinataire?.nom &&
                <React.Fragment>
                  <div>
                    à
                  </div>
                  {isMe(notification.destinataire)}
                </React.Fragment>
            }
          </CaseSidebarItemComponents.PersonContainer>
          <CaseSidebarItemComponents.DateContainer>
            {DateUtils.APIStrToLocalDateString(notification.date, { hour: '2-digit', minute: '2-digit' })}
            {
              isSubmittingNotViewed || isSubmittingViewed ?
                <CircularProgress /> :
                <ClickableColoredSquareChip
                  color={notification.lu ? 'green' : 'red'}
                  onClick={handleChipClick}
                >
                  {notification.lu ? 'Lu' : 'Nouveau'}
                </ClickableColoredSquareChip>
            }
          </CaseSidebarItemComponents.DateContainer>
        </CaseSidebarItemComponents.Title>
        <CaseSidebarItemComponents.Object>
          {notification.objet}
        </CaseSidebarItemComponents.Object>
        <CaseSidebarItemComponents.Text>
          {notification.message}
        </CaseSidebarItemComponents.Text>
        <div>
          {
            formikForm.values.action !== null &&
              <FieldContainer>
                {
                  formikForm.values.action === 'transferer' &&
                    <ForwardContainer>
                      <ForwardTo required>
                        Transférer à :
                      </ForwardTo>
                      {
                        isFetchingRecipientList ?
                          <CircularProgress /> :
                          <AutocompleteField
                            name="transfererA"
                            options={recipientList}
                          />
                      }
                    </ForwardContainer>
                }
                <Field
                  component={TextField}
                  rows={3}
                  multiline
                  name="message"
                  placeholder="Votre message"
                />
              </FieldContainer>
          }
        </div>
        <ButtonLine>
          {
            formikForm.values.action !== null &&
              <AnswerButton
                variant="contained"
                type="submit"
                disabled={isFetchingRecipientList}
              >
                {
                  isFetchingRecipientList ?
                    <CircularProgress /> :
                    'Envoyer'
                }
              </AnswerButton>
          }
          {
            formikForm.values.action !== 'transferer' &&
              <AnswerButton
                variant="outlined"
                onClick={() => handleValue('action', 'transferer')}
              >
                Transférer
              </AnswerButton>
          }
        </ButtonLine>
      </CaseSidebarItemComponents.Card>
    </Form>
  )
}

export default CaseSidebarNotificationMenuItem
