/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { closeQuickActions } from 'store/slices/quickActionModalSlice'

/* Component imports -------------------------------------------------------- */
import Button from '@mui/material/Button'

/* Styled components -------------------------------------------------------- */
const QAButton = styled(Button)`
  height: 50px;
  margin: 5px;
`

type ActionType = 'tache' | 'commentaire' | 'mail' | 'echange-interne' | 'commande-sinapps' | 'fiche-vigilance' | 'courrier'

/* Component declaration ---------------------------------------------------- */
interface QuickActionsButtonProps {
  caseId: string;
  actionType: ActionType;
  children: React.ReactNode;
  disabled?: boolean;
}

const QuickActionsButton: React.FC<QuickActionsButtonProps> = ({
  caseId,
  actionType,
  children,
  disabled,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleClick = (): void => {
    dispatch(closeQuickActions())
    navigate(`/actions-rapides/${caseId}/${actionType}`)
  }

  return (
    <QAButton
      variant="outlined"
      size="large"
      fullWidth
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </QAButton>
  )
}

export default QuickActionsButton
