/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'
import type {
  DataDocument,
  PieceJointe,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export interface CourrierState {
  courriers: DataDocument[];
  attachments: PieceJointe[];
}

/* Redux slice -------------------------------------------------------------- */
const courrierSlice = createSlice(
  {
    name: 'courrier',
    initialState: {
      courriers: [],
      attachments: [],
    } as CourrierState,
    reducers: {
      setCourriers: (state, { payload }: PayloadAction<DataDocument[]>) => {
        state.courriers = payload
      },
      setAttachment: (state, { payload }: PayloadAction<PieceJointe[]>) => {
        state.attachments = payload
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const { setCourriers, setAttachment } = courrierSlice.actions

export default courrierSlice.reducer

export const getCourrierState = (state: RootState): CourrierState => {
  return state.courrier
}

export const getCourriers = (state: RootState): DataDocument[] => {
  return state.courrier.courriers
}

export const getAttachments = (state: RootState): PieceJointe[] => {
  return state.courrier.attachments
}
