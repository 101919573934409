/* DateUtils methods -------------------------------------------------------- */
const isValidDate = (d: unknown): d is Date => {
  return d instanceof Date &&
  !Number.isNaN(d.valueOf())
}

const dateToAPIStr = (pDate: Date): string => {
  return pDate.toISOString()
}

const dateToShortAPIStr = (pDate: Date): string => {
  return dateToAPIStr(pDate)
    .split('T')[0]
}

const APIStrToDate = (pDateStr: string): Date => {
  return new Date(pDateStr)
}

const APIStrToDateOrUndefined = (pDateStr?: string | null): Date | undefined => {
  if (pDateStr === undefined || pDateStr === null) {
    return
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) {
    return
  }

  return date
}

const APIStrToLocalDateString = (pDateStr?: string | null, options?: Intl.DateTimeFormatOptions): string => {
  if (pDateStr === undefined || pDateStr === null) {
    return ''
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) {
    return ''
  }

  return date.toLocaleDateString('fr-FR', options)
}

const APIStrToLocalTimeString = (pDateStr?: string | null, options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' }): string => {
  if (pDateStr === undefined || pDateStr === null) {
    return ''
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) return ''

  return date.toLocaleTimeString('fr-FR', options)
}

const getMostRecentDate = (dates: (string | null | undefined)[]): { date: Date; index: number } | null => {
  let mostRecent: { date: Date; index: number } | null = null

  dates.forEach((dateStr, index) => {
    const parsedDate = dateStr ? new Date(dateStr) : null

    if (parsedDate && !isNaN(parsedDate.getTime())) {
      if (!mostRecent || parsedDate > mostRecent.date) {
        mostRecent = { date: parsedDate, index }
      }
    }
  })

  return mostRecent
}

const planningStartDateApi = dateToShortAPIStr(new Date(new Date().setDate(new Date().getDate() - 21)))
const planningEndDateApi = dateToShortAPIStr(new Date(new Date().setMonth(new Date().getMonth() + 3)))

/* Export DateUtils ------------------------------------ */
export default {
  isValidDate,
  dateToAPIStr,
  dateToShortAPIStr,
  APIStrToDate,
  APIStrToDateOrUndefined,
  APIStrToLocalDateString,
  APIStrToLocalTimeString,
  getMostRecentDate,
  planningStartDateApi,
  planningEndDateApi,
}
