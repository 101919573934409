/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import RouteTitle from 'routers/Router/RouteTitle'

/* Type imports ------------------------------------------------------------- */
import type { TravelerForm } from 'types/TravelerForm'

/* Component declaration ---------------------------------------------------- */
interface TravelerAddLayoutProps {}

const TravelerAddLayout: React.FC<TravelerAddLayoutProps> = () => {
  const { formikForm } = useOutletContext<{formikForm: TravelerForm}>()
  const location = useLocation()
  const { caseId } = useParams<{ caseId: string }>()
  const [ title, setTitle ] = useState<string>('')

  const tabs = [
    { title: 'Nouvel intervenant', path: 'traveller/nouveau/intervenant' },
    { title: 'Nouveau dommage', path: 'traveller/nouveau/dommage' },
    { title: 'Nouveau recours', path: 'traveller/nouveau/recours' },
    { title: 'Nouveau reglement', path: 'traveller/nouveau/reglement' },
    { title: 'Modifier un intervenant', path: 'traveller/modification/intervenants' },
    { title: 'Modifier un dommage', path: 'traveller/modification/dommage' },
  ]

  useEffect(() => {
    const newTabValue = tabs.findIndex((tab) => location.pathname.indexOf(tab.path) !== -1)
    if (tabs[newTabValue]?.title) {
      setTitle(`${caseId} - ${tabs[newTabValue].title}`)
    }
  }, [ location.pathname ])

  return (
    <>
      <RouteTitle title={title} />
      <Outlet context={formikForm} />
    </>
  )
}

export default TravelerAddLayout
