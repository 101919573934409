/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useOutletContext,
  useParams,
} from 'react-router-dom'
import {
  useGetIrsiBuildingStatusListQuery,
  useGetIrsiCostingAgreementListQuery,
  useGetIrsiDeleteCauseListQuery,
  useGetIrsiDisasterNatureListQuery,
  useGetIrsiExpertiseTypeListQuery,
  useGetIrsiInsuranceCompanyListQuery,
  useGetIrsiPersonQualifiantListQuery,
  useGetIrsiPersonRoleListQuery,
  useGetIrsiPropertyUsageListQuery,
  useGetIrsiQualitePersonListQuery,
  useGetIrsiRiskAddressListQuery,
  usePostTravelerIrsiMutation,
} from 'store/api'
import { enumToSegmentedButtonOptions } from 'helpers/enumToSegmentedButtonOptions'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { handleNumberVerification } from 'helpers/numberUtils'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import { toast } from 'react-toastify'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import Tabs from 'components/Tabs/Tabs'
import TabPanel from 'components/TabPanel/TabPanel'
import PriceField from 'components/FieldWithInputAdornment/PriceField'

/* Type imports ------------------------------------------------------------- */
import type {
  Cause,
  CodeLabel,
  IrsiRequest,
  PersonneImplique,
  Reference,
} from 'API/__generated__/Api'
import { RegimeFiscal } from 'API/__generated__/Api'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { TravelerForm } from 'types/TravelerForm'

/* Styled components -------------------------------------------------------- */
const BoldText = styled.div`
  font-weight: bold;
`

const FormContainer = styled.div`
  padding-bottom: 40px;
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  align-items: start;
  justify-content: stretch;

  margin-bottom: 10px;
`

const FourGridNoMarginContainer = styled(GridContainer)`
  margin-bottom: 0px;
`

const OneFiveGridContainer = styled(GridContainer)`
  grid-template-columns: 1fr 5fr;
`

const OneOneTwoGridContainer = styled(GridContainer)`
  grid-template-columns: 1fr 1fr 2fr;
`

const OneOneTwoGridNoMarginContainer = styled(OneOneTwoGridContainer)`
  margin-bottom: 0px;
`

const TwoOneOneGridContainer = styled(GridContainer)`
  grid-template-columns: 2fr 1fr 1fr;
`

const TwoOneOneGridNoMarginContainer = styled(TwoOneOneGridContainer)`
  margin-bottom: 0px;
`

const TwoGridContainer = styled(GridContainer)`
  grid-template-columns: repeat(2, 1fr);
`

const TwoGridNoMarginContainer = styled(TwoGridContainer)`
  margin-bottom: 0px;
  `

const ThreeGridContainer = styled(GridContainer)`
  grid-template-columns: repeat(3, 1fr);
  `

const ThreeGridNoMarginContainer = styled(ThreeGridContainer)`
  margin-bottom: 0px;
  gap: 40px;
  @media ${(props) => props.theme.media.mobile.main} {
    gap: 5px;
  }
`

const CardContainer = styled(Card)`
  font-size: 14px;
`

const CauseCardContainer = styled(Card)`
  font-size: 14px;
  margin-bottom: 20px;
`

const CardContentContainer = styled(CardContent)`
  padding-top: 0px;
`

const CardContentNoTitleContainer = styled(CardContent)`
  padding-top: 20px;
  padding-bottom: 20px !important;
`

const CardContentNoPaddingContainer = styled(CardContent)`
  padding-top: 10px;
  padding-bottom: 10px !important;
`

const CardContentTabsContainer = styled(CardContent)`
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
`

const SmallBoldTitle = styled(FormBoldTitle)`
  font-size: 14px;
`

const AddCauseContainer = styled.div`
  display: flex;
  place-content: space-between;
  font-size: 1.15rem;
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  margin: 20px 0px;
  align-items: center;
`

const BoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const LightSeparator = styled(BoldSeparator)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  margin-top: 10px;
`

const FlexCheckableButton = styled(CheckableButton)`
  height: 100%;
  label {
    padding: 5px 0px;
  }
`

const RecourseButton = styled(Button)`
  margin-top: 20px;
  width: 400px;
  height: 45px;
  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

const BoldUppercaseTitle = styled(FormBoldTitle)`
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 0px;
`

const CenteredText = styled.div`
  text-align: center;
`

const ButtonSaveGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
`

/* Component declaration ---------------------------------------------------- */
interface IrsiPageProps {}

const IrsiPage: React.FC<IrsiPageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ personTabValue, setPersonTabValue ] = useState<number>(0)
  const [ costingTabValue, setCostingTabValue ] = useState<number>(0)
  const [ isSaving, setIsSaving ] = useState<boolean>(false)
  const timelineLoaded = useRef<boolean>(false)

  const {
    currentData: qualiteList = [],
    isFetching: isFetchingQualiteList,
  } = useGetIrsiQualitePersonListQuery(caseId)
  const {
    currentData: riskAddressList = [],
    isFetching: isFetchingRiskAddressList,
  } = useGetIrsiRiskAddressListQuery(caseId)
  const {
    currentData: buildingStatusList = [],
    isFetching: isFetchingBuildingStatusList,
  } = useGetIrsiBuildingStatusListQuery()
  const {
    currentData: propertyUsageList = [],
    isFetching: isFetchingPropertyUsageList,
  } = useGetIrsiPropertyUsageListQuery()
  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetIrsiDisasterNatureListQuery()
  const {
    currentData: deleteCauseList = [],
    isFetching: isFetchingDeletCauseList,
  } = useGetIrsiDeleteCauseListQuery()
  const {
    currentData: costingAgreementList = [],
    isFetching: isFetchingCostingAgreementList,
  } = useGetIrsiCostingAgreementListQuery()
  const {
    currentData: personQualifiantList = [],
    isFetching: isFetchingPersonQualifiantList,
  } = useGetIrsiPersonQualifiantListQuery()
  const {
    currentData: personRoleList = [],
    isFetching: isFetchingPersonRoleList,
  } = useGetIrsiPersonRoleListQuery()
  const {
    currentData: insuranceCompanyList = [],
    isFetching: isFetchingInsuranceCompanyList,
  } = useGetIrsiInsuranceCompanyListQuery()
  const {
    currentData: expertiseTypeList = [],
    isFetching: isFetchingExpertiseTypeList,
  } = useGetIrsiExpertiseTypeListQuery()
  const [
    submitCaseIrsi,
  ] = usePostTravelerIrsiMutation()

  const handleIrsiSubmit = (): void => {
    setIsSaving(true)
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        console.log('IrsiSubmit values', formikForm.values.irsiData, '\nerrors', formikForm.errors.irsiData)
        const data = formikForm.values.irsiData
        const irsiForm: IrsiRequest = {
          ...data,
          expert: {
            ...data.expert,
            typeExpertise: data.expert.typeExpertise.code,
          },
          circonstance: {
            ...data.circonstance,
            natureSinistre: data.circonstance.natureSinistre.code,
          },
          causes: data.causes.map((causes) => ({
            ...causes,
            causeSinistre: causes.causeSinistre?.code,
            casBareme: causes.casBareme?.code,
            causeBareme: causes.causeBareme?.code,
            causeSupprimee: causes.causeSupprimee?.code,
          })),
          risque: {
            ...data.risque,
            statutImmeuble: data.risque.statutImmeuble.code,
            usageAssure: data.risque.usageAssure.code,
          },
          personnesImpliquees: data.personnesImpliquees.map((implicated) => ({
            ...implicated,
            nomAssurance: implicated.nomAssurance?.code,
            presente: implicated.presente || true,
            qualite: implicated.qualite?.code || '',
            role: implicated.role?.code,
            qualifiants: implicated.qualifiants?.map((qualifiant) => qualifiant.code),
            chiffrage: {
              ...implicated.chiffrage,
              accord: implicated.chiffrage.accord?.code,
              prejudicesMateriels: implicated.chiffrage.prejudicesMateriels?.map((prejudicesMateriels) => ({
                ...prejudicesMateriels,
                tauxTVA: prejudicesMateriels.tauxTVA?.tva.code,
                fraisAfferents: prejudicesMateriels.fraisAfferents?.map((fraisAfferents) => ({
                  ...fraisAfferents,
                  typeFrais: fraisAfferents.typeFrais.code,
                })),
              })),
              prejudicesImmateriels: implicated.chiffrage.prejudicesImmateriels?.map((prejudicesImmateriels) => ({
                ...prejudicesImmateriels,
                type: parseInt(prejudicesImmateriels.type.code),
                tauxTVA: prejudicesImmateriels.tauxTVA?.tva.code,
              })),
            },
          })),
        }
        if (Object.keys(errors).some((error) => !error.indexOf('irsiData'))) {
          return submitCaseIrsi({
            query: {
              dossier: caseId,
              validation: false,
            },
            data: irsiForm,
          })
        } else {
          return submitCaseIrsi({
            query: {
              dossier: caseId,
              validation: true,
            },
            data: irsiForm,
          })
        }
      })
      .then((response) => {
        setIsSaving(false)
        if (!isApiError(response)) {
          // success
        } else {
          toast.error('Une erreur est survenue, veuillez verifier les données du formulaire IRSI.')
          console.log('IrsiSubmit errors', response)
        }
      }).catch(console.error)
  }

  const handleValue = (type: string, value?: string | number |boolean | string[] | Cause[] | CodeLabel[] | Reference[]): void => {
    formikForm.setFieldValue(`irsiData.${type}`, value)
  }

  const insuredIndex = useMemo(() => {
    return formikForm.values.irsiData?.personnesImpliquees?.findIndex((person) => person.id === formikForm.values.irsiData?.assure.personne?.id) || 0
  }, [ formikForm.values.irsiData?.assure, formikForm.values.irsiData?.personnesImpliquees ])

  const handleImplicatedPersonCheckedButton = (personIndex: number, value: CodeLabel, checked: boolean): void => {
    const newValues: CodeLabel[] | undefined = [ ...formikForm.values.irsiData.personnesImpliquees[personIndex].qualifiants || [] ]

    if (!checked) {
      handleValue(`personnesImpliquees[${personIndex}].qualifiants`, newValues?.filter((val) => val.code !== value.code))
    }
    else {
      newValues?.push(value)
      handleValue(`personnesImpliquees[${personIndex}].qualifiants`, newValues)
    }
  }

  const addCause = (): void => {
    let causes: Cause[] = []

    if (formikForm.values.irsiData.causes !== null && formikForm.values.irsiData.causes !== undefined) {
      causes = [ ...formikForm.values.irsiData.causes ]
    }
    causes.push({
      casBareme: { code: '', libelle: '' },
      causeBareme: undefined,
      causeSupprimee: { code: '', libelle: '' },
      causeSinistre: { code: '', libelle: '' },
    })
    handleValue(`causes`, causes)
  }

  const handlePersonTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setPersonTabValue(newValue)
  }

  const handleCostingTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setCostingTabValue(newValue)
  }

  const getTabs = (): string [] => {
    if (formikForm.values.irsiData.personnesImpliquees !== undefined && formikForm.values.irsiData.personnesImpliquees.length > 0) {
      return [
        ...formikForm.values.irsiData.personnesImpliquees.map(
          (person: PersonneImplique, index: number): string => {
            return person.nom || `Personne ${index + 1}`
          },
        ),
      ]
    }

    return []
  }

  useEffect(() => {
    if (formikForm.values.irsiData?.personnesImpliquees !== undefined && !timelineLoaded.current) {
      const personnesImpliquees: PersonneImplique[] = structuredClone(formikForm.values.irsiData?.personnesImpliquees)

      personnesImpliquees.forEach((person) => {
        const newRefs: Reference[] = [ ...person.references ]
        if (!person.references.find((ref) => ref.id === '01')) {
          newRefs.push({ id: '01', libelle: '' })
        }
        if (!person.references.find((ref) => ref.id === '02')) {
          newRefs.push({ id: '02', libelle: '' })
        }
        person.references = newRefs
      })
      handleValue('personnesImpliquees', personnesImpliquees)
      timelineLoaded.current = true
    }

  }, [ formikForm.values.irsiData?.personnesImpliquees ])

  const getReferenceIndex = (personIndex: number, id: string): number => formikForm.values.irsiData.personnesImpliquees[personIndex].references.findIndex((reference) => reference.id === id)

  if (formikForm.values.irsiData === undefined) {
    return null
  }

  const typeExpertiseOptions: SegmentedButtonOption<string>[] = expertiseTypeList.map(({ code, libelle }) => ({ value: code, label: libelle }) )
  const buildingStatusOptions: SegmentedButtonOption<string>[] = buildingStatusList.map(({ code, libelle }) => ({ value: code, label: libelle }) )
  const propertyUsageOptions: SegmentedButtonOption<string>[] = propertyUsageList.map(({ code, libelle }) => ({ value: code, label: libelle }) )
  const deleteCauseOptions: SegmentedButtonOption<string>[] = deleteCauseList.map(({ code, libelle }) => ({ value: code, label: libelle }) )
  const disasterNatureOptions: SegmentedButtonOption<string>[] = disasterNatureList.map(({ code, libelle }) => ({ value: code, label: libelle }) )
  const roleOptions: SegmentedButtonOption<string>[] = personRoleList.map(({ code, libelle }) => ({ value: code, label: libelle }) )
  const costingAgreementOptions: SegmentedButtonOption<string>[] = costingAgreementList.map(({ code, libelle }) => ({ value: code, label: libelle }) )
  const tierOptions: SegmentedButtonOption<string>[] = formikForm.values.irsiData.personnesImpliquees.map(({ id, nom, prenom }) => ({ value: id, label: `${nom} ${prenom}` }) )
  const booleanOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]
  const paymentOptions: SegmentedButtonOption<string>[] = enumToSegmentedButtonOptions(RegimeFiscal)

  return (
    <FormContainer>
      {
        (
          isFetchingQualiteList ||
          isFetchingRiskAddressList ||
          isFetchingBuildingStatusList ||
          isFetchingPropertyUsageList ||
          isFetchingDeletCauseList ||
          isFetchingCostingAgreementList ||
          isFetchingPersonQualifiantList ||
          isFetchingPersonRoleList ||
          isFetchingInsuranceCompanyList ||
          isFetchingExpertiseTypeList ||
          isFetchingDisasterNatureList
        ) ?
          <Loader /> :
          <>
            <TravelerLargeTitle>
              Irsi
              <ButtonSaveGroup>
                <Button variant="outlined">
                  Mise à jour du chiffrage
                </Button>
                <Button variant="outlined">
                  Mise à jour globale
                </Button>
                <Button variant="outlined">
                  Envoi vers Darva
                </Button>
                <Button
                  variant="contained"
                  onClick={handleIrsiSubmit}
                  disabled={isSaving}
                >
                  {
                    isSaving ?
                      <CircularProgress size={26} /> :
                      'Enregistrement'
                  }
                </Button>
              </ButtonSaveGroup>
            </TravelerLargeTitle>
            <FormBoldTitle bigger>
              Identification
            </FormBoldTitle>
            <CardContainer>
              <CardContentContainer>
                <FourGridNoMarginContainer>
                  <SmallBoldTitle>
                    N° de police
                  </SmallBoldTitle>
                  <SmallBoldTitle required>
                    Réf du sinistre
                  </SmallBoldTitle>
                  <SmallBoldTitle required>
                    N° Ordre de mission
                  </SmallBoldTitle>
                  <SmallBoldTitle>
                    Date de missionnement
                  </SmallBoldTitle>
                </FourGridNoMarginContainer>
                <GridContainer>
                  <Field
                    component={TextField}
                    name="irsiData.identification.numeroDePolice"
                    placeholder="N° de Police"
                    disabled={formikForm.values.disabled}
                  />
                  <Field
                    component={TextField}
                    name="irsiData.identification.refSinistre"
                    placeholder="Réf du sinistre"
                    disabled={formikForm.values.disabled}
                  />
                  <Field
                    component={TextField}
                    name="irsiData.identification.noMission"
                    placeholder="N° Ordre de mission"
                    disabled={formikForm.values.disabled}
                  />
                  <FormikDatePicker
                    name="irsiData.identification.dateMission"
                    disabled={formikForm.values.disabled}
                  />
                </GridContainer>
                <OneFiveGridContainer>
                  Assureur
                  <BoldText>
                    {formikForm.values.irsiData.identification.assureur}
                  </BoldText>
                  Agence
                  <BoldText>
                    {formikForm.values.irsiData.identification.agence1}
                  </BoldText>
                </OneFiveGridContainer>
              </CardContentContainer>
            </CardContainer>
            <FormBoldTitle bigger>
              Expert
            </FormBoldTitle>
            <CardContainer>
              <CardContentContainer>
                <TwoOneOneGridNoMarginContainer>
                  <SmallBoldTitle required>
                    Nom
                  </SmallBoldTitle>
                  <SmallBoldTitle required>
                    Référence
                  </SmallBoldTitle>
                  <SmallBoldTitle required>
                    Date de l'expertise
                  </SmallBoldTitle>
                </TwoOneOneGridNoMarginContainer>
                <TwoOneOneGridContainer>
                  <Field
                    component={TextField}
                    name="irsiData.expert.nom"
                    placeholder="Nom"
                    disabled={formikForm.values.disabled}
                  />
                  <Field
                    component={TextField}
                    name="irsiData.expert.reference"
                    placeholder="Référence"
                    disabled={formikForm.values.disabled}
                  />
                  <FormikDatePicker
                    name="irsiData.expert.dateExpertise"
                    disabled={formikForm.values.disabled}
                  />
                </TwoOneOneGridContainer>
                <SmallBoldTitle required>
                  Type d'expertise
                </SmallBoldTitle>
                <SegmentedButtons
                  options={typeExpertiseOptions}
                  selectedOption={formikForm.values.irsiData.expert.typeExpertise?.code || ''}
                  setSelectedOption={(newVal) => handleValue(`expert.typeExpertise.code`, newVal)}
                  disabled={formikForm.values.disabled}
                />
                <TwoGridNoMarginContainer>
                  <SmallBoldTitle required>
                    Mail
                  </SmallBoldTitle>
                  <SmallBoldTitle required>
                    Téléphone
                  </SmallBoldTitle>
                </TwoGridNoMarginContainer>
                <TwoGridContainer>
                  <Field
                    component={TextField}
                    name="irsiData.expert.email"
                    placeholder="Email"
                    disabled={formikForm.values.disabled}
                  />
                  <Field
                    component={TextField}
                    name="irsiData.expert.telephone"
                    placeholder="Téléphone"
                    disabled={formikForm.values.disabled}
                  />
                </TwoGridContainer>
              </CardContentContainer>
            </CardContainer>
            <FormBoldTitle bigger>
              Assuré
            </FormBoldTitle>
            <CardContainer>
              <CardContentContainer>
                <TwoGridNoMarginContainer>
                  <SmallBoldTitle required>
                    Nom
                  </SmallBoldTitle>
                  <SmallBoldTitle>
                    Prénom
                  </SmallBoldTitle>
                </TwoGridNoMarginContainer>
                <TwoGridContainer>
                  <Field
                    component={TextField}
                    name={`irsiData.personnesImpliquees[${insuredIndex}].nom`}
                    placeholder="Nom"
                    disabled={formikForm.values.disabled}
                  />
                  <Field
                    component={TextField}
                    name={`irsiData.personnesImpliquees[${insuredIndex}].prenom`}
                    placeholder="Prénom"
                    disabled={formikForm.values.disabled}
                  />
                </TwoGridContainer>
                <SmallBoldTitle>
                  Adresse
                </SmallBoldTitle>
                <Field
                  component={TextField}
                  name={`irsiData.personnesImpliquees[${insuredIndex}].adresse.adresse1`}
                  placeholder="Adresse"
                  disabled={formikForm.values.disabled}
                />
                <br />
                <br />
                <Field
                  component={TextField}
                  name={`irsiData.personnesImpliquees[${insuredIndex}].adresse.adresse2`}
                  placeholder="Adresse"
                  disabled={formikForm.values.disabled}
                />
                <br />
                <br />
                <Field
                  component={TextField}
                  name={`irsiData.personnesImpliquees[${insuredIndex}].adresse.adresse3`}
                  placeholder="Adresse"
                  disabled={formikForm.values.disabled}
                />
                <TwoGridNoMarginContainer>
                  <SmallBoldTitle>
                    Code postal
                  </SmallBoldTitle>
                  <SmallBoldTitle>
                    Ville
                  </SmallBoldTitle>
                </TwoGridNoMarginContainer>
                <TwoGridContainer>
                  <Field
                    component={TextField}
                    name={`irsiData.personnesImpliquees[${insuredIndex}].adresse.codePostal`}
                    placeholder="Adresse"
                    disabled={formikForm.values.disabled}
                  />
                  <Field
                    component={TextField}
                    name={`irsiData.personnesImpliquees[${insuredIndex}].adresse.ville`}
                    placeholder="Adresse"
                    disabled={formikForm.values.disabled}
                  />
                </TwoGridContainer>
                <SmallBoldTitle required>
                  Qualité de l'assuré
                </SmallBoldTitle>
                <Field
                  name={`irsiData.personnesImpliquees[${insuredIndex}].qualite.code`}
                  component={Select}
                  displayEmpty
                  renderValue={verifySelectFieldValue(formikForm.values.irsiData.personnesImpliquees[insuredIndex]?.qualite?.code)}
                  value={formikForm.values.irsiData.personnesImpliquees[insuredIndex]?.qualite?.code || ''}
                  disabled={formikForm.values.disabled}
                >
                  {
                    qualiteList.map((value, index) => (
                      <MenuItem
                        value={value.code}
                        key={`${value.code}-${index}`}
                      >
                        {value.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
                <SmallBoldTitle>
                  Qualifiant
                </SmallBoldTitle>
                <ThreeGridContainer>
                  {
                    personQualifiantList.map((value, index) => (
                      <FlexCheckableButton
                        key={`${value.code}-${index}`}
                        checked={formikForm.values.irsiData.personnesImpliquees[insuredIndex]?.qualifiants?.some((qualifiant) => qualifiant.code === value.code) || false}
                        onChange={(e, c): void => handleImplicatedPersonCheckedButton(insuredIndex, value, c)}
                        label={value.libelle}
                        disabled={formikForm.values.disabled}
                      />
                    ))
                  }
                </ThreeGridContainer>
              </CardContentContainer>
            </CardContainer>
            <FormBoldTitle bigger>
              Risque
            </FormBoldTitle>
            <CardContainer>
              <CardContentContainer>
                <SmallBoldTitle>
                  Adresse
                </SmallBoldTitle>
                <Field
                  name="irsiData.risque.adresse.adresse1"
                  component={Select}
                  displayEmpty
                  renderValue={verifySelectFieldValue(formikForm.values.irsiData.risque.adresse?.adresse1)}
                  value={formikForm.values.irsiData.risque.adresse?.adresse1 || ''}
                  disabled={formikForm.values.disabled}
                >
                  {
                    riskAddressList.map((value, index) => (
                      <MenuItem
                        value={value.adresse1 || ''}
                        key={`${value.adresse1}-${index}`}
                      >
                        {value.adresse1}
                      </MenuItem>
                    ))
                  }
                </Field>
                <br />
                <br />
                <Field
                  component={TextField}
                  name="irsiData.risque.adresse.adresse1"
                  placeholder="Adresse"
                  disabled={formikForm.values.disabled}
                />
                <br />
                <br />
                <Field
                  component={TextField}
                  name="irsiData.risque.adresse.adresse2"
                  placeholder="Adresse"
                  disabled={formikForm.values.disabled}
                />
                <br />
                <br />
                <Field
                  component={TextField}
                  name="irsiData.risque.adresse.adresse3"
                  placeholder="Adresse"
                  disabled={formikForm.values.disabled}
                />
                <TwoGridNoMarginContainer>
                  <SmallBoldTitle>
                    Code postal
                  </SmallBoldTitle>
                  <SmallBoldTitle>
                    Ville
                  </SmallBoldTitle>
                </TwoGridNoMarginContainer>
                <TwoGridContainer>
                  <Field
                    component={TextField}
                    name="irsiData.risque.adresse.codePostal"
                    placeholder="Adresse"
                    disabled={formikForm.values.disabled}
                  />
                  <Field
                    component={TextField}
                    name="irsiData.risque.adresse.ville"
                    placeholder="Adresse"
                    disabled={formikForm.values.disabled}
                  />
                </TwoGridContainer>
                <SmallBoldTitle required>
                  Statut de l'immeuble
                </SmallBoldTitle>
                <SegmentedButtons
                  options={buildingStatusOptions}
                  selectedOption={formikForm.values.irsiData.risque.statutImmeuble.code || ''}
                  setSelectedOption={(newVal) => handleValue(`risque.statutImmeuble.code`, newVal)}
                  disabled={formikForm.values.disabled}
                />
                <SmallBoldTitle required>
                  Usage du bien par l'assuré
                </SmallBoldTitle>
                <SegmentedButtons
                  options={propertyUsageOptions}
                  selectedOption={formikForm.values.irsiData.risque.usageAssure.code || ''}
                  setSelectedOption={(newVal) => handleValue(`risque.usageAssure.code`, newVal)}
                  disabled={formikForm.values.disabled}
                />
              </CardContentContainer>
            </CardContainer>
            <FormBoldTitle bigger>
              Causes et circonstances du sinistre
            </FormBoldTitle>
            <CardContainer>
              <CardContentContainer>
                <OneOneTwoGridNoMarginContainer>
                  <SmallBoldTitle required>
                    Date
                  </SmallBoldTitle>
                  <SmallBoldTitle>
                    Heure
                  </SmallBoldTitle>
                  <SmallBoldTitle required>
                    Nature du sinistre
                  </SmallBoldTitle>
                </OneOneTwoGridNoMarginContainer>
                <OneOneTwoGridContainer>
                  <FormikDatePicker
                    name="irsiData.circonstance.dateCirconstance"
                    disabled={formikForm.values.disabled}
                  />
                  <FormikDatePicker
                    time
                    name="irsiData.circonstance.dateCirconstance"
                    disabled={formikForm.values.disabled}
                  />
                  <SegmentedButtons
                    options={disasterNatureOptions}
                    selectedOption={formikForm.values.irsiData.circonstance.natureSinistre?.code || ''}
                    setSelectedOption={(newVal) => handleValue(`circonstance.natureSinistre.code`, newVal)}
                    disabled={formikForm.values.disabled}
                  />
                </OneOneTwoGridContainer>
                <TwoGridNoMarginContainer>
                  <SmallBoldTitle>
                    Sinistre répétitif
                  </SmallBoldTitle>
                  <SmallBoldTitle>
                    Recherche de fuite
                  </SmallBoldTitle>
                </TwoGridNoMarginContainer>
                <TwoGridContainer>
                  <SegmentedButtons
                    options={booleanOptions}
                    selectedOption={formikForm.values.irsiData.circonstance.sinistreRepetitif}
                    setSelectedOption={(newVal) => handleValue(`circonstance.sinistreRepetitif`, newVal)}
                    disabled={formikForm.values.disabled}
                  />
                  <SegmentedButtons
                    options={booleanOptions}
                    selectedOption={formikForm.values.irsiData.circonstance.rechercheDeFuite}
                    setSelectedOption={(newVal) => handleValue(`circonstance.rechercheDeFuite`, newVal)}
                    disabled={formikForm.values.disabled}
                  />
                </TwoGridContainer>
                <SmallBoldTitle>
                  Commentaire causes et circonstances
                </SmallBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Votre message"
                  name="irsiData.circonstance.commentaire"
                  rows={3}
                  multiline
                  disabled={formikForm.values.disabled}
                />
                <SmallBoldTitle>
                  Causes multiples
                </SmallBoldTitle>
                <TwoGridContainer>
                  <SegmentedButtons
                    options={booleanOptions}
                    selectedOption={formikForm.values.irsiData.circonstance.causesMultiples}
                    setSelectedOption={(newVal) => handleValue(`circonstance.causesMultiples`, newVal)}
                    disabled={formikForm.values.disabled}
                  />
                </TwoGridContainer>
                <AddCauseContainer>
                  Causes
                  <Button
                    variant="contained"
                    onClick={addCause}
                    disabled={formikForm.values.disabled}
                  >
                    Ajouter une cause
                  </Button>
                </AddCauseContainer>
                {
                  formikForm.values.irsiData.causes.map((cause, causeIndex) => (
                    <CauseCardContainer key={`${JSON.stringify(cause)}-${causeIndex}`}>
                      <CardContentContainer>
                        <FormBoldTitle>
                          Cause n°
                          {causeIndex + 1}
                        </FormBoldTitle>
                        <SmallBoldTitle required>
                          Cause du sinistre
                        </SmallBoldTitle>
                        <Field
                          name={`irsiData.causes[${causeIndex}].causeSinistre.code`}
                          component={Select}
                          displayEmpty
                          renderValue={verifySelectFieldValue(formikForm.values.irsiData.causes[causeIndex].causeSinistre?.code)}
                          value={formikForm.values.irsiData.causes[causeIndex].causeSinistre?.code || ''}
                          disabled={formikForm.values.disabled}
                        >
                          {
                            disasterNatureList.find((nature) => nature.code === formikForm.values.irsiData.circonstance.natureSinistre?.code)?.causeSinistres
                              ?.map((value, index) => (
                                <MenuItem
                                  value={value.code}
                                  key={`${value.code}-${index}`}
                                >
                                  {value.libelle}
                                </MenuItem>
                              ))
                          }
                        </Field>
                        <SmallBoldTitle required>
                          Cause supprimée
                        </SmallBoldTitle>
                        <SegmentedButtons
                          options={deleteCauseOptions}
                          selectedOption={formikForm.values.irsiData.causes[causeIndex].causeSupprimee?.code}
                          setSelectedOption={(newVal) => handleValue(`causes[${causeIndex}].causeSupprimee.code`, newVal)}
                          disabled={formikForm.values.disabled}
                        />
                        <SmallBoldTitle required>
                          Cas barême
                        </SmallBoldTitle>
                        <Field
                          name={`irsiData.causes[${causeIndex}].casBareme.code`}
                          component={Select}
                          displayEmpty
                          renderValue={verifySelectFieldValue(formikForm.values.irsiData.causes[causeIndex].casBareme?.code)}
                          value={formikForm.values.irsiData.causes[causeIndex].casBareme?.code || ''}
                          disabled={formikForm.values.disabled}
                        >
                          {
                            disasterNatureList.find((nature) => nature.code === formikForm.values.irsiData.circonstance.natureSinistre?.code)?.casBaremes
                              ?.map((value, index) => (
                                <MenuItem
                                  value={value.code}
                                  key={`${value.code}-${index}`}
                                >
                                  {value.libelle}
                                </MenuItem>
                              ))
                          }
                        </Field>
                        <SmallBoldTitle>
                          Cause barême
                        </SmallBoldTitle>
                        <Field
                          name={`irsiData.causes[${causeIndex}].causeBareme.code`}
                          component={Select}
                          displayEmpty
                          renderValue={verifySelectFieldValue(formikForm.values.irsiData.causes[causeIndex].causeBareme?.code)}
                          value={formikForm.values.irsiData.causes[causeIndex].causeBareme?.code || ''}
                          disabled={formikForm.values.disabled}
                        >
                          {
                            disasterNatureList.find((nature) => nature.code === formikForm.values.irsiData.circonstance.natureSinistre?.code)?.casBaremes
                              ?.find((casBareme) => casBareme.code === formikForm.values.irsiData.causes[causeIndex].casBareme?.code)?.causesBareme
                              ?.map((value, index) => (
                                <MenuItem
                                  value={value.code}
                                  key={`${value.code}-${index}`}
                                >
                                  {value.libelle}
                                </MenuItem>
                              ))
                          }
                        </Field>
                      </CardContentContainer>
                    </CauseCardContainer>
                  ))
                }
              </CardContentContainer>
            </CardContainer>
            <FormBoldTitle bigger>
              Personnes impliquées
            </FormBoldTitle>
            <CardContainer>
              <CardContentTabsContainer>
                <Tabs
                  value={personTabValue}
                  onChange={handlePersonTabChange}
                  tabs={getTabs()}
                />
                {
                  formikForm.values.irsiData.personnesImpliquees?.map((person, personIndex) => (
                    <TabPanel
                      value={personTabValue}
                      index={personIndex}
                      key={`${person.id}-${personIndex}`}
                    >
                      <CardContentContainer>
                        <TwoGridNoMarginContainer>
                          <SmallBoldTitle required>
                            Nom
                          </SmallBoldTitle>
                          <SmallBoldTitle>
                            Prénom
                          </SmallBoldTitle>
                        </TwoGridNoMarginContainer>
                        <TwoGridContainer>
                          <Field
                            component={TextField}
                            name={`irsiData.personnesImpliquees[${personIndex}].nom`}
                            placeholder="Nom"
                            disabled={formikForm.values.disabled}
                          />
                          <Field
                            component={TextField}
                            name={`irsiData.personnesImpliquees[${personIndex}].prenom`}
                            placeholder="Prénom"
                            disabled={formikForm.values.disabled}
                          />
                        </TwoGridContainer>
                        <SmallBoldTitle>
                          Adresse
                        </SmallBoldTitle>
                        <Field
                          component={TextField}
                          name={`irsiData.personnesImpliquees[${personIndex}].adresse.adresse1`}
                          placeholder="Adresse"
                          disabled={formikForm.values.disabled}
                        />
                        <br />
                        <br />
                        <Field
                          component={TextField}
                          name={`irsiData.personnesImpliquees[${personIndex}].adresse.adresse2`}
                          placeholder="Adresse"
                          disabled={formikForm.values.disabled}
                        />
                        <br />
                        <br />
                        <Field
                          component={TextField}
                          name={`irsiData.personnesImpliquees[${personIndex}].adresse.adresse3`}
                          placeholder="Adresse"
                          disabled={formikForm.values.disabled}
                        />
                        <TwoGridNoMarginContainer>
                          <SmallBoldTitle>
                            Code postal
                          </SmallBoldTitle>
                          <SmallBoldTitle>
                            Ville
                          </SmallBoldTitle>
                        </TwoGridNoMarginContainer>
                        <TwoGridContainer>
                          <Field
                            component={TextField}
                            name={`irsiData.personnesImpliquees[${personIndex}].adresse.codePostal`}
                            placeholder="Adresse"
                            disabled={formikForm.values.disabled}
                          />
                          <Field
                            component={TextField}
                            name={`irsiData.personnesImpliquees[${personIndex}].adresse.ville`}
                            placeholder="Adresse"
                            disabled={formikForm.values.disabled}
                          />
                        </TwoGridContainer>
                        <SmallBoldTitle required>
                          Qualité
                        </SmallBoldTitle>
                        <Field
                          name={`irsiData.personnesImpliquees[${personIndex}].qualite.code`}
                          component={Select}
                          displayEmpty
                          renderValue={verifySelectFieldValue(person.qualite?.code)}
                          value={person.qualite?.code || ''}
                          disabled={formikForm.values.disabled}
                        >
                          {
                            qualiteList.map((value, index) => (
                              <MenuItem
                                value={value.code}
                                key={`${value.code}-${index}`}
                              >
                                {value.libelle}
                              </MenuItem>
                            ))
                          }
                        </Field>
                        <SmallBoldTitle>
                          Rôle
                        </SmallBoldTitle>
                        <SegmentedButtons
                          options={roleOptions}
                          selectedOption={person.role?.code}
                          setSelectedOption={(newVal) => handleValue(`personnesImpliquees[${personIndex}].role.code`, newVal)}
                          disabled={formikForm.values.disabled}
                        />
                        <SmallBoldTitle>
                          Qualifiant
                        </SmallBoldTitle>
                        <ThreeGridContainer>
                          {
                            personQualifiantList.map((value, index) => (
                              <FlexCheckableButton
                                key={`${value.code}-${index}`}
                                checked={person.qualifiants?.some((qualifiant) => qualifiant.code === value.code) || false}
                                onChange={(e, c): void => handleImplicatedPersonCheckedButton(personIndex, value, c)}
                                label={value.libelle}
                                disabled={formikForm.values.disabled}
                              />
                            ))
                          }
                        </ThreeGridContainer>
                        <TwoGridNoMarginContainer>
                          <SmallBoldTitle>
                            Numéro de contrat police
                          </SmallBoldTitle>
                          <SmallBoldTitle>
                            Numéro de dossier
                          </SmallBoldTitle>
                        </TwoGridNoMarginContainer>
                        <TwoGridContainer>
                          <Field
                            component={TextField}
                            name={`irsiData.personnesImpliquees[${personIndex}].references[${getReferenceIndex(personIndex, '02')}].libelle`}
                            placeholder="Numéro de contrat police"
                            disabled={formikForm.values.disabled}
                          />
                          <Field
                            component={TextField}
                            name={`irsiData.personnesImpliquees[${personIndex}].references[${getReferenceIndex(personIndex, '01')}].libelle`}
                            placeholder="Numéro de dossier"
                            disabled={formikForm.values.disabled}
                          />
                        </TwoGridContainer>
                        <TwoGridNoMarginContainer>
                          <SmallBoldTitle required>
                            Personne présente
                          </SmallBoldTitle>
                          <SmallBoldTitle>
                            LRAR
                          </SmallBoldTitle>
                        </TwoGridNoMarginContainer>
                        <TwoGridContainer>
                          <SegmentedButtons
                            options={booleanOptions}
                            selectedOption={person.presente}
                            setSelectedOption={(newVal) => handleValue(`personnesImpliquees[${personIndex}].presente`, newVal)}
                            disabled={formikForm.values.disabled}
                          />
                          <SegmentedButtons
                            options={booleanOptions}
                            selectedOption={person.lrar}
                            setSelectedOption={(newVal) => handleValue(`personnesImpliquees[${personIndex}].lrar`, newVal)}
                            disabled={formikForm.values.disabled}
                          />
                        </TwoGridContainer>
                        <SmallBoldTitle>
                          Nom de l'assureur
                        </SmallBoldTitle>
                        <Field
                          name={`irsiData.personnesImpliquees[${personIndex}].nomAssurance.code`}
                          component={Select}
                          displayEmpty
                          renderValue={verifySelectFieldValue(person.nomAssurance?.code)}
                          value={person.nomAssurance?.code || ''}
                          disabled={formikForm.values.disabled}
                        >
                          {
                            insuranceCompanyList.map((value, index) => (
                              <MenuItem
                                value={value.code}
                                key={`${value.code}-${index}`}
                              >
                                {value.libelle}
                              </MenuItem>
                            ))
                          }
                        </Field>
                      </CardContentContainer>
                    </TabPanel>
                  ))
                }
              </CardContentTabsContainer>
            </CardContainer>
            <FormBoldTitle bigger>
              Chiffrage
            </FormBoldTitle>
            <CardContainer>
              <CardContentTabsContainer>
                <Tabs
                  value={costingTabValue}
                  onChange={handleCostingTabChange}
                  tabs={getTabs()}
                />
                {
                  formikForm.values.irsiData.personnesImpliquees?.map((person, personIndex) => (
                    <TabPanel
                      value={costingTabValue}
                      index={personIndex}
                      key={`${person.id}-${personIndex}`}
                    >
                      <CardContentContainer>
                        <SmallBoldTitle>
                          Bénéficiaire
                        </SmallBoldTitle>
                        <SegmentedButtons
                          options={tierOptions}
                          selectedOption={person.chiffrage.beneficiaire || ''}
                          setSelectedOption={(newVal) => handleValue(`personnesImpliquees[${personIndex}].chiffrage.beneficiaire`, newVal)}
                          disabled={formikForm.values.disabled}
                        />
                        <SmallBoldTitle>
                          Accord sur le chiffrage des dommages
                        </SmallBoldTitle>
                        <SegmentedButtons
                          options={costingAgreementOptions}
                          selectedOption={person.chiffrage.accord?.code}
                          setSelectedOption={(newVal) => handleValue(`personnesImpliquees[${personIndex}].chiffrage.accord.code`, newVal)}
                          disabled={formikForm.values.disabled}
                        />
                        <SmallBoldTitle>
                          Régime fiscal de réglement
                        </SmallBoldTitle>
                        <TwoGridContainer>
                          <SegmentedButtons
                            options={paymentOptions}
                            selectedOption={person.chiffrage.regimeFiscal}
                            setSelectedOption={(newVal) => handleValue(`personnesImpliquees[${personIndex}].chiffrage.regimeFiscal`, newVal)}
                            disabled={formikForm.values.disabled}
                          />
                        </TwoGridContainer>
                        <br />
                        <CardContainer>
                          <CardContentNoPaddingContainer>
                            <CenteredText>
                              <BoldUppercaseTitle>
                                Détail de préjudices matériels
                              </BoldUppercaseTitle>
                            </CenteredText>
                            <LightSeparator />
                            <ThreeGridNoMarginContainer>
                              <BoldUppercaseTitle>
                                Préjudices
                              </BoldUppercaseTitle>
                              <BoldUppercaseTitle>
                                Montant préjudice HT/TTC (€)
                              </BoldUppercaseTitle>
                              <BoldUppercaseTitle>
                                Montant vétusté déduite HT/TTC (€)
                              </BoldUppercaseTitle>
                            </ThreeGridNoMarginContainer>
                            <BoldSeparator />
                            {
                              person.chiffrage.prejudicesMateriels?.map((prejudice, prejudiceIndex) => (
                                <div key={`${prejudice.id}-${prejudiceIndex}`}>
                                  <ThreeGridNoMarginContainer>
                                    {prejudice.libelle}
                                    <PriceField
                                      name={`irsiData.personnesImpliquees[${personIndex}].chiffrage.prejudicesMateriels[${prejudiceIndex}].${person.chiffrage.regimeFiscal === RegimeFiscal.HT ? 'montantHT' : 'montantTTC'}`}
                                      size="small"
                                      disabled={formikForm.values.disabled}
                                      onChange={(e) => handleValue(`personnesImpliquees[${personIndex}].chiffrage.prejudicesMateriels[${prejudiceIndex}].${person.chiffrage.regimeFiscal === RegimeFiscal.HT ? 'montantHT' : 'montantTTC'}`, handleNumberVerification(e.target.value, 2))}
                                    />
                                    <PriceField
                                      name={`irsiData.personnesImpliquees[${personIndex}].chiffrage.prejudicesMateriels[${prejudiceIndex}].${person.chiffrage.regimeFiscal === RegimeFiscal.HT ? 'montantVetusteDetuiteHT' : 'montantVetusteDetuiteTTC'}`}
                                      size="small"
                                      disabled={formikForm.values.disabled}
                                      onChange={(e) => handleValue(`personnesImpliquees[${personIndex}].chiffrage.prejudicesMateriels[${prejudiceIndex}].${person.chiffrage.regimeFiscal === RegimeFiscal.HT ? 'montantVetusteDetuiteHT' : 'montantVetusteDetuiteTTC'}`, handleNumberVerification(e.target.value, 2))}
                                    />
                                  </ThreeGridNoMarginContainer>
                                  {
                                    prejudiceIndex !== (person.chiffrage.prejudicesMateriels?.length || 0) - 1 &&
                                      <LightSeparator />
                                  }
                                </div>
                              ))
                            }
                          </CardContentNoPaddingContainer>
                        </CardContainer>
                        <br />
                        <br />
                        <CardContainer>
                          <CardContentNoPaddingContainer>
                            <CenteredText>
                              <BoldUppercaseTitle>
                                Détail de préjudices immatériels
                              </BoldUppercaseTitle>
                            </CenteredText>
                            <LightSeparator />
                            <ThreeGridNoMarginContainer>
                              <BoldUppercaseTitle>
                                Nature des frais
                              </BoldUppercaseTitle>
                              <BoldUppercaseTitle>
                                Dommages HT/TTC (€)
                              </BoldUppercaseTitle>
                            </ThreeGridNoMarginContainer>
                            <BoldSeparator />
                            {
                              person.chiffrage.prejudicesImmateriels?.map((prejudice, prejudiceIndex) => (
                                <div key={`${prejudice.id}-${prejudiceIndex}`}>
                                  <ThreeGridNoMarginContainer>
                                    {prejudice.type.libelle}
                                    <PriceField
                                      name={`irsiData.personnesImpliquees[${personIndex}].chiffrage.prejudicesImmateriels[${prejudiceIndex}].montantTTC`}
                                      size="small"
                                      disabled={formikForm.values.disabled}
                                      onChange={(e) => handleValue(`personnesImpliquees[${personIndex}].chiffrage.prejudicesImmateriels[${prejudiceIndex}].montantTTC`, handleNumberVerification(e.target.value, 2))}
                                    />
                                  </ThreeGridNoMarginContainer>
                                  {
                                    prejudiceIndex !== (person.chiffrage.prejudicesImmateriels?.length || 0) - 1 &&
                                      <LightSeparator />
                                  }
                                </div>
                              ))
                            }
                          </CardContentNoPaddingContainer>
                        </CardContainer>
                      </CardContentContainer>
                    </TabPanel>
                  ))
                }
              </CardContentTabsContainer>
            </CardContainer>
            <FormBoldTitle bigger>
              Assiette
            </FormBoldTitle>
            <CardContainer>
              <CardContentNoTitleContainer>
                <ThreeGridNoMarginContainer>
                  Total Assiette du Local HT
                  <PriceField
                    name="irsiData.assiette"
                    size="small"
                    disabled={formikForm.values.disabled}
                    onChange={(e) => handleValue(`assiette`, handleNumberVerification(e.target.value, 2))}
                  />
                </ThreeGridNoMarginContainer>
              </CardContentNoTitleContainer>
            </CardContainer>
            <FormBoldTitle
              bigger
              required
            >
              Recours
            </FormBoldTitle>
            <CardContainer>
              <CardContentNoTitleContainer>
                <CardContainer>
                  <CardContentNoPaddingContainer>
                    <ThreeGridNoMarginContainer>
                      Total recours du PO à exercer
                      <PriceField
                        name="irsiData.recours.po"
                        size="small"
                        disabled={formikForm.values.disabled}
                        onChange={(e) => handleValue(`recours.po`, handleNumberVerification(e.target.value, 2))}
                      />
                    </ThreeGridNoMarginContainer>
                    <LightSeparator />
                    <ThreeGridNoMarginContainer>
                      Total recours de l’occupant à exercer
                      <PriceField
                        name="irsiData.recours.occupant"
                        size="small"
                        disabled={formikForm.values.disabled}
                        onChange={(e) => handleValue(`recours.occupant`, handleNumberVerification(e.target.value, 2))}
                      />
                    </ThreeGridNoMarginContainer>
                    <LightSeparator />
                    <ThreeGridNoMarginContainer>
                      Total recours du PNO/CNO à exercer
                      <PriceField
                        name="irsiData.recours.pnoCno"
                        size="small"
                        disabled={formikForm.values.disabled}
                        onChange={(e) => handleValue(`recours.pnoCno`, handleNumberVerification(e.target.value, 2))}
                      />
                    </ThreeGridNoMarginContainer>
                    <LightSeparator />
                    <ThreeGridNoMarginContainer>
                      Total recours de l’immeuble à exercer
                      <PriceField
                        name="irsiData.recours.immeuble"
                        size="small"
                        disabled={formikForm.values.disabled}
                        onChange={(e) => handleValue(`recours.immeuble`, handleNumberVerification(e.target.value, 2))}
                      />
                    </ThreeGridNoMarginContainer>
                  </CardContentNoPaddingContainer>
                </CardContainer>
                <RecourseButton variant="contained">
                  Je souhaite calculer mes totaux recours
                </RecourseButton>
              </CardContentNoTitleContainer>
            </CardContainer>
            <FormBoldTitle bigger>
              Commentaire
            </FormBoldTitle>
            <CardContainer>
              <CardContentContainer>
                <SmallBoldTitle>
                  Commentaire
                </SmallBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Votre message"
                  name="irsiData.commentaire"
                  rows={3}
                  multiline
                  disabled={formikForm.values.disabled}
                />
              </CardContentContainer>
            </CardContainer>
          </>
      }
    </FormContainer>
  )
}

export default IrsiPage
