/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import ColoredSquareChip from './ColoredSquareChip'

/* Component declaration ---------------------------------------------------- */
const ClickableColoredSquareChip = styled(ColoredSquareChip)`
  cursor: pointer;

  &:hover {
    filter: brightness(70%);
  }
`

export default ClickableColoredSquareChip
