/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component declaration ---------------------------------------------------- */
const PageContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: scroll;

  /* &::-webkit-scrollbar {
    display: none;
  } */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  background-color: #F6F8FD;

  padding: 0 max(calc(30vw - 300px), 1rem) !important;
  @media screen and (max-width: 768px) {
    padding: 0 0.5rem !important;
  }

  & > div,
  & > section,
  & > article {
    margin: .5rem;
  }
`

export default PageContainer
