/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import {
  usePostViewedInternalExchangeMutation,
  usePostNotViewedInternalExchangeMutation,
} from 'store/api'
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import CaseSidebarItemComponents from 'layouts/CaseSidebar/CaseSidebarComponents/CaseSidebarItemComponents/CaseSidebarItemComponents'
import ClickableColoredSquareChip from 'components/ColoredSquareChip/ClickableColoredSquareChip'
import InternalExchangeAnswerForward from 'components/InternalExchange/InternalExchangeAnswerForward'

/* Type imports ------------------------------------------------------------- */
import type {
  EchangeInterne,
  EchangeInterneDestinataire,
  EchangeInterneEmetteur,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const Line = styled.h5`
  margin: 0px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarInternalExchangeItemProps {
  exchange: EchangeInterne;
}

const CaseSidebarInternalExchangeItem: React.FC<CaseSidebarInternalExchangeItemProps> = ({ exchange }) => {
  const authInfo = useAuthInfo()

  const [
    submitViewed,
    { isLoading: isSubmittingViewed },
  ] = usePostViewedInternalExchangeMutation()

  const [
    submitNotViewed,
    { isLoading: isSubmittingNotViewed },
  ] = usePostNotViewedInternalExchangeMutation()

  const isMe = (person: EchangeInterneDestinataire | EchangeInterneEmetteur) => person.id === authInfo?.currentCollaborateur?.refAnnuaire.refComplete ? 'Vous' : person.nom

  const handleChipClick = (): void => {
    if (isMe(exchange.emetteur) === 'Vous' && isMe(exchange.destinataire) !== 'Vous') return
    if (exchange.lu) {
      submitNotViewed({ id: exchange.id }).catch(console.error)
    } else {
      submitViewed({ id: exchange.id }).catch(console.error)
    }
  }

  return (
    <CaseSidebarItemComponents.Card>
      <CaseSidebarItemComponents.Title>
        <CaseSidebarItemComponents.PersonContainer>
          {isMe(exchange.emetteur)}
          {
            exchange.destinataire?.nom &&
              <React.Fragment>
                <div>
                  à
                </div>
                {isMe(exchange.destinataire)}
              </React.Fragment>
          }
        </CaseSidebarItemComponents.PersonContainer>
        <CaseSidebarItemComponents.DateContainer>
          {DateUtils.APIStrToLocalDateString(exchange.date, { hour: '2-digit', minute: '2-digit' })}
          {
            isSubmittingNotViewed || isSubmittingViewed ?
              <CircularProgress /> :
              <ClickableColoredSquareChip
                color={exchange.lu ? 'green' : 'red'}
                onClick={handleChipClick}
              >
                {exchange.lu ? 'Lu' : 'Nouveau'}
              </ClickableColoredSquareChip>
          }
        </CaseSidebarItemComponents.DateContainer>
      </CaseSidebarItemComponents.Title>
      <Line>
        {exchange.objet}
      </Line>
      <CaseSidebarItemComponents.Text>
        {exchange.message}
      </CaseSidebarItemComponents.Text>
      <InternalExchangeAnswerForward
        exchange={exchange}
        sidebarVersion
      />
    </CaseSidebarItemComponents.Card>
  )
}

export default CaseSidebarInternalExchangeItem
