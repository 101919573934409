/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import StakeholdersRdvCard from './StakeholdersRdvCard/StakeholdersRdvCard'

/* Type imports ------------------------------------------------------------- */
import type { TravelerForm } from 'types/TravelerForm'
import type {
  ActeurTraveller,
  RecoursTraveller,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const AddStakeholdersButton = styled(Button)`
  margin-left: auto;
  width: 220px;
`

const FormContainer = styled.div`
  padding-bottom: 40px;
`

interface StakeholdersPageProps {}

/* Component declaration ---------------------------------------------------- */
const StakeholdersPage: React.FC<StakeholdersPageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const navigate = useNavigate()
  const { caseId } = useParams<{ caseId: string }>()

  const addStakeholders = (): void => {
    navigate(`/dossiers/${caseId}/traveller/nouveau/intervenant`)
  }

  const handleValue = (type: string, value?: string | boolean | RecoursTraveller[] | ActeurTraveller): void => {
    formikForm.setFieldValue(type, value)
  }

  return (
    <FormContainer>
      <TravelerLargeTitle>
        Intervenants
        <AddStakeholdersButton
          variant="contained"
          onClick={addStakeholders}
          disabled={formikForm.values.disabled}
        >
          Ajouter un intervenant
        </AddStakeholdersButton>
      </TravelerLargeTitle>
      {
        formikForm.values.rendezVous?.map((value, index) => (
          <StakeholdersRdvCard
            index={index}
            rdv={value}
            acteurs={formikForm.values.acteurs}
            key={`${value.dateRDV}-${index}`}
            handleFormValue={(type: string, value: string | boolean): void => handleValue(`rendezVous[${index}].${type}`, value)}
            handleDelete={(type: string, value) => handleValue(type, value)}
            disabled={formikForm.values.disabled}
          />
        ))
      }
    </FormContainer>
  )
}

export default StakeholdersPage
