/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useOutletContext } from 'react-router-dom'
import {
  useGetRiskNotVerifiedReasonListQuery,
  useGetRiskTypeListQuery,
  useGetRiskUsageListQuery,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  MenuItem,
} from '@mui/material'
import { Edit } from '@mui/icons-material'
import { Field } from 'formik'
import {
  CheckboxWithLabel,
  Select,
  TextField,
} from 'formik-mui'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import RiskComplianceContractInfo from './RiskComplianceContractInfo/RiskComplianceContractInfo'
import RiskComplianceCorrectInfoModal from './RiskComplianceCorrectInfoModal/RiskComplianceCorrectInfoModal'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { TravelerForm } from 'types/TravelerForm'
import type {
  RiskComplianceModalType,
  RiskComplianceValueType,
} from 'types/RiskCompliance'
import type {
  Adresse,
  CodeLabel,
  Verification,
} from 'API/__generated__/Api'
import {
  TypePersonne,
  TypeRexSinistre,
  Verif,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type ModalInfo = {
  label: string;
  fieldName: string;
  modalType: RiskComplianceModalType;
  value?: RiskComplianceValueType;
  verificationFieldName: keyof Verification;
  verificationPreviousValue: Verif;
}

/* Styled components -------------------------------------------------------- */
const ContentContainer = styled(CardContent)`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1.25fr .75fr 1.75fr;
  gap: 5px;
  justify-content: stretch;
  align-items: flex-start;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  align-items: center;
`

const VerifContentContainer = styled(ContentContainer)`
  grid-template-columns: repeat(3, 1fr) 1.5fr;
`

const VerifTitleContainer = styled(VerifContentContainer)`
  margin: 10px 0px 5px;
  font-size: 0.9rem;
`

const CardContainer = styled(Card)`
  margin-bottom: 10px;
  font-size: 13px;
`

const CardContentContainer = styled(CardContent)`
  margin-top: -35px;
`

interface BoldTextProps {
  grey?: boolean;
}

const BoldText = styled.div<BoldTextProps>`
  font-weight: bold;
  color: ${(props) => props.grey ? '#AAAAAA' : '' };
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const EndTextField = styled(TextField)`
  margin-bottom: 40px;
`

const MarginBottomTextField = styled(TextField)`
  margin-bottom: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface RiskCompliancePageProps {}

const RiskCompliancePage: React.FC<RiskCompliancePageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const [ modalInfo, setModalInfo ] = useState<ModalInfo>()

  const {
    currentData: riskTypeList = [],
    isFetching: isFetchingRiskTypeList,
  } = useGetRiskTypeListQuery()
  const {
    currentData: riskUsageList = [],
    isFetching: isFetchingRiskUsageList,
  } = useGetRiskUsageListQuery()
  const {
    currentData: riskNotVerifiedReasonList = [],
    isFetching: isFetchingRiskNotVerifiedReasonList,
  } = useGetRiskNotVerifiedReasonListQuery()

  const handleValue = (type: string, value?: string | boolean | RiskComplianceValueType): void => {
    formikForm.setFieldValue(`conformiteDuRisque.${type}`, value)
  }

  const getDeclaredValue = (value: keyof Verification) => formikForm.values.conformiteDuRisque.verification?.[value]?.valeurDeclaree
  const getActualValue = (value: keyof Verification) => formikForm.values.conformiteDuRisque.verification?.[value]?.valeurRelevee
  const getValueVerification = (value: keyof Verification) => formikForm.values.conformiteDuRisque.verification?.[value]?.valeurVerifiee
  const verifyValue = (value: keyof Verification) => JSON.stringify(getDeclaredValue(value)) === JSON.stringify(getActualValue(value))

  const handleValueVerif = (
    fieldName: keyof Verification,
    value: string,
    label: string,
    modalValue: RiskComplianceValueType,
    modalType: RiskComplianceModalType = 'verification',
  ): void => {
    handleValue(`verification.${fieldName}.valeurVerifiee`, value)
    if (value === 'Non') {
      setModalInfo({
        label,
        value: modalValue,
        fieldName: `verification.${fieldName}.valeurRelevee`,
        modalType,
        verificationPreviousValue: getValueVerification(fieldName) || Verif.NonVerifie,
        verificationFieldName: fieldName,
      })
    } else {
      handleValue(`verification.${fieldName}.valeurRelevee`, undefined)
    }
  }

  const handleClose = (value?: RiskComplianceValueType): void => {
    if (!modalInfo) return
    if (value !== undefined) {
      handleValue(modalInfo.fieldName, value)
    }
    else {
      handleValue(modalInfo.verificationFieldName, modalInfo.verificationPreviousValue)
    }
    setModalInfo(undefined)
  }

  const verifyOptions: SegmentedButtonOption<string>[] = [ { value: 'Oui' }, { value: 'Non' }, { value: 'NonVerifie', label: 'Non vérifié' } ]
  const isBcOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]

  if (formikForm.values.conformiteDuRisque === undefined) {
    return null
  }

  const verificationList: {fieldName: keyof Verification; label: string}[] = [
    { fieldName: 'surface', label: 'Surface' },
    { fieldName: 'surfaceAnnexe', label: 'Surface annexe' },
    { fieldName: 'nombreDePiece', label: 'Nombre de pièces' },
    { fieldName: 'nombreDEtage', label: "Nombre d'étages" },
    { fieldName: 'nombreDeDependance', label: 'Nombre de dépendances' },
  ]

  return (
    <div>
      {(isFetchingRiskTypeList || isFetchingRiskUsageList || isFetchingRiskNotVerifiedReasonList) && <Loader />}
      <TravelerLargeTitle>
        Conformité du risque
      </TravelerLargeTitle>
      {
        formikForm.values.conformiteDuRisque.contratRex &&
          <RiskComplianceContractInfo
            contractInfo={formikForm.values.conformiteDuRisque.contratRex}
            assure={formikForm.values.acteurs.find((acteur) => acteur.type === TypePersonne.Assure)!}
          />
      }
      <FormBoldTitle bigger>
        Verification du risque
      </FormBoldTitle>
      <CardContainer>
        <VerifTitleContainer>
          <div />
          <BoldText>
            Valeur déclarée
          </BoldText>
          <BoldText>
            Valeur relevée
          </BoldText>
          <BoldText>
            Vérification
          </BoldText>
        </VerifTitleContainer>
        <VerifContentContainer>
          <BoldText>
            Adresse
          </BoldText>
          <BoldText grey={verifyValue('adresseRisque')}>
            {getAddress(getDeclaredValue('adresseRisque') as Adresse)}
          </BoldText>
          <BoldText>
            {getAddress(getActualValue('adresseRisque') as Adresse).trim() || <div />}
            <CustomIconButton
              Icon={Edit}
              label="Corriger la valeur"
              onClick={() => handleValueVerif('adresseRisque', 'Non', 'Adresse', getActualValue('adresseRisque') || getDeclaredValue('adresseRisque') || undefined, 'address')}
              size="small"
            />
          </BoldText>
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={getValueVerification('adresseRisque')}
            setSelectedOption={(newVal) => handleValueVerif('adresseRisque', newVal, 'Adresse', getActualValue('adresseRisque') || getDeclaredValue('adresseRisque') || undefined, 'address')}
            smaller
            disabled={formikForm.values.disabled}
          />
          <BoldText>
            Type de risque
          </BoldText>
          <BoldText grey={verifyValue('typeRisque')}>
            {(getDeclaredValue('typeRisque') as unknown as CodeLabel)?.libelle}
          </BoldText>
          <BoldText>
            {(getActualValue('typeRisque') as unknown as CodeLabel)?.libelle || <div />}
            <CustomIconButton
              Icon={Edit}
              label="Corriger la valeur"
              onClick={() => handleValueVerif('typeRisque', 'Non', 'Type de risque', getActualValue('typeRisque') || getDeclaredValue('typeRisque') || undefined, 'selectList')}
              size="small"
            />
          </BoldText>
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={getValueVerification('typeRisque')}
            setSelectedOption={(newVal) => handleValueVerif('typeRisque', newVal, 'Type de risque', getActualValue('typeRisque') || getDeclaredValue('typeRisque') || undefined, 'selectList')}
            smaller
            disabled={formikForm.values.disabled}
          />
          <BoldText>
            Usage du risque
          </BoldText>
          <BoldText grey={verifyValue('usageRisque')}>
            {(getDeclaredValue('usageRisque') as unknown as CodeLabel)?.libelle}
          </BoldText>
          <BoldText>
            {(getActualValue('usageRisque') as unknown as CodeLabel)?.libelle || <div />}
            <CustomIconButton
              Icon={Edit}
              label="Corriger la valeur"
              onClick={() => handleValueVerif('usageRisque', 'Non', 'Usage du risque', getActualValue('usageRisque') || getDeclaredValue('usageRisque') || undefined, 'selectList')}
              size="small"
            />
          </BoldText>
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={getValueVerification('usageRisque')}
            setSelectedOption={(newVal) => handleValueVerif('usageRisque', newVal, 'Usage du risque', getActualValue('usageRisque') || getDeclaredValue('usageRisque') || undefined, 'selectList')}
            smaller
            disabled={formikForm.values.disabled}
          />
          {
            verificationList.map((value) => (
              <React.Fragment key={value.label}>
                <BoldText>
                  {value.label}
                </BoldText>
                <BoldText grey={verifyValue(value.fieldName)}>
                  {getDeclaredValue(value.fieldName) as number}
                </BoldText>
                <BoldText>
                  {(getActualValue(value.fieldName) !== undefined && getActualValue(value.fieldName) !== null) ? getActualValue(value.fieldName) as number : <div />}
                  <CustomIconButton
                    Icon={Edit}
                    label="Corriger la valeur"
                    onClick={() => handleValueVerif(value.fieldName, 'Non', value.label, getActualValue(value.fieldName) || getDeclaredValue(value.fieldName) || 0)}
                    size="small"
                  />
                </BoldText>
                <SegmentedButtons
                  options={verifyOptions}
                  selectedOption={getValueVerification(value.fieldName)}
                  setSelectedOption={(newVal) => handleValueVerif(value.fieldName, newVal, value.label, getActualValue(value.fieldName) || getDeclaredValue(value.fieldName) || 0)}
                  smaller
                  disabled={formikForm.values.disabled}
                />
              </React.Fragment>
            ))
          }
        </VerifContentContainer>
      </CardContainer>
      <br />
      <CardContainer>
        <CardContentContainer>
          <FormBoldTitle bigger>
            Conformité du risque
          </FormBoldTitle>
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={formikForm.values.conformiteDuRisque.conformite?.verification}
            setSelectedOption={(newVal) => handleValue('conformite.verification', newVal)}
            disabled={formikForm.values.disabled}
          />
          {
            formikForm.values.conformiteDuRisque.conformite?.verification === Verif.NonVerifie &&
              <>
                <FormBoldTitle>
                  Motif de non vérification
                </FormBoldTitle>
                <Field
                  component={Select}
                  name="conformiteDuRisque.conformite.motifNonVerification.code"
                  displayEmpty
                  value={formikForm.values.conformiteDuRisque.conformite?.motifNonVerification?.code || ''}
                  renderValue={verifySelectFieldValue(formikForm.values.conformiteDuRisque.conformite?.motifNonVerification?.code)}
                  disabled={isFetchingRiskNotVerifiedReasonList}
                >
                  {
                    riskNotVerifiedReasonList.map((value, index) => (
                      <MenuItem
                        value={value.code}
                        key={`${value.code}-${index}`}
                      >
                        {value.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
              </>
          }
          {
            formikForm.values.conformiteDuRisque.conformite?.verification === Verif.Non &&
              <>
                <br />
                <div>
                  <Field
                    id="description"
                    component={CheckboxWithLabel}
                    Label={{ label: 'Description du risque erronée' }}
                    type="checkbox"
                    checked={formikForm.values.conformiteDuRisque.conformite.descriptionRisqueErrone || false}
                    onChange={(e: React.SyntheticEvent, c: boolean) => formikForm.setFieldValue('conformiteDuRisque.conformite.descriptionRisqueErrone', c)}
                  />
                </div>
                <div>
                  <Field
                    id="garantie"
                    component={CheckboxWithLabel}
                    Label={{ label: 'Garantie et/ou capitaux insuffisants' }}
                    type="checkbox"
                    checked={formikForm.values.conformiteDuRisque.conformite.garantieEtOuCapitauxInsuffisants || false}
                    onChange={(e: React.SyntheticEvent, c: boolean) => formikForm.setFieldValue('conformiteDuRisque.conformite.garantieEtOuCapitauxInsuffisants', c)}
                  />
                </div>
                {
                  formikForm.values.conformiteDuRisque.conformite.garantieEtOuCapitauxInsuffisants &&
                    <Field
                      component={MarginBottomTextField}
                      placeholder="Votre message"
                      rows={3}
                      multiline
                      value={formikForm.values.conformiteDuRisque.conformite.garantieEtOuCapitauxInsuffisantsCommentaire || ''}
                      name="conformiteDuRisque.conformite.garantieEtOuCapitauxInsuffisantsCommentaire"
                      disabled={formikForm.values.disabled}
                    />
                }
                <div>
                  <Field
                    id="garantie"
                    component={CheckboxWithLabel}
                    Label={{ label: 'Autre (Clauses, Bail, Éléments spécifiques du contrat...)' }}
                    type="checkbox"
                    checked={formikForm.values.conformiteDuRisque.conformite.autreRejet || false}
                    onChange={(e: React.SyntheticEvent, c: boolean) => formikForm.setFieldValue('conformiteDuRisque.conformite.autreRejet', c)}
                  />
                </div>
                {
                  formikForm.values.conformiteDuRisque.conformite.autreRejet &&
                    <Field
                      component={TextField}
                      placeholder="Votre message"
                      rows={3}
                      multiline
                      value={formikForm.values.conformiteDuRisque.conformite.autreRejetCommentaire || ''}
                      name="conformiteDuRisque.conformite.autreRejetCommentaire"
                      disabled={formikForm.values.disabled}
                    />
                }
              </>
          }
          <FormBoldTitle
            required={
              formikForm.values.conformiteDuRisque.conformite?.verification === Verif.NonVerifie &&
              formikForm.values.conformiteDuRisque.conformite?.motifNonVerification?.code === 'Autre'
            }
          >
            Commentaire
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Votre message"
            rows={3}
            multiline
            name="conformiteDuRisque.conformite.commentaire"
            disabled={formikForm.values.disabled}
          />
        </CardContentContainer>
      </CardContainer>
      {
        formikForm.values.sinistre.type?.code === TypeRexSinistre.Vol &&
          <>
            <FormBoldTitle bigger>
              Moyen de protection vol
            </FormBoldTitle>
            <SegmentedButtons
              options={verifyOptions}
              selectedOption={formikForm.values.conformiteDuRisque.protectionVol?.verification}
              setSelectedOption={(newVal) => handleValue('protectionVol.verification', newVal)}
              disabled={formikForm.values.disabled}
            />
            <FormBoldTitle>
              Commentaire
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Votre message"
              rows={3}
              multiline
              name="conformiteDuRisque.protectionVol.commentaire"
              disabled={formikForm.values.disabled}
            />
          </>
      }
      <FormBoldTitle bigger>
        Bail commercial
      </FormBoldTitle>
      <SegmentedButtons
        options={isBcOptions}
        selectedOption={formikForm.values.conformiteDuRisque.bailCommercial?.isBC}
        setSelectedOption={(newVal) => handleValue('bailCommercial.isBC', newVal)}
        disabled={formikForm.values.disabled}
      />
      <FormBoldTitle>
        Commentaire
      </FormBoldTitle>
      <Field
        component={EndTextField}
        placeholder="Votre message"
        rows={3}
        multiline
        name="conformiteDuRisque.bailCommercial.commentaire"
        disabled={formikForm.values.disabled}
      />
      {
        modalInfo?.modalType !== undefined && modalInfo?.label !== undefined && !formikForm.values.disabled &&
          <RiskComplianceCorrectInfoModal
            {...modalInfo}
            selectList={
              (modalInfo.verificationFieldName === 'typeRisque' && riskTypeList) ||
              (modalInfo.verificationFieldName === 'usageRisque' && riskUsageList) ||
              []
            }
            open={modalInfo.modalType !== undefined}
            handleClose={handleClose}
          />
      }
    </div>
  )
}

export default RiskCompliancePage
