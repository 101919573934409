/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
} from '@mui/material'
import DrawerContent from 'components/Drawer/DrawerContent'
import CaseSidebarTaskMenuItem from './CaseSidebarTaskMenuItem'
import BlueRoundedChip from 'components/BlueRoundedChip/BlueRoundedChip'
import ScrollableFiltersContainer from 'components/ScrollableFiltersContainer/ScrollableFiltersContainer'

/* Type imports ------------------------------------------------------------- */
import type { Tache } from 'API/__generated__/Api'
import { TypeTache } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 1rem;
  padding: 10px 10px 0px 20px;
`

const Chip = styled(BlueRoundedChip)`
  min-width: fit-content;
  height: fit-content;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarTaskMenuProps {
  tasks?: Tache[];
  isFetching: boolean;
  caseId: string;
  onClose: () => void;
  isConnected: boolean;
}

const CaseSidebarTaskMenu: React.FC<CaseSidebarTaskMenuProps> = ({
  tasks = [],
  isFetching,
  caseId,
  onClose,
  isConnected,
}) => {
  const navigate = useNavigate()
  const [ filters, setFilters ] = useState<TypeTache[]>([])

  const handleFilter = (newType: TypeTache): void => {
    if (filters.find((type) => type === newType) !== undefined) {
      setFilters([ ...filters.filter((type) => type !== newType) ])
    } else {
      setFilters([ ...filters, newType ])
    }
  }

  if (isFetching) {
    return <CircularProgress />
  }

  const handleClick = (): void => {
    onClose()
    navigate(`/actions-rapides/${caseId}/tache`)
  }

  return (
    <>
      <TitleContainer>
        Tâches
        <Button
          variant="contained"
          onClick={handleClick}
        >
          Nouvelle tâche
        </Button>
      </TitleContainer>
      <DrawerContent>
        <ScrollableFiltersContainer>
          {
            Object.entries(TypeTache)
              .filter((value): boolean => isNaN(Number(value[1])))
              .map(([ , option ]) => (
                <Chip
                  variant={filters.find((filter) => filter === option) !== undefined ? 'contained' : 'outlined'}
                  key={option[1]}
                  onClick={() => handleFilter(option)}
                >
                  {option}
                </Chip>
              ))
          }
        </ScrollableFiltersContainer>
        {
          isFetching ?
            <CircularProgress /> :
            tasks
              .filter((task) => filters.length === 0 || filters.find((filter) => filter === task.typeTache))
              .map((task: Tache, index: number) => (
                <CaseSidebarTaskMenuItem
                  task={task}
                  key={`${task.id}-${index}`}
                  isConnected={isConnected}
                />
              ))
        }
      </DrawerContent>
    </>
  )
}

export default CaseSidebarTaskMenu
