/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component declaration ---------------------------------------------------- */
export interface FormBoldTitleProps {
  bigger?: boolean;
  smaller?: boolean;
  required?: boolean;
}

const FormBoldTitle = styled.div<FormBoldTitleProps>`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: ${(props) => props.bigger ? '1.25rem' : props.smaller ? '.8rem' : '1rem'};
  margin-bottom: 10px;
  margin-top: ${(props) => props.bigger ? '40px' : props.smaller ? '10px' : '20px'};

  &:after {
    content: ${(props) => props.required ? "' *'" : ''};
    /* color: #d32f2f; */
    color: red;
  }
`

export default FormBoldTitle
