/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCasesChipListQuery,
  useGetCaseStateCodeListQuery,
  useGetDisasterNatureListQuery,
  useLazyGetCasesByFiltersQuery,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import {
  Select as MuiSelect,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Collapse,
  Button,
  CircularProgress,
} from '@mui/material'
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded'
import CloseButton from 'components/CloseButton/CloseButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CasesList from 'components/CasesList/CasesList'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import CaseListPageChanger from 'components/CaseListPageChanger/CaseListPageChanger'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikHelpers,
  FormikContextType,
} from 'formik'
import type {
  DossierRecherche,
  RechercheDossierListParams,
} from 'API/__generated__/Api'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'

/* Type declarations -------------------------------------------------------- */
const researchCaseSchema = Yup.object().shape<Shape<RechercheDossierListParams>>({
  StartIndex: Yup.number().min(0).required(),
  Limit: Yup.number().min(5).max(100).required(),
}).required()

type ResearchForm = FormikContextType<RechercheDossierListParams>

type OpenedMenu = {
  advanced: boolean;
  filters: boolean;
}

/* Styled components -------------------------------------------------------- */
interface GridContainerProps {
  cols: number;
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  gap: 10px;
  align-items: center;
  justify-content: stretch;

  grid-template-columns: repeat(${(props) => props.cols}, minmax(0, 1fr));
`

const FullHeightDialog = styled(Dialog)`
  .MuiPaper-root {
    min-height: calc(100% - 64px) !important;
  }
  z-index: 250 !important;
`

const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 5px;
  padding-bottom: 0px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
  overflow-y: scroll;
`

const FormButton = styled(Button)`
  margin-top: 20px;
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

const FormBoldTitleNoTop = styled(FormBoldTitle)`
  margin-top: 0;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px !important;
`

const SegmentedButtonContainer = styled.div`
  margin-top: 30px;
  margin-bottom: -10px;
`

const FormContainer = styled.div`
  margin-bottom: 5px;
`

const BoldSeparator = styled.div`
  margin: 20px 0px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const LoaderContainer = styled.div`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSearcherModalProps {
  open: boolean;
  handleClose: () => void;
}

const CaseSearcherModal: React.FC<CaseSearcherModalProps> = ({
  open,
  handleClose,
}) => {
  const [ filteredList, setFilteredList ] = useState<DossierRecherche[]>([])
  const [ mandantList, setMandantList ] = useState<string[]>([])
  const [ mandantFilter, setMandantFilter ] = useState<string>('')
  const [ typeFilter, setTypeFilter ] = useState<string>('')
  const [ statutFilter, setStatutFilter ] = useState<string>('')
  const [ openedMenu, setOpenedMenu ] = useState<OpenedMenu>({ advanced: false, filters: false })

  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterNatureListQuery(undefined, {
    skip: !open,
  })
  const {
    currentData: caseStateCodeList = [],
    isFetching: isFetchingCaseStateCodeList,
  } = useGetCaseStateCodeListQuery(undefined, {
    skip: !open,
  })
  const [
    getCaseList,
    {
      currentData: caseList = [],
      isFetching: isFetchingCaseList,
    },
  ] = useLazyGetCasesByFiltersQuery()
  const {
    currentData: casesChips = [],
    isFetching: isFetchingCasesChips,
  } = useGetCasesChipListQuery(
    {
      modeSimple: true,
      dossiers: caseList.map((caseItem) => caseItem.id),
    },
    { skip: caseList.length === 0 },
  )

  const onFetchCaseEnd = (data: DossierRecherche[], resetStartIndex: boolean = false): void => {
    if (open) {
      setMandantFilter('')
      setMandantList(
        [ ...new Set(data.map((exchange) => exchange.compagnie?.libelle || 'Compagnie inconnue')) ]
          .sort((a, b) => a.localeCompare(b)),
      )
      if (resetStartIndex) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        formikForm.setFieldValue('StartIndex', 0)
      }
      if (data.length === 0) {
        setFilteredList([])
      }
    }
  }

  const onSubmit = (values: RechercheDossierListParams, { setSubmitting }: FormikHelpers<RechercheDossierListParams>): void => {
    getCaseList({ ...values, StartIndex: 0 }, true)
      .then(({ data }) => onFetchCaseEnd(data || [], true))
      .catch(console.error)
    setSubmitting(false)
  }

  const formikForm: ResearchForm = useForm<RechercheDossierListParams>(
    {
      initialValues: {
        AdresseSinistre: '',
        Assure: '',
        CodePostalVilleSinistre: '',
        Limit: 5,
        Mandant: '',
        MesDossiers: true,
        PoliceAssure: '',
        Reference: '',
        StartIndex: 0,
        Tiers: '',
      },
      onSubmit: onSubmit,
      validationSchema: researchCaseSchema,
    },
  )

  const handleValue = (type: string, value?: number | boolean): void => {
    formikForm.setFieldValue(type, value)
  }

  useEffect(() => {
    if (caseList.length > 0 && open) {
      setFilteredList(caseList.filter((dossier) =>
        (mandantFilter === '' || (dossier.compagnie?.libelle || 'Compagnie inconnue') === mandantFilter) &&
        (typeFilter === '' || dossier.sinistre.nature?.code === typeFilter) &&
        (statutFilter === '' || dossier.etat.code === statutFilter),
      ))
    }
  }, [
    mandantFilter,
    typeFilter,
    statutFilter,
    caseList,
    open,
  ])

  useEffect(() => {
    if (open) {
      getCaseList(formikForm.values, true)
        .then(({ data }) => onFetchCaseEnd(data || []))
        .catch(console.error)
    }
  }, [
    formikForm.values.StartIndex,
    formikForm.values.Limit,
    open,
  ])

  const onClickStartIndexButtonPrev = (): void => handleValue('StartIndex', (formikForm.values.StartIndex || 0) - parseInt(formikForm.values.Limit?.toString() || '0') >= 0 ? (formikForm.values.StartIndex || 0) - parseInt(formikForm.values.Limit?.toString() || '0') : 0)

  const onClickStartIndexButtonNext = (): void => handleValue('StartIndex', (formikForm.values.StartIndex || 0) + parseInt(formikForm.values.Limit?.toString() || '0'))

  const caseTypeOption: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Mes dossiers seulement' }, { value: false, label: 'Tous les dossiers' } ]

  const isFetching = useMemo(() => isFetchingCaseList || isFetchingDisasterNatureList || isFetchingCaseStateCodeList,
    [
      isFetchingCaseList,
      isFetchingDisasterNatureList,
      isFetchingCaseStateCodeList,
    ])

  return (
    <FullHeightDialog
      open={open}
      onClose={() => handleClose()}
      fullWidth
      maxWidth="md"
    >
      <DialogTitleContainer>
        Rechercher un dossier
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        <FormContainer>
          <Form form={formikForm}>
            <SegmentedButtonContainer>
              <SegmentedButtons
                options={caseTypeOption}
                selectedOption={formikForm.values.MesDossiers}
                setSelectedOption={(newVal) => handleValue('MesDossiers', newVal)}
                smaller
              />
            </SegmentedButtonContainer>
            <FormBoldTitle>
              Recherche par référence
            </FormBoldTitle>
            <Field
              component={TextField}
              name="Reference"
              placeholder="Référence dossier / sinistre / compagnie"
              size="small"
            />
            <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, advanced: !openedMenu.advanced })}>
              Recherche avancée
              <DropDownArrow open={openedMenu.advanced} />
            </FormTitleWithArrow>
            <Collapse
              in={openedMenu.advanced}
              timeout="auto"
              unmountOnExit
            >
              <GridContainer cols={3}>
                <div>
                  <FormBoldTitleNoTop smaller>
                    Mandant
                  </FormBoldTitleNoTop>
                  <Field
                    component={TextField}
                    name="Mandant"
                    placeholder="Mandant"
                    size="small"
                  />
                </div>
                <div>
                  <FormBoldTitleNoTop smaller>
                    Police
                  </FormBoldTitleNoTop>
                  <Field
                    component={TextField}
                    name="PoliceAssure"
                    placeholder="Police assuré"
                    size="small"
                  />
                </div>
                <div>
                  <FormBoldTitleNoTop smaller>
                    Assuré
                  </FormBoldTitleNoTop>
                  <Field
                    component={TextField}
                    name="Assure"
                    placeholder="Assuré"
                    size="small"
                  />
                </div>
              </GridContainer>
              <GridContainer cols={3}>
                <div>
                  <FormBoldTitle smaller>
                    Tiers
                  </FormBoldTitle>
                  <Field
                    component={TextField}
                    name="Tiers"
                    placeholder="Tiers"
                    size="small"
                  />
                </div>
                <div>
                  <FormBoldTitle smaller>
                    Adresse
                  </FormBoldTitle>
                  <Field
                    component={TextField}
                    name="AdresseSinistre"
                    placeholder="Adresse"
                    size="small"
                  />
                </div>
                <div>
                  <FormBoldTitle smaller>
                    Code Postal / Ville
                  </FormBoldTitle>
                  <Field
                    component={TextField}
                    name="CodePostalVilleSinistre"
                    placeholder="Code Postal / Ville"
                    size="small"
                  />
                </div>
              </GridContainer>
            </Collapse>
            <FormButton
              type="submit"
              variant="contained"
              fullWidth
            >
              Rechercher
            </FormButton>
            <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, filters: !openedMenu.filters })}>
              Filtres
              <DropDownArrow open={openedMenu.filters} />
            </FormTitleWithArrow>
            <Collapse
              in={openedMenu.filters}
              timeout="auto"
              unmountOnExit
            >
              <GridContainer cols={3}>
                <div>
                  <FormBoldTitleNoTop smaller>
                    Mandant du dossier
                  </FormBoldTitleNoTop>
                  <MuiSelect
                    value={mandantList.length > 0 ? mandantFilter : ''}
                    onChange={(e): void => setMandantFilter(e.target.value)}
                    fullWidth
                    renderValue={verifySelectFieldValue(mandantFilter)}
                    displayEmpty
                    size="small"
                    disabled={isFetching}
                  >
                    <MenuItem
                      value=""
                      key=""
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      mandantList.map((option) => (
                        <MenuItem
                          value={option}
                          key={option}
                        >
                          {option}
                        </MenuItem>
                      ))
                    }
                  </MuiSelect>
                </div>
                <div>
                  <FormBoldTitleNoTop smaller>
                    Type de sinistre
                  </FormBoldTitleNoTop>
                  <MuiSelect
                    value={disasterNatureList.length > 0 ? typeFilter : ''}
                    onChange={(e): void => setTypeFilter(e.target.value)}
                    fullWidth
                    renderValue={verifySelectFieldValue(typeFilter)}
                    displayEmpty
                    size="small"
                    disabled={isFetching}
                  >
                    <MenuItem
                      value=""
                      key=""
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      disasterNatureList.map((option, index) => (
                        <MenuItem
                          value={option.code}
                          key={`${option.code}-${index}`}
                        >
                          {option.libelle}
                        </MenuItem>
                      ))
                    }
                  </MuiSelect>
                </div>
                <div>
                  <FormBoldTitleNoTop smaller>
                    Statut du dossier
                  </FormBoldTitleNoTop>
                  <MuiSelect
                    value={caseStateCodeList.length > 0 ? statutFilter : ''}
                    onChange={(e): void => setStatutFilter(e.target.value)}
                    fullWidth
                    renderValue={verifySelectFieldValue(statutFilter)}
                    displayEmpty
                    size="small"
                    disabled={isFetching}
                  >
                    <MenuItem
                      value=""
                      key=""
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      caseStateCodeList.map((option, index) => (
                        <MenuItem
                          value={option.code?.code}
                          key={`${option.code?.code}-${index}`}
                        >
                          {option.code?.libelle}
                        </MenuItem>
                      ))
                    }
                  </MuiSelect>
                </div>
              </GridContainer>
            </Collapse>
            <BoldSeparator />
            <CaseListPageChanger
              disablePrev={formikForm.values.StartIndex === 0}
              disableNext={caseList.length < parseInt(formikForm.values.Limit?.toString() || '0')}
              limitValue={formikForm.values.Limit}
              onClickPrev={onClickStartIndexButtonPrev}
              onClickNext={onClickStartIndexButtonNext}
              onLimitChange={(value) => formikForm.setFieldValue('Limit', value)}
            />
            {
              isFetching ?
                <LoaderContainer>
                  <CircularProgress />
                </LoaderContainer> :
                <div onClick={() => handleClose()}>
                  <CasesList
                    cases={filteredList}
                    loading={isFetching}
                    casesChips={casesChips}
                    isFetchingChips={isFetchingCasesChips}
                    searcher
                  />
                </div>
            }
            <CaseListPageChanger
              disablePrev={formikForm.values.StartIndex === 0}
              disableNext={caseList.length < parseInt(formikForm.values.Limit?.toString() || '0')}
              limitValue={formikForm.values.Limit}
              onClickPrev={onClickStartIndexButtonPrev}
              onClickNext={onClickStartIndexButtonNext}
              onLimitChange={(value) => formikForm.setFieldValue('Limit', value)}
            />
            <br />
          </Form>
        </FormContainer>
      </DialogContentContainer>
    </FullHeightDialog>
  )
}

export default CaseSearcherModal
