/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { getAddress } from 'helpers/getAddress'
import { verifyNumberField } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Type imports ------------------------------------------------------------- */
import type {
  RiskComplianceModalType,
  RiskComplianceValueType,
} from 'types/RiskCompliance'
import type {
  Adresse,
  CodeLabel,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px 0px;
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);

  align-items: center;
  justify-content: stretch;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

const Separator = styled.div`
  height: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface RiskComplianceCorrectInfoModalProps {
  open: boolean;
  handleClose: (value?: RiskComplianceValueType) => void;
  label: string;
  value?: RiskComplianceValueType;
  modalType: RiskComplianceModalType;
  selectList: CodeLabel[];
}

const RiskComplianceCorrectInfoModal: React.FC<RiskComplianceCorrectInfoModalProps> = ({
  open,
  handleClose,
  label,
  value,
  modalType,
  selectList,
}) => {
  const [ address, setAddress ] = useState<Adresse>(value as Adresse)
  const [ selectedOption, setSelectedOption ] = useState<string>((value as CodeLabel)?.code || '')
  const [ valueRelevee, setValueRelevee ] = useState<RiskComplianceValueType>(value)

  const onClose = () => {
    handleClose(
      (modalType === 'address' && address) ||
      (modalType === 'selectList' && selectList.find((o) => o.code === selectedOption)) ||
      valueRelevee,
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleContainer>
        Corriger les informations ?
        <CloseButton handleClose={() => handleClose()} />
      </DialogTitleContainer>
      {
        modalType === 'address' && (
          <DialogContentContainer>
            {
              getAddress(value as Adresse) &&
                <FormBoldTitle>
                  {'Ancienne valeur : '}
                  {getAddress(value as Adresse)}
                </FormBoldTitle>
            }
            <FormBoldTitle>
              {`${label} :`}
            </FormBoldTitle>
            <TextField
              value={address.adresse1}
              onChange={(e): void => setAddress({ ...address, adresse1: e.target.value })}
              placeholder="Adresse"
            />
            <Separator />
            <TextField
              value={address.adresse2}
              onChange={(e): void => setAddress({ ...address, adresse2: e.target.value })}
              placeholder="Adresse"
            />
            <Separator />
            <TextField
              value={address.adresse3}
              onChange={(e): void => setAddress({ ...address, adresse3: e.target.value })}
              placeholder="Adresse"
            />
            <GridContainer>
              <FormBoldTitle>
                Code postal :
              </FormBoldTitle>
              <FormBoldTitle>
                Ville :
              </FormBoldTitle>
            </GridContainer>
            <GridContainer>
              <TextField
                value={address.codePostal}
                onChange={(e): void => setAddress({ ...address, codePostal: e.target.value })}
                placeholder="Code postal"
              />
              <TextField
                value={address.ville}
                onChange={(e): void => setAddress({ ...address, ville: e.target.value })}
                placeholder="Ville"
              />
            </GridContainer>
            <GridContainer>
              <FormBoldTitle>
                Pays :
              </FormBoldTitle>
              <FormBoldTitle>
                Digicode :
              </FormBoldTitle>
            </GridContainer>
            <GridContainer>
              <TextField
                value={address.pays}
                onChange={(e): void => setAddress({ ...address, pays: e.target.value })}
                placeholder="Pays"
              />
              <TextField
                value={address.digicode}
                onChange={(e): void => setAddress({ ...address, digicode: e.target.value })}
                placeholder="Digicode"
              />
            </GridContainer>
          </DialogContentContainer>
        )
      }
      {
        modalType === 'selectList' && (
          <DialogContentContainer>
            {
              (value as CodeLabel)?.libelle &&
                <FormBoldTitle>
                  {'Ancienne valeur : '}
                  {(value as CodeLabel)?.libelle}
                </FormBoldTitle>
            }
            <FormBoldTitle>
              {`${label} :`}
            </FormBoldTitle>
            <Select
              value={selectedOption}
              onChange={(e): void => setSelectedOption(e.target.value)}
              fullWidth
              renderValue={verifySelectFieldValue(selectedOption)}
              displayEmpty
            >
              {
                selectList.map((option) => (
                  <MenuItem
                    value={option.code}
                    key={option.code}
                  >
                    {option.libelle}
                  </MenuItem>
                ))
              }
            </Select>
          </DialogContentContainer>
        )
      }
      {
        modalType === 'verification' && (
          <DialogContentContainer>
            {
              JSON.stringify(value) &&
                <FormBoldTitle>
                  {`Valeur déclarée : `}
                  {JSON.stringify(value)}
                </FormBoldTitle>
            }
            <FormBoldTitle>
              {`${label} :`}
            </FormBoldTitle>
            <TextField
              value={valueRelevee}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => verifyNumberField(e, ((e) => setValueRelevee(e.target.value)))}
              placeholder={label}
            />
          </DialogContentContainer>
        )
      }
      <DialogActionContainer>
        <FormButton onClick={() => handleClose()}>
          Annuler
        </FormButton>
        <FormButton
          variant="contained"
          onClick={onClose}
        >
          Corriger
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default RiskComplianceCorrectInfoModal
