/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import CaseSidebarHistoryItem from './CaseSidebarHistoryItem'
import CaseSidebarArrowTitle from '../CaseSidebarComponents/CaseSidebarArrowTitle/CaseSidebarArrowTitle'

/* Type imports ------------------------------------------------------------- */
import type { SuiviSimplifie } from 'API/__generated__/Api'

/* Internal variables ------------------------------------------------------- */
const STR_LEN_DEFAULT_LIMIT: number = 250
const ITEM_DEFAULT_LIMIT: number = 3

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarHistoryProps {
  items?: SuiviSimplifie[];
  isFetching: boolean;
  strLenLimit?: number;
  itemLimit?: number;
  setOpenedMenu: () => void;
}

const CaseSidebarHistory: React.FC<CaseSidebarHistoryProps> = ({
  items = [],
  isFetching,
  strLenLimit = STR_LEN_DEFAULT_LIMIT,
  itemLimit = ITEM_DEFAULT_LIMIT,
  setOpenedMenu,
}) => {

  return (
    <div>
      <CaseSidebarArrowTitle
        setOpenedMenu={setOpenedMenu}
        title="Historique (3 derniers)"
      />
      {
        isFetching ?
          <CircularProgress /> :
          items.slice(0, itemLimit).map((history: SuiviSimplifie, index: number) => (
            <CaseSidebarHistoryItem
              key={`history-${history.libelle?.replace(/\s/g, '_')}-${index}`}
              item={history}
              strLenLimit={strLenLimit}
            />
          ))
      }
    </div>
  )
}

export default CaseSidebarHistory
