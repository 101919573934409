/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  Menu,
  MenuItem,
} from '@mui/material'
import { MoreVertRounded } from '@mui/icons-material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Type imports ------------------------------------------------------------- */
import type { RecoursTraveller } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const BoldUppercaseTitle = styled(FormBoldTitle)`
  text-transform: uppercase;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BoldText = styled.div`
  font-weight: bold;
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 2fr 2fr 4fr 1fr 3%;

  align-items: stretch;
  justify-content: stretch;

  margin-bottom: 10px;
`

const BorderedDiv = styled.div`
  padding-right: 10px;
  border-right: 2px solid ${(props) => props.theme.colors.grey};
  margin-right: 10px;
`

const CardContainer = styled(Card)`
  margin-bottom: 20px;
  font-size: 14px;
`

const CardContentTableContainer = styled(CardContent)`
  padding-top: 0px;
  overflow-x: overlay;
  padding-bottom: 10px !important;
`

const MoreDotsButton = styled(Button)`
  align-self: center;
  min-width: auto;
`

const MoreDots = styled(MoreVertRounded)`
  font-size: 2.5rem;
`

/* Component declaration ---------------------------------------------------- */
interface RecourseCardProps {
  recourse: RecoursTraveller;
  personName: string;
  onDelete: () => void;
  disabled: boolean;
}

const RecourseCard: React.FC<RecourseCardProps> = ({
  recourse,
  personName,
  onDelete,
  disabled,
}) => {
  const [ anchorMenu, setAnchorMenu ] = useState<null | SVGElement>(null)
  const menuOpen = Boolean(anchorMenu)

  const handleMenuClick = (event: React.MouseEvent<SVGElement>): void => {
    setAnchorMenu(event.currentTarget)
  }

  const handleMenuClose = (): void => {
    setAnchorMenu(null)
  }

  return (
    <CardContainer>
      <CardContentTableContainer>
        <BoldUppercaseTitle>
          {`${recourse.type} N°${recourse.compteur} - ${personName}`}
        </BoldUppercaseTitle>
        <GridContainer>
          <BorderedDiv>
            Contre / À subir
            <BoldText>
              {recourse.aSubir ? 'À subir' : 'Contre'}
            </BoldText>
          </BorderedDiv>
          <BorderedDiv>
            Type de prestation
            <BoldText>
              {recourse.neuf ? 'Neuf' : 'Vétusté déduite'}
            </BoldText>
          </BorderedDiv>
          <BorderedDiv>
            Assureur Tiers
            <BoldText>
              {isValidString(recourse.cibleRecours.assureurCible?.nom) ? `${recourse.cibleRecours.assureurCible?.nom} - ` : ''}
              {`N° Police ${recourse.cibleRecours.police}`}
              <br />
              Cabinet d'expertise
              {' '}
              {recourse.cibleRecours.assureurCible?.cabinet}
              <br />
              {(recourse.cibleRecours.assureurCible?.refExpert?.length || 0) > 0 ? `Ref. expert ${recourse.cibleRecours.assureurCible?.refExpert}` : null}
            </BoldText>
          </BorderedDiv>
          <div>
            Montant
            <BoldText>
              {recourse.montant}
              {' '}
              €
            </BoldText>
          </div>
          <MoreDotsButton
            variant="text"
            size="small"
          >
            <MoreDots
              color="primary"
              onClick={handleMenuClick}
            />
          </MoreDotsButton>
        </GridContainer>
        <Menu
          anchorEl={anchorMenu}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={onDelete}
            disabled={disabled}
          >
            Supprimer
          </MenuItem>
        </Menu>
      </CardContentTableContainer>
    </CardContainer>
  )
}

export default RecourseCard
