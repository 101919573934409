/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useDispatch } from 'react-redux'
import { openQuickActions } from 'store/slices/quickActionModalSlice'

/* Component imports -------------------------------------------------------- */
import MoreVertRounded from '@mui/icons-material/MoreVertRounded'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'

/* Type imports ------------------------------------------------------------- */
import type { ButtonProps } from '@mui/material'

/* Styled components -------------------------------------------------------- */
const SlimCustomIconButton = styled(CustomIconButton)`
  width: 25px;
  min-width: 25px;
`

/* Component declaration ---------------------------------------------------- */
interface OpenQuickActionsButtonProps extends ButtonProps {
  caseId: string;
  disabled?: boolean;
}

const OpenQuickActionsButton: React.FC<OpenQuickActionsButtonProps> = ({ caseId, disabled = false, ...rest }) => {
  const dispatch = useDispatch()

  const openQuickActionsWithCaseId = (): void => {
    dispatch(
      openQuickActions({
        quickActionsOpen: true,
        caseId,
      }),
    )
  }

  return (
    <SlimCustomIconButton
      Icon={MoreVertRounded}
      onClick={(e) => {e.stopPropagation(); openQuickActionsWithCaseId()}}
      onTouchStart={(e) => {e.stopPropagation(); openQuickActionsWithCaseId()}}
      disabled={disabled}
      {...rest}
      label="Actions rapides"
    />
  )
}

export default OpenQuickActionsButton
