/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import CaseSidebarItemComponents from 'layouts/CaseSidebar/CaseSidebarComponents/CaseSidebarItemComponents/CaseSidebarItemComponents'
import ShowMoreLessText from 'components/ShowMoreLessText/ShowMoreLessText'
import DownloadFileButton from 'components/DownloadFileButton/DownloadFileButton'

/* Type imports ------------------------------------------------------------- */
import type { SuiviSimplifie } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DateContainer = styled.div`
  display: inline;
  float: right;
`

const Line = styled.h5`
  font-weight: normal;
  margin: 5px 0px;
`

const DownloadFileContainer = styled.div`
  button {
    margin-right: 5px;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0px;
      margin-bottom: 0px;
    }
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarHistoryItemProps {
  item: SuiviSimplifie;
  strLenLimit: number;
}

const CaseSidebarHistoryItem: React.FC<CaseSidebarHistoryItemProps> = ({
  item,
  strLenLimit,
}) => {

  return (
    <CaseSidebarItemComponents.Card>
      <Line>
        <b>
          {item.type.libelle}
          {' : '}
          {item.libelle}
        </b>
        <DateContainer>
          {DateUtils.APIStrToLocalDateString(item.date)}
        </DateContainer>
      </Line>
      {
        isValidString(item.emetteur) &&
          <Line>
            Émetteur :
            {' '}
            <b>
              {item.emetteur}
            </b>
          </Line>
      }
      {
        item.destinataires.length > 0 &&
          <Line>
            {`Destinataire${item.destinataires.length > 1 ? 's' : ''} : `}
            <b>
              {item.destinataires.join(', ')}
            </b>
          </Line>
      }
      <DownloadFileContainer>
        {
          item.pieceJointes.map((file, index) => (
            <DownloadFileButton
              key={`${file.fileName}-${index}`}
              url={file.url || ''}
              name={file.libelle || file.fileName || 'Document'}
            />
          ))
        }
      </DownloadFileContainer>
      <ShowMoreLessText
        content={item.commentaire}
        strLenLimit={strLenLimit}
      />
    </CaseSidebarItemComponents.Card>
  )
}

export default CaseSidebarHistoryItem
