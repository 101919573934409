/* Type imports ------------------------------------------------------------- */
import type { LocalisationGPS } from 'API/__generated__/Api'
import { LatLng } from 'leaflet'

export const getCoordinates = (coordinates?: LocalisationGPS): LatLng => {
  let lat: number = 0
  let lon: number = 0

  if (coordinates) {
    if (coordinates.latitude && !isNaN(coordinates.latitude)) {
      lat = coordinates.latitude
    }
    if (coordinates.longitude && !isNaN(coordinates.longitude)) {
      lon = coordinates.longitude
    }
  }

  return (new LatLng(lat, lon))
}

export const hasCoordinates = (coordinates?: LocalisationGPS): boolean => {
  if (!coordinates || !coordinates.latitude || isNaN(coordinates.latitude) || !coordinates.longitude || isNaN(coordinates.longitude)) {
    return false
  }
  return true
}
