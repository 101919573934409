/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  useGetCaseDocumentsQuery,
  useGetMailRecipientsQuery,
  useGetMailSendersQuery,
  useGetMailTypeListQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import Loader from 'components/Loader/Loader'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import MailForm from './MailForm'

/* Component declaration ---------------------------------------------------- */
interface QaMailPageProps {}

const QaMailPage: React.FC<QaMailPageProps> = () => {
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ triggerSubmit, setTriggerSubmit ] = useState<number>(0)

  const {
    currentData: senders = [],
    isFetching: isFetchingSenders,
  } = useGetMailSendersQuery(caseId)
  const {
    currentData: recipients = [],
    isFetching: isFetchingRecipients,
  } = useGetMailRecipientsQuery(
    {
      dossier: caseId,
      interne: true,
      externe: true,
    },
  )
  const {
    currentData: mailTypes = [],
    isFetching: isFetchingMailTypeList,
  } = useGetMailTypeListQuery(caseId)
  const {
    currentData: documents = [],
    isFetching: isFetchingDocuments,
  } = useGetCaseDocumentsQuery({ dossier: caseId })

  const isLoading = useMemo(() => isFetchingSenders || isFetchingMailTypeList || isFetchingRecipients || isFetchingDocuments,
    [
      isFetchingSenders,
      isFetchingMailTypeList,
      isFetchingRecipients,
      isFetchingDocuments,
    ])

  return (
    <>
      <HeaderAction
        title="Nouveau Mail"
        onSubmit={() => setTriggerSubmit(triggerSubmit + 1)}
      >
        <SubmitFormButton
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Envoyer
        </SubmitFormButton>
      </HeaderAction>
      <PageContainer>
        {isLoading && <Loader />}
        <MailForm
          senders={senders}
          isFetchingSenders={isFetchingSenders}
          recipients={recipients}
          mailTypes={mailTypes}
          documents={documents}
          triggerOnSubmit={triggerSubmit}
        />
      </PageContainer>
    </>
  )
}

export default QaMailPage
