/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
// Layouts
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import MainLayout from 'layouts/MainLayout/MainLayout'
import CaseLayout from 'layouts/CaseLayout/CaseLayout'
import TravelerLayout from 'layouts/TravelerLayout/TravelerLayout'
import TravelerAddLayout from 'layouts/TravelerAddLayout/TravelerAddLayout'
import ActionLayout from 'layouts/ActionLayout/ActionLayout'
// Planning
import PlanningPage from 'pages/PlanningPages/PlanningPage/PlanningPage'
import PlanningMapPage from 'pages/PlanningPages/PlanningMapPage/PlanningMapPage'
// Main
import CasesPage from 'pages/CasesPage/CasesPage'
import InternalExchangesPage from 'pages/InternalExchangesPage/InternalExchangesPage'
import NotificationsPage from 'pages/NotificationsPage/NotificationsPage'
import TasksPage from 'pages/TasksPage/TasksPage'
import AccountPage from 'pages/AccountPage/AccountPage'
import LoginPage from 'pages/LoginPage/LoginPage'
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'
// Quick Actions
import QaTaskPage from 'pages/QuickActionPages/QaTaskPage/QaTaskPage'
import QaCommentPage from 'pages/QuickActionPages/QaCommentPage/QaCommentPage'
import QaMailPage from 'pages/QuickActionPages/QaMailPage/QaMailPage'
import QaInternalExchangePage from 'pages/QuickActionPages/QaInternalExchangePage/QaInternalExchangePage'
import QaSinappsCommandPage from 'pages/QuickActionPages/QaSinappsCommandPage/QaSinappsCommandPage'
import QaSinappsVigilanceReportPage from 'pages/QuickActionPages/QaSinappsVigilanceReportPage/QaSinappsVigilanceReportPage'
import QaCourrierPage from 'pages/QuickActionPages/QaCourrierPage/QaCourrierPage'
// Traveler
import StakeholdersPage from 'pages/TravelerPages/StakeholdersPage/StakeholdersPage'
import AddStakeholderPage from 'pages/TravelerPages/StakeholdersPage/AddStakeholderPage/AddStakeholderPage'
import EditStakeholderPage from 'pages/TravelerPages/StakeholdersPage/EditStakeholderPage/EditStakeholderPage'
import RiskCompliancePage from 'pages/TravelerPages/RiskCompliancePage/RiskCompliancePage'
import DisasterPage from 'pages/TravelerPages/DisasterPage/DisasterPage'
import DamagesPage from 'pages/TravelerPages/DamagesPage/DamagesPage'
import AddDamagePage from 'pages/TravelerPages/DamagesPage/AddDamagePage/AddDamagePage'
import CompensationPage from 'pages/TravelerPages/CompensationPage/CompensationPage'
import RecoursePage from 'pages/TravelerPages/RecoursePage/RecoursePage'
import AddRecoursePage from 'pages/TravelerPages/RecoursePage/AddRecoursePage/AddRecoursePage'
import ConfidentialPage from 'pages/TravelerPages/ConfidentialPage/ConfidentialPage'
import PaymentPage from 'pages/TravelerPages/PaymentPage/PaymentPage'
import AddPaymentPage from 'pages/TravelerPages/PaymentPage/AddPaymentPage/AddPaymentPage'
import IrsiPage from 'pages/TravelerPages/IrsiPage/IrsiPage'
// Traveler Actions
import SinappsReportPage from 'pages/TravelerPages/ActionPages/SinappsReportPage/SinappsReportPage'
import DocumentProcessorPage from 'pages/TravelerPages/ActionPages/DocumentProcessorPage/DocumentProcessorPage'
import NotepadPage from 'pages/TravelerPages/ActionPages/NotepadPage/NotepadPage'
import CommunicationPage from 'pages/TravelerPages/ActionPages/CommunicationPage/CommunicationPage'
// Other
import MediaLibraryAnnotatePage from 'pages/MediaLibraryAnnotatePage/MediaLibraryAnnotatePage'

/* Component declaration ---------------------------------------------------- */
interface RouterProps {}

const Router: React.FC<RouterProps> = () => {
  const authInfo = useAuthInfo()

  console.log(`[DEBUG] <App> authInfo :`, authInfo)

  return (
    <Routes>
      <Route
        path="/"
        element={
          authInfo !== null ?
            <MainLayout /> :
            <Navigate
              to="/connexion"
              replace
            />
        }
      >
        <Route path="planning">
          <Route
            index
            element={<PlanningPage />}
          />
          <Route
            path="carte"
            element={<PlanningMapPage />}
          />
          <Route
            path="carte/:eventDate/:eventId"
            element={<PlanningMapPage />}
          />
        </Route>
        <Route path="mediatheque">
          <Route
            path=":mediaId"
            element={<MediaLibraryAnnotatePage />}
          />
        </Route>
        <Route path="dossiers">
          <Route
            index
            element={<CasesPage />}
          />
          <Route
            path=":caseId"
            element={<CaseLayout />}
          >
            <Route
              index
              element={
                <Navigate
                  to="traveller"
                  replace
                />
              }
            />
            <Route
              path="traveller"
              element={<TravelerLayout />}
            >
              <Route
                index
                element={
                  <Navigate
                    to="intervenants"
                    replace
                  />
                }
              />
              <Route
                path="intervenants"
                element={<StakeholdersPage />}
              />
              <Route
                path="conformite-du-risque"
                element={<RiskCompliancePage />}
              />
              <Route
                path="sinistre"
                element={<DisasterPage />}
              />
              <Route
                path="dommages"
                element={<DamagesPage />}
              />
              <Route
                path="indemnisation"
                element={<CompensationPage />}
              />
              <Route
                path="recours"
                element={<RecoursePage />}
              />
              <Route
                path="confidentiel"
                element={<ConfidentialPage />}
              />
              <Route
                path="reglement"
                element={<PaymentPage />}
              />
              <Route
                path="irsi"
                element={<IrsiPage />}
              />
            </Route>
            <Route
              path="traveller/nouveau"
              element={<TravelerAddLayout />}
            >
              <Route
                path="intervenant"
                element={<AddStakeholderPage />}
              />
              <Route
                path="dommage/:personId/:damageType"
                element={<AddDamagePage />}
              />
              <Route
                path="recours"
                element={<AddRecoursePage />}
              />
              <Route
                path="reglement"
                element={<AddPaymentPage />}
              />
            </Route>
            <Route
              path="traveller/modification"
              element={<TravelerAddLayout />}
            >
              <Route
                path="intervenants/:stakeholderId"
                element={<EditStakeholderPage />}
              />
              <Route
                path="dommage/:personId/:damageType/:damageId/:roomIndex"
                element={<AddDamagePage />}
              />
              <Route
                path="dommage/:personId/:damageType/:damageId"
                element={<AddDamagePage />}
              />
            </Route>
          </Route>
        </Route>
        <Route
          path="dossiers/:caseId/traveller/actions"
          element={<ActionLayout />}
        >
          <Route
            index
            element={
              <Navigate
                to="/dossiers"
                replace
              />
            }
          />
          <Route
            path="compte-rendu"
            element={<SinappsReportPage />}
          />
          <Route
            path="bloc-note"
            element={<NotepadPage />}
          />
          <Route
            path="document"
            element={<DocumentProcessorPage />}
          />
          <Route
            path="communication"
            element={<CommunicationPage />}
          />
        </Route>
        <Route path="actions-rapides">
          <Route
            index
            element={
              <Navigate
                to="/dossiers"
                replace
              />
            }
          />
          <Route
            path=":caseId"
            element={<ActionLayout />}
          >
            <Route
              path="tache"
              element={<QaTaskPage />}
            />
            <Route
              path="commentaire"
              element={<QaCommentPage />}
            />
            <Route
              path="mail"
              element={<QaMailPage />}
            />
            <Route
              path="echange-interne"
              element={<QaInternalExchangePage />}
            />
            <Route
              path="commande-sinapps"
              element={<QaSinappsCommandPage />}
            />
            <Route
              path="fiche-vigilance"
              element={<QaSinappsVigilanceReportPage />}
            />
            <Route
              path="courrier"
              element={<QaCourrierPage />}
            />
          </Route>
        </Route>
        <Route
          path="echanges-internes"
          element={<InternalExchangesPage />}
        />
        <Route
          path="notifications"
          element={<NotificationsPage />}
        />
        <Route
          path="taches"
          element={<TasksPage />}
        />
        <Route
          path="compte"
          element={<AccountPage />}
        />
        <Route
          index
          element={
            <Navigate
              to="/planning"
              replace
            />
          }
        />
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Route>
      <Route
        element={
          authInfo === null ?
            <AuthLayout /> :
            <Navigate
              to="/"
              replace
            />
        }
      >
        <Route
          path="/connexion"
          element={<LoginPage />}
        />
      </Route>
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </Routes>
  )
}

export default Router
