/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Component declaration ---------------------------------------------------- */
const CompensationTableBoldUppercaseTitle = styled(FormBoldTitle)`
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -5px;
  margin-bottom: -5px;
`

export default CompensationTableBoldUppercaseTitle
