/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'
import type { TravelerForm } from 'types/TravelerForm'
import type { RapportResume } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export interface CaseReportSyncState {
  withValidation: string | null;
  withoutValidation: string | null;
  edition: string | null;
}

export interface CaseRoute {
  name: string;
  route: () => void;
}

export interface TravelerReport {
  summary: RapportResume | null;
  sync: CaseReportSyncState;
  formikForm: TravelerForm | null;
  errorForm: TravelerForm | null;
}

export interface TravelerCase {
    isReadyForOffline: boolean;
    selectedReport: string;
    reports: {
      [reportId: string]: TravelerReport;
    };
    routes: CaseRoute[];
}

export interface TravelerState {
  [caseId: string]: TravelerCase;
}

const initCase = (state: TravelerState, caseId: string, reportId?: string) => {
  if (!state[caseId]) {
    state[caseId] = {
      isReadyForOffline: false,
      reports: {},
      selectedReport: '',
      routes: [],
    }
  }
  if (reportId && !state[caseId].reports[reportId]) {
    state[caseId].reports[reportId] = {
      formikForm: null,
      errorForm: null,
      summary: null,
      sync: {
        withoutValidation: null,
        withValidation: null,
        edition: null,
      },
    }
  }
}

/* Redux slice -------------------------------------------------------------- */
const travelerSlice = createSlice(
  {
    name: 'traveler',
    initialState: {} as TravelerState,
    reducers: {
      setCaseIsReadyForOffline: (state, { payload }: PayloadAction<{caseId: string; isReadForOffline: boolean}>) => {
        initCase(state, payload.caseId)
        state[payload.caseId].isReadyForOffline = payload.isReadForOffline
      },
      setCaseSelectedReport: (state, { payload }: PayloadAction<{caseId: string; selectedReport: string}>) => {
        initCase(state, payload.caseId)
        state[payload.caseId].selectedReport = payload.selectedReport
      },
      setCaseReportSummary: (state, { payload }: PayloadAction<{caseId: string; reportId: string; summary: RapportResume}>) => {
        initCase(state, payload.caseId, payload.reportId)
        state[payload.caseId].reports[payload.reportId].summary = payload.summary
      },
      setCaseReportSync: (state, { payload }: PayloadAction<{caseId: string; reportId: string; validation: boolean; sync: string}>) => {
        initCase(state, payload.caseId, payload.reportId)
        if (payload.validation) {
          state[payload.caseId].reports[payload.reportId].sync.withValidation = payload.sync
          state[payload.caseId].reports[payload.reportId].sync.withoutValidation = payload.sync
        } else {
          state[payload.caseId].reports[payload.reportId].sync.withoutValidation = payload.sync
        }
      },
      setCaseReportEdition: (state, { payload }: PayloadAction<{caseId: string; reportId: string; edition: string}>) => {
        initCase(state, payload.caseId, payload.reportId)
        state[payload.caseId].reports[payload.reportId].sync.edition = payload.edition
      },
      setCaseReportFormikForm: (state, { payload }: PayloadAction<{caseId: string; reportId: string; formikForm: TravelerForm}>) => {
        initCase(state, payload.caseId, payload.reportId)
        state[payload.caseId].reports[payload.reportId].formikForm = payload.formikForm
      },
      setCaseReportErrorForm: (state, { payload }: PayloadAction<{caseId: string; reportId: string; errorForm: TravelerForm}>) => {
        initCase(state, payload.caseId, payload.reportId)
        state[payload.caseId].reports[payload.reportId].errorForm = payload.errorForm
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const {
  setCaseIsReadyForOffline,
  setCaseSelectedReport,
  setCaseReportSummary,
  setCaseReportSync,
  setCaseReportEdition,
  setCaseReportErrorForm,
  setCaseReportFormikForm,
} = travelerSlice.actions

export default travelerSlice.reducer

export const getTravelerState = (state: RootState): TravelerState => {
  return state.traveler
}

export const getTravelerCaseReport = (caseId: string, reportId: string) => (state: RootState): TravelerReport => {
  return state.traveler[caseId]?.reports?.[reportId]
}

export const getIsReadyForOfflineList = (state: RootState): string[] => {
  return Object.entries(state.traveler).filter(([ , caseData ]) => caseData.isReadyForOffline).map(([ caseId ]) => caseId) || []
}

export const getCaseIsReadyForOffline = (caseId: string) => (state: RootState): boolean => {
  return state.traveler[caseId]?.isReadyForOffline
}

export const getCaseSelectedReport = (caseId: string) => (state: RootState): string | null => {
  return state.traveler[caseId]?.selectedReport
}

export const getCaseReportSync = (caseId: string, reportId: string) => (state: RootState): CaseReportSyncState | null => {
  return state.traveler[caseId]?.reports[reportId]?.sync || null
}

export const getCaseReportSummaryList = (caseId: string) => (state: RootState): RapportResume[] => {
  const caseData = state.traveler[caseId]

  if (!caseData) {
    return []
  }

  return Object.values(caseData.reports).map((report) => report.summary).filter((summary) => summary !== null) as RapportResume[]
}
