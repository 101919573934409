/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import CaseSidebarItemComponents from 'layouts/CaseSidebar/CaseSidebarComponents/CaseSidebarItemComponents/CaseSidebarItemComponents'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarPostItProps {
  isFetching: boolean;
  postIt?: string | null;
}

const CaseSidebarPostIt: React.FC<CaseSidebarPostItProps> = ({ isFetching, postIt }) => {
  return (
    <>
      <FormBoldTitle>
        Post-it
      </FormBoldTitle>
      {
        isFetching ?
          <CircularProgress /> :
          isValidString(postIt) ?
            <CaseSidebarItemComponents.Card>
              {postIt}
            </CaseSidebarItemComponents.Card> :
            <>
              Aucun post-it à afficher
            </>
      }
    </>
  )
}

export default CaseSidebarPostIt
