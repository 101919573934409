/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
} from 'react'
import L from 'leaflet'

/* Module imports ----------------------------------------------------------- */
import { renderToString } from 'react-dom/server'
import { getCoordinates } from 'helpers/mapHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Marker,
  Popup,
} from 'react-leaflet'
import PlanningTooltip from 'pages/PlanningPages/PlanningComponents/PlanningTooltip'

/* Asset imports ------------------------------------------------------------ */
import pinIcon from 'assets/map_pin_icon.svg'

/* Type imports ------------------------------------------------------------- */
import type {
  Map,
  Marker as LMarker,
} from 'leaflet'
import type { Planning } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface PlanningMapMarkerProps {
  event: Planning;
  mapRef: Map | null;
  selectedEvent: string;
  setSelectedEvent: (event: Planning) => void;
  eventNumber: number;
}

const PlanningMapMarker: React.FC<PlanningMapMarkerProps> = ({
  event,
  mapRef,
  selectedEvent,
  setSelectedEvent,
  eventNumber,
}) => {
  const markerRef = useRef<LMarker | null>(null)

  const CustomMarker = () => {
    return (
      <div className="pin-container">
        <b>
          {eventNumber}
        </b>
        <img
          src={pinIcon}
          alt="Map marker"
        />
      </div>
    )
  }

  const CustomDivIcon = L.divIcon({
    html: renderToString(<CustomMarker />),
    iconSize: [ 40, 60 ],
    iconAnchor: [ 0, 0 ],
    popupAnchor: [ 0, -45 ],
  })

  useEffect(() => {
    if (selectedEvent === event?.id) {
      mapRef?.flyTo(getCoordinates(event.coordonnees))
      markerRef?.current?.openPopup()
    }
  }, [
    selectedEvent,
    event,
    mapRef,
  ])

  return (
    <Marker
      position={getCoordinates(event.coordonnees)}
      ref={markerRef}
      icon={CustomDivIcon}
      eventHandlers={{ click: () => setSelectedEvent(event) }}
    >
      <Popup>
        <PlanningTooltip event={event} />
      </Popup>
    </Marker>
  )
}

export default PlanningMapMarker
