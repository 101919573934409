/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { enumToSegmentedButtonOptions } from 'helpers/enumToSegmentedButtonOptions'
import {
  useGetEventDocumentsFamilleActionListQuery,
  usePostTaskMutation,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import {
  DestinataireTache,
  TypeEvenementiel,
  type TacheRapideRequest,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface NewTask {
  exit: boolean;
}

type TaskRequest = TacheRapideRequest & NewTask

const taskSchema = Yup.object().shape<Shape<TaskRequest>>({
  codeAction: Yup.number().min(1, 'Le type de tâche est obligatoire').required('Le type de tâche est obligatoire'),
  libelle: Yup.string().required('Le libellé est obligatoire'),
  commentaire: Yup.string().required('Le commentaire est obligatoire'),
  dateEcheance: Yup.string().nullable().required("L'échéance est obligatoire"),
  destinataire: Yup.mixed<DestinataireTache>().required(),
})

type TaskForm = FormikContextType<TaskRequest>

/* Styled components -------------------------------------------------------- */
const Space = styled.div`
  margin-bottom: 40px;
`

/* Component declaration ---------------------------------------------------- */
interface QaTaskPageProps {}

const QaTaskPage: React.FC<QaTaskPageProps> = () => {
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()

  const {
    currentData: familleActionList = [],
    isFetching: isFetchingFamilleActionList,
  } = useGetEventDocumentsFamilleActionListQuery({ dossier: caseId, filtreEvenementiel: TypeEvenementiel.Dossier })
  const [
    submitNewTask,
  ] = usePostTaskMutation()

  const onSubmit = async (values: TaskRequest, { setSubmitting, resetForm }: FormikHelpers<TaskRequest>): Promise<void> => {
    const response = await submitNewTask({ query: { dossier: caseId }, data: values })

    if (!isApiError(response)) {
      resetForm()
      toast.success('La tâche à bien été créée.')
      if (values.exit) {
        navigate(-1)
      }
    } else {
      toast.error("Une erreur est survenue lors de l'envoi du commentaire.")
      setSubmitting(false)
    }
  }

  const formikForm: TaskForm = useForm<TaskRequest>(
    {
      initialValues: {
        codeAction: 0,
        libelle: '',
        commentaire: '',
        dateEcheance: new Date().toISOString(),
        destinataire: DestinataireTache.Moi,
        exit: false,
      },
      onSubmit: onSubmit,
      validationSchema: taskSchema,
    },
  )

  const handleValue = (type: string, value?: string | boolean | string[]| Date | null | number): void => {
    formikForm.setFieldValue(type, value)
  }

  const recipientOptions: SegmentedButtonOption<string>[] = enumToSegmentedButtonOptions(DestinataireTache)

  const actionList = useMemo(() => familleActionList.filter((famille) => famille.code === 'TA')[0]?.actions?.filter((action) => action.parametrage.datable) || [], [ familleActionList ])

  return (
    <>
      <HeaderAction
        title="Nouvelle tâche"
        onSubmit={formikForm.handleSubmit}
      >
        <SubmitFormButton
          type="submit"
          variant="outlined"
          disabled={formikForm.isSubmitting}
          onClick={() => handleValue('exit', true)}
        >
          Valider et terminer
        </SubmitFormButton>
        <SubmitFormButton
          type="submit"
          variant="contained"
          disabled={formikForm.isSubmitting}
        >
          Valider et ajouter nouveau
        </SubmitFormButton>
      </HeaderAction>
      <PageContainer>
        {(formikForm.isSubmitting || isFetchingFamilleActionList) && <Loader />}
        <Form form={formikForm}>
          <FormBoldTitle required>
            Type de tâche
          </FormBoldTitle>
          <Field
            component={Select}
            name="codeAction"
            displayEmpty
            disabled={isFetchingFamilleActionList}
            renderValue={verifySelectFieldValue(formikForm.values.codeAction.toString())}
          >
            <MenuItem value={0}>
              Sélectionner
            </MenuItem>
            {
              actionList.map((value, index) => (
                <MenuItem
                  value={value.code}
                  key={`${value.code}-${index}`}
                >
                  {value.libelle}
                </MenuItem>
              ))
            }
          </Field>
          <FormBoldTitle required>
            Libellé
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Nom tâche"
            name="libelle"
          />
          <FormBoldTitle required>
            Commentaire
          </FormBoldTitle>
          <Field
            component={TextField}
            rows={8}
            multiline
            name="commentaire"
            placeholder="Votre message"
          />
          <FormBoldTitle required>
            Échéance
          </FormBoldTitle>
          <FormikDatePicker name="dateEcheance" />
          <FormBoldTitle required>
            Destinataire
          </FormBoldTitle>
          <SegmentedButtons
            options={recipientOptions}
            selectedOption={formikForm.values.destinataire}
            setSelectedOption={(newVal) => handleValue('destinataire', newVal)}
          />
          <Space />
        </Form>
      </PageContainer>
    </>
  )
}

export default QaTaskPage
