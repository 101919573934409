/* Framework imports -------------------------------------------------------- */
import React from 'react'
import * as Yup from 'yup'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCommentActionQuery,
  usePostNewCommentMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'

/* Type declarations -------------------------------------------------------- */
const commentSchema = Yup.object({
  libelle: Yup.string().required('Le libellé est obligatoire'),
  action: Yup.string().required('Le type de tâche est obligatoire'),
  commentaire: Yup.string().required('Le commentaire est obligatoire'),
  exit: Yup.boolean(),
})

type CommentRequest = Yup.InferType<typeof commentSchema>

/* Styled components -------------------------------------------------------- */
const FormContainer = styled.div`
  padding-bottom: 40px;
`

/* Component declaration ---------------------------------------------------- */
interface QaCommentPageProps {}

const QaCommentPage: React.FC<QaCommentPageProps> = () => {
  const { caseId = '' } = useParams<{caseId: string}>()
  const navigate = useNavigate()

  const {
    currentData: commentActionList = [],
    isFetching: isFetchingCommentActionList,
  } = useGetCommentActionQuery()
  const [
    submitNewComment,
  ] = usePostNewCommentMutation()

  const onSubmit = async (values: CommentRequest, { setSubmitting, resetForm }: FormikHelpers<CommentRequest>): Promise<void> => {
    const response = await submitNewComment({
      caseId,
      data: values,
    })

    if (!isApiError(response)) {
      resetForm()
      if (values.exit) {
        navigate(-1)
      }
    } else {
      toast.error("Une erreur est survenue lors de l'envoi du commentaire.")
      setSubmitting(false)
    }
  }

  const formikForm = useForm<CommentRequest>(
    {
      initialValues: {
        action: '',
        libelle: '',
        commentaire: '',
        exit: false,
      },
      onSubmit: onSubmit,
      validationSchema: commentSchema,
    },
  )

  const handleValue = (type: string, value?: string | boolean): void => {
    formikForm.setFieldValue(type, value).catch(console.error)
  }

  return (
    <>
      <HeaderAction
        title="Nouveau commentaire"
        onSubmit={formikForm.handleSubmit}
      >
        <SubmitFormButton
          type="submit"
          variant="outlined"
          disabled={formikForm.isSubmitting || isFetchingCommentActionList}
          onClick={() => handleValue('exit', true)}
        >
          Valider et terminer
        </SubmitFormButton>
        <SubmitFormButton
          type="submit"
          variant="contained"
          disabled={formikForm.isSubmitting || isFetchingCommentActionList}
        >
          Valider et ajouter nouveau
        </SubmitFormButton>
      </HeaderAction>
      <PageContainer>
        {(formikForm.isSubmitting || isFetchingCommentActionList) && <Loader />}
        <FormContainer>
          <Form form={formikForm}>
            <FormBoldTitle required>
              Type de tâche
            </FormBoldTitle>
            <Field
              component={Select}
              name="action"
              displayEmpty
              disabled={isFetchingCommentActionList}
              renderValue={verifySelectFieldValue(formikForm.values.action)}
            >
              <MenuItem value={0}>
                Sélectionner
              </MenuItem>
              {
                commentActionList.map((value, index) => (
                  <MenuItem
                    value={value.code}
                    key={`${value.code}-${index}`}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
            <FormBoldTitle required>
              Libellé
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Libellé"
              name="libelle"
            />
            <FormBoldTitle required>
              Commentaire
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Votre message"
              rows={8}
              multiline
              name="commentaire"
            />
          </Form>
        </FormContainer>
      </PageContainer>
    </>
  )
}

export default QaCommentPage
