/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { ButtonProps } from '@mui/material'

/* Component declaration ---------------------------------------------------- */
const BlueRoundedChip = styled(Button)<ButtonProps>`
  font-size: 14px;
  font-weight: normal;

  border: ${(props) => props.variant === 'contained' ? 'none' : '1px solid'} !important;
  background-color: ${(props) => props.variant === 'outlined' ? 'white !important' : ''};
  border-radius: 1rem;
  width: 120px;
`

export default BlueRoundedChip
