/* Module imports ----------------------------------------------------------- */
import { v4 as uuid } from 'uuid'
import { roundNumber } from './calculs'

/* Type imports ------------------------------------------------------------- */
import type {
  CodeLabel,
  Dommage,
  Indemnisation,
  IndemnisationSinapps,
} from '../types/DommageIndemnisation'

/* Function declarations ---------------------------------------------------- */
export const createSinappsGUID = (): string => {
  return uuid().replaceAll('-', '')
}

export const getGarantie = (dommage: Dommage, indemnisations: Indemnisation[]): CodeLabel | undefined => {
  const indemnisation = indemnisations.find((indemn) =>
    (indemn.natureDuBien?.code === dommage.natureDuBien?.code || indemn.natureDuBien?.code === dommage.sinapps.natureDuBienSinapps.code) &&
    (indemn.detailDuBien?.code === dommage.detailDuBien?.code || indemn.detailDuBien?.code === dommage.sinapps.detailDuBienSinapps?.code) &&
    indemn.typeDeReparation.code === dommage.typeDeReparation.code,
  )

  if (!indemnisation) return
  return indemnisation.garantie
}

export const roundIndemnisations = (indemnisationSinapps: IndemnisationSinapps): IndemnisationSinapps => {
  Object.keys(indemnisationSinapps).forEach((key) => {
    const value = indemnisationSinapps[key as keyof IndemnisationSinapps]

    if (typeof value === 'number' && value !== null) {
      (indemnisationSinapps[key as keyof IndemnisationSinapps] as number) = roundNumber(value)
    }
  })
  return indemnisationSinapps
}
