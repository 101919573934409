/* Module imports ----------------------------------------------------------- */
import { createDamageUuid } from 'helpers/uuidUtils'
import { calculIndemnisation } from 'helpers/calculs-montants-dommages-indemnisations/src/calculIndemnisation'
import { getListeDommagesSinapps } from 'helpers/calculs-montants-dommages-indemnisations/src/getListeDommagesSinapps'
import { getListeBiensSinapps } from 'helpers/calculs-montants-dommages-indemnisations/src/getListeBiensSinapps'
import { calculIndemnisationSinapps } from 'helpers/calculs-montants-dommages-indemnisations/src/calculIndemnisationSinapps'

/* Type imports ------------------------------------------------------------- */
import type {
  Dommage as ApiDommage,
  Indemnisation as ApiIndemnisation,
  DommageSinapps as ApiDommageSinapps,
  DommageBienSinapps as ApiDommageBienSinapps,
  IndemnisationSinapps as ApiIndemnisationSinapps,
  IndemnisationTraveller,
} from 'API/__generated__/Api'
import {
  ReparabiliteMob,
  TypeDeReparation,
} from 'API/__generated__/Api'
import type {
  Dommage as CalculDommage,
  Indemnisation as CalculIndemnisation,
  DommageSinapps as CalculDommageSinapps,
  DommageBienSinapps as CalculDommageBienSinapps,
  IndemnisationSinapps as CalculIndemnisationSinapps,
} from 'helpers/calculs-montants-dommages-indemnisations/src/types/DommageIndemnisation'

/* CompensationService methods ---------------------------------------------- */
const toCalculDamages = (damages: ApiDommage[]): CalculDommage[] => {
  return damages.map((damage) => ({
    ...damage,
    valeur: {
      ...damage.valeur,
      uniteMesure: { code: damage.valeur.uniteMesure?.code || '' },
      montantHT: damage.valeur.montantHT || 0,
      montantTTC: damage.valeur.montantTTC || 0,
      tauxTva: { ...damage.valeur.tauxTva, taux: damage.valeur.tauxTva?.taux || 0 },
    },
    vetuste: {
      ...damage.vetuste,
      montantVetuste: damage.vetuste.montantVetuste || 0,
      montantVetusteRecuperable: damage.vetuste.montantVetusteRecuperable || 0,
      montantVetusteDeduite: damage.vetuste.montantVetusteDeduite || 0,
      reparation: { ...damage.vetuste.reparation, reparabilite: { ...damage.vetuste.reparation?.reparabilite, code: damage.vetuste.reparation?.reparabilite?.code || ReparabiliteMob.Reparable }},
    },
    sinapps: {
      ...damage.sinapps,
      natureDuBienSinapps: { ...damage.sinapps?.natureDuBienSinapps, code: damage.sinapps?.natureDuBienSinapps?.code || '' },
      detailDuBienSinapps: { ...damage.sinapps?.detailDuBienSinapps, code: damage.sinapps?.detailDuBienSinapps?.code || '' },
      idDetailDommageSinapps: damage.sinapps?.idDetailDommageSinapps || damage.id,
      idDommageSinapps: damage.sinapps?.idDommageSinapps || damage.id,
    },
    natureDuBien: { ...damage.natureDuBien, code: damage.natureDuBien?.code || '' },
    detailDuBien: damage.detailDuBien ? { ...damage.detailDuBien, code: damage.detailDuBien?.code || '' } : undefined,
    typeDeReparation: { ...damage.typeDeReparation, code: damage.typeDeReparation?.code || TypeDeReparation.AR },
  }))
}

const toCalculCompensation = (compensations: ApiIndemnisation[]): CalculIndemnisation[] => {
  return compensations.map((compensation) => ({
    ...compensation,
    typeDeReparation: { ...compensation.typeDeReparation, code: compensation.typeDeReparation?.code || TypeDeReparation.AR },
    montantHT: compensation.montantHT || 0,
    montantTTC: compensation.montantTTC || 0,
    montantVetusteDeduiteTTC: compensation.montantVetusteDeduiteTTC || 0,
    montantVetusteRecuperable: compensation.montantVetusteRecuperable || 0,
    montantDecouvert: compensation.montantDecouvert || 0,
  }))
}

const toCalculSinappsDamage = (damages: ApiDommageSinapps[]): CalculDommageSinapps[] => {
  return damages.map((damage) => ({
    ...damage,
    montantVetusteHT: damage.montantVetusteHT || 0,
    montantVetusteTTC: damage.montantVetusteTTC || 0,
    montantVetusteDeduiteHT: damage.montantVetusteDeduiteHT || 0,
    montantVetusteDeduiteTTC: damage.montantVetusteDeduiteTTC || 0,
    montantVetusteRecuperableHT: damage.montantVetusteRecuperableHT || 0,
    montantVetusteRecuperableTTC: damage.montantVetusteRecuperableTTC || 0,
  }))
}

const toCalculSinappsPropertyDamage = (damages: ApiDommageBienSinapps[]): CalculDommageBienSinapps[] => {
  return damages.map((damage) => ({
    ...damage,
    montantHT: damage.montantHT || 0,
    montantTTC: damage.montantTTC || 0,
    montantVetusteHT: damage.montantVetusteHT || 0,
    montantVetusteTTC: damage.montantVetusteTTC || 0,
    montantVetusteRecuperableHT: damage.montantVetusteRecuperableHT || 0,
    montantVetusteRecuperableTTC: damage.montantVetusteRecuperableTTC || 0,
    reparabilite: { ...damage.reparabilite, code: damage.reparabilite?.code || ReparabiliteMob.Reparable },
    natureDuBienSinapps: { ...damage.natureDuBienSinapps, code: damage.natureDuBienSinapps?.code || '' },
  }))
}

const toCalculSinappsCompensation = (compensations: ApiIndemnisationSinapps[]): CalculIndemnisationSinapps[] => {
  return compensations.map((compensation) => ({
    ...compensation,
    idGarantie: compensation.garantie?.code || '',
    montantIndemniteImmediateHTAvant: compensation.montantIndemniteImmediateHTAvant || 0,
    montantIndemniteImmediateTTCAvant: compensation.montantIndemniteImmediateTTCAvant || 0,
    montantIndemniteImmediateHTApres: compensation.montantIndemniteImmediateHTApres || 0,
    montantIndemniteImmediateTTCApres: compensation.montantIndemniteImmediateTTCApres || 0,
    montantIndemniteDiffereeHTAvant: compensation.montantIndemniteDiffereeHTAvant || 0,
    montantIndemniteDiffereeTTCAvant: compensation.montantIndemniteDiffereeTTCAvant || 0,
    montantIndemniteDiffereeHTApres: compensation.montantIndemniteDiffereeHTApres || 0,
    montantIndemniteDiffereeTTCApres: compensation.montantIndemniteDiffereeTTCApres || 0,
    montantFranchiseImmediateTTC: compensation.montantFranchiseImmediateTTC || 0,
    montantFranchiseDiffereeHT: compensation.montantFranchiseDiffereeHT || 0,
    montantFranchiseDiffereeTTC: compensation.montantFranchiseDiffereeTTC || 0,
  }))
}

const toApiCompensation = (compensations: CalculIndemnisation[]): ApiIndemnisation[] => {
  return compensations.map((compensation) => ({
    ...compensation,
    id: createDamageUuid(),
  }))
}

const toApiSinappsDamages = (sinappsDamages: CalculDommageSinapps[]): ApiDommageSinapps[] => {
  return sinappsDamages.map((sinappsDamage: CalculDommageSinapps) => ({
    ...sinappsDamage,
    id: sinappsDamage.idDommageSinapps || '',
  }))
}

const toApiSinappsPropertyDamage = (sinappsDamages: CalculDommageBienSinapps[]): ApiDommageBienSinapps[] => {
  return sinappsDamages.map((sinappsDamage: CalculDommageBienSinapps) => ({
    ...sinappsDamage,
    id: sinappsDamage.idDommageSinapps || '',
  }))
}

interface CalculateAllCompensationProps {
  damages: ApiDommage[];
  isSinapps: boolean;
  oldCompensation?: IndemnisationTraveller;
}

export const calculateAllCompensations = ({ damages, isSinapps, oldCompensation }: CalculateAllCompensationProps): IndemnisationTraveller => {
  const newDamages: CalculDommage[] = toCalculDamages(damages)
  const compensations: CalculIndemnisation[] = calculIndemnisation(newDamages, toCalculCompensation(oldCompensation?.indemnisations || []))

  if (!isSinapps) {
    return ({
      ...oldCompensation,
      indemnisations: toApiCompensation(compensations),
    })
  }

  const sinappsDamages: CalculDommageSinapps[] = getListeDommagesSinapps(newDamages, compensations, toCalculSinappsDamage(oldCompensation?.dommagesSinapps || []))
  const sinappsPropertyDamage: CalculDommageBienSinapps[] = getListeBiensSinapps(newDamages, compensations, toCalculSinappsPropertyDamage(oldCompensation?.dommagesBienSinapps || []))
  const sinappsCompensations: CalculIndemnisationSinapps[] = calculIndemnisationSinapps(sinappsDamages, sinappsPropertyDamage, toCalculSinappsCompensation(oldCompensation?.indemnisationsSinapps || []))

  return ({
    ...oldCompensation,
    indemnisations: toApiCompensation(compensations),
    dommagesSinapps: toApiSinappsDamages(sinappsDamages),
    dommagesBienSinapps: toApiSinappsPropertyDamage(sinappsPropertyDamage),
    indemnisationsSinapps: sinappsCompensations,
  })
}
