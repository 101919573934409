/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Collapse,
} from '@mui/material'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import CheckableButton from 'components/CheckableButton/CheckableButton'

/* Type imports ------------------------------------------------------------- */
import type { CodeLabel } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 1fr;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const FlexCheckableButton = styled(CheckableButton)`
  height: 100%;

  label {
    padding: 5px 2px 5px 0px;
  }

  span {
    font-size: .95rem;
  }
`

const CardContainer = styled(Card)`
  margin: 10px 0px;
  padding: 0px 10px 3px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px !important;
`

const Title = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  margin: 5px 0px;
  cursor: pointer;
`

/* Component declaration ---------------------------------------------------- */
interface QaSinappsVigilanceReportCardProps {
  title: string;
  amountChecked: number;
  selectList: CodeLabel[];
  checkedList: string[];
  handleChecked: (value: string, checked: boolean) => void;
}

const QaSinappsVigilanceReportCard: React.FC<QaSinappsVigilanceReportCardProps> = ({
  title,
  amountChecked,
  selectList,
  checkedList,
  handleChecked,
}) => {
  const [ open, setOpen ] = useState<boolean>(false)

  return (
    <CardContainer>
      <Title onClick={() => setOpen(!open)}>
        {`${title} - ${amountChecked} coché(s)`}
        <DropDownArrow open={open} />
      </Title>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {
            selectList.map((value, index) => (
              <FlexCheckableButton
                key={`${value.code}-${index}`}
                checked={checkedList.some((list) => list === value.code)}
                onChange={(e, c) => handleChecked(value.code, c)}
                label={value.libelle}
              />
            ))
          }
        </GridContainer>
      </Collapse>
    </CardContainer>
  )
}

export default QaSinappsVigilanceReportCard
