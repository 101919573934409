/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'

/* Type declarations -------------------------------------------------------- */
type ProtoEnum<KeyType extends string, ValueType extends string | number> = {
  [key in KeyType]: ValueType;
}

/* enumToSegmentedButtonOptions helper function ----------------------------- */
export const enumToSegmentedButtonOptions = <KeyType extends string, ValueType extends string | number>
  (inputEnum: ProtoEnum<KeyType, ValueType>): SegmentedButtonOption<string>[] => {
  return Object.entries(inputEnum)
    .filter(([ key ]) => isNaN(Number(key)))
    .map(([ value, label ]): SegmentedButtonOption<string> => ({
      value: label as string || value,
      label: label as string || value,
    }))
}
