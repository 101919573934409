/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useQuickActionModal } from 'helpers/hooks/useQuickActionModal'

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import QuickActionsButtons from './QuickActionsButtons'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  align-self: center;
  margin-top: 20px;
  text-transform: uppercase;
`

const DialogContentContainer = styled(DialogContent)`
  margin-bottom: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface QuickActionsModalProps {}

const QuickActionsModal: React.FC<QuickActionsModalProps> = () => {
  const modal = useQuickActionModal()

  if (modal.state.caseId === null || modal.state.caseId === undefined) {
    return null
  }

  const handleClose = (): void => {
    modal.closeQuickActions()
  }

  return (
    <Dialog
      open={modal.state.quickActionsOpen}
      onClose={(): { payload: undefined; type: string } => modal.closeQuickActions()}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitleContainer>
        Actions rapides
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        <QuickActionsButtons caseId={modal.state.caseId} />
      </DialogContentContainer>
    </Dialog>
  )
}

export default QuickActionsModal
