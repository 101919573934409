/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component declaration ---------------------------------------------------- */
const HeaderTitleContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;

  display: flex;
  align-items: center;
  font-size: 1.5rem;
  text-transform: uppercase;
`

export default HeaderTitleContainer
