/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getAddress } from 'helpers/getAddress'

/* Type imports ------------------------------------------------------------- */
import type { Planning } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const TooltipContainer = styled.div`
  font-size: .85rem;
`

const BoldText = styled.div`
  font-weight: bold;
  display: inline;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningTooltipProps {
  event: Planning;
}

const PlanningTooltip: React.FC<PlanningTooltipProps> = ({ event }) => {

  return (
    <TooltipContainer>
      <div>
        <BoldText>
          {DateUtils.APIStrToLocalTimeString(event.dateDebut, { hour: '2-digit', minute: '2-digit' })}
          {' - '}
          {DateUtils.APIStrToLocalTimeString(event.dateFin, { hour: '2-digit', minute: '2-digit' })}
          {' : '}
        </BoldText>
        {event.typeRDV.libelle}
      </div>
      {
        event.refDossier?.refComplete &&
          <React.Fragment>
            <div>
              <BoldText>
                {'Dossier : '}
              </BoldText>
              {event.refDossier?.refComplete}
            </div>
            <div>
              <BoldText>
                {'Référence sinistre : '}
              </BoldText>
              {event.referenceSinistre}
            </div>
            <div>
              <BoldText>
                {'Nature sinistre : '}
              </BoldText>
              {event.natureSinistre?.libelle}
            </div>
            <div>
              {' '}
              <BoldText>
                {'Personnes conviées : '}
              </BoldText>
              {
                event.conviees?.map((person) => (
                  <div key={person.id}>
                    {person.nom}
                    <br />
                  </div>
                ))
              }
            </div>
            <div>
              <BoldText>
                {'Lieu de rendez-vous : '}
              </BoldText>
              <br />
              {getAddress(event.adresse)}
            </div>
          </React.Fragment>
      }
      <div>
        <BoldText>
          {'Commentaire : '}
        </BoldText>
        <br />
        {event.commentaire}
      </div>
    </TooltipContainer>
  )
}

export default PlanningTooltip
