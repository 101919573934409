/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component declaration ---------------------------------------------------- */
const BaseLayout = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;

  & > div,
  & > header {
    padding: 0 1rem;
    @media screen and (max-width: 768px) {
      padding: 0 0.5rem;
    }
  }
`

export default BaseLayout
