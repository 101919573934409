/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import CaseItem from 'components/CasesList/CaseItem/CaseItem'
import CaseSearcherItem from 'components/CaseSearcher/CaseSearcherItem'

/* Type imports ------------------------------------------------------------- */
import type {
  DossierRecherche,
  Pastilles,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CaseListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`

/* Component declaration ---------------------------------------------------- */
interface CasesListProps {
  cases: DossierRecherche[];
  casesChips: Pastilles[];
  isFetchingChips: boolean;
  loading?: boolean;
  searcher?: boolean;
}

const CasesList: React.FC<CasesListProps> = ({
  cases = [],
  loading = false,
  searcher = false,
  casesChips = [],
  isFetchingChips,
}) => {

  return (
    <CaseListContainer>
      {
        cases.length > 0 ?
          cases.map((pCase: DossierRecherche, pIndex: number) => {
            return searcher ?
              <CaseSearcherItem
                key={`${pCase.id}-${pIndex}`}
                caseItem={pCase}
                disabled={loading}
                caseChips={casesChips.find((chips) => chips.dossier === pCase.id)?.listePastilles}
                isFetchingChip={isFetchingChips}
              /> :
              <CaseItem
                key={`${pCase.id}-${pIndex}`}
                caseItem={pCase}
                disabled={loading}
                caseChips={casesChips.find((chips) => chips.dossier === pCase.id)?.listePastilles}
                isFetchingChip={isFetchingChips}
              />
          },
          ) :
          'Aucun dossier à afficher...'
      }
    </CaseListContainer>
  )
}

export default CasesList
