/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Tooltip } from '@mui/material'
import PlanningTooltip from 'pages/PlanningPages/PlanningComponents/PlanningTooltip'
import PlanningCalendarEventDayView from './PlanningCalendarEventView/PlanningCalendarEventDayView'
import PlanningCalendarEventWeekView from './PlanningCalendarEventView/PlanningCalendarEventWeekView'
import PlanningCalendarEventMonthView from './PlanningCalendarEventView/PlanningCalendarEventMonthView'

/* Type imports ------------------------------------------------------------- */
import type { EventContentArg } from '@fullcalendar/core'
import { CalendarViewTypes } from 'helpers/FullCalendarOptions'
import type { Planning } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const Container = styled.div`
  height: 100%;
  width: 100%;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarEventRenderProps {
  viewType: CalendarViewTypes;
  event?: Planning;
  eventInfo: EventContentArg;
}

const PlanningCalendarEventRender: React.FC<PlanningCalendarEventRenderProps> = ({
  viewType,
  event,
  eventInfo,
}) => {

  const renderEventContent = (eventInfo: EventContentArg): React.ReactElement | undefined => {
    switch (viewType) {
      case CalendarViewTypes.Day:
        // !event means its holidays
        // Different display for holidays on day view
        if (!event) return (
          <PlanningCalendarEventWeekView
            eventInfo={eventInfo}
            event={event}
          />
        )
        return <PlanningCalendarEventDayView event={event} />
      case CalendarViewTypes.Week:
        return (
          <PlanningCalendarEventWeekView
            eventInfo={eventInfo}
            event={event}
          />
        )
      case CalendarViewTypes.Month:
        return (
          <PlanningCalendarEventMonthView
            event={event}
            eventInfo={eventInfo}
          />
        )
      default:
        return <div />
    }
  }

  return (
    <Tooltip
      title={event && <PlanningTooltip event={event} />}
      arrow
    >
      <Container>
        {renderEventContent(eventInfo)}
      </Container>
    </Tooltip>
  )
}

export default PlanningCalendarEventRender
