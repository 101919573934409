/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component declaration ---------------------------------------------------- */
const DrawerContent = styled.div`
  margin-top: 10px;
  padding: 15px 20px;
  background-color: #F6F8FD;
  min-height: calc(100vh - 170px);
`

export default DrawerContent
