/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { getAddress } from 'helpers/getAddress'
import { isValidString } from 'helpers/isValidString'
import { hasCoordinates } from 'helpers/mapHelpers'

/* Component imports -------------------------------------------------------- */
import {
  FmdGoodRounded,
  PhoneRounded,
} from '@mui/icons-material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import DownloadOfflineCaseButton from 'components/IconButtons/DownloadOfflineCaseButton/DownloadOfflineCaseButton'
import OpenSidebarButton from 'components/IconButtons/OpenSidebarButton/OpenSidebarButton'
import GoToCasePageButton from 'components/IconButtons/GoToCasePageButton/GoToCasePageButton'
import OpenQuickActionsButton from 'components/IconButtons/OpenQuickActionsButton/OpenQuickActionsButton'

/* Type imports ------------------------------------------------------------- */
import type { Planning } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface EventCardProps {
  eventcolor: string;
}

const EventCard = styled.div<EventCardProps>`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.eventcolor};
  color: ${(props) => props.theme.palette.secondary.main};
  overflow-y: auto;
  overflow-x: hidden;
  font-size: .85rem;
`

const CardContentContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns:  7fr 3fr;
  gap: 5px;
  align-items: center;
  justify-content: stretch;
  align-items: flex-start;
  padding: 5px;
`

const Name = styled.div`
  font-weight: bold;
`

const CaseContainer = styled.div`
  justify-self: end;
  text-align: end;
  margin-right: 5px;
`

const CaseButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: -15px;
  margin-bottom: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarEventDayViewProps {
  event: Planning;
}

const PlanningCalendarEventDayView: React.FC<PlanningCalendarEventDayViewProps> = ({ event }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const onLocationClick = () => {
    if (location.pathname.indexOf('/carte') === -1) {
      navigate(`/planning/carte/${event.dateDebut}/${event.id}`)
    }
  }

  return (
    <EventCard
      id={`${event.commentaire}#${event.typeRDV?.libelle}`}
      eventcolor={event.typeRDV?.color}
    >
      <CardContentContainer>
        <div>
          <Name>
            {event.assure || event.typeRDV.libelle}
          </Name>
          <div>
            {event.natureSinistre?.libelle}
          </div>
          <div>
            {getAddress(event.adresse)}
          </div>
          <div>
            <div>
              {isValidString(event.adresse?.digicode) && `Digicode: ${event.adresse?.digicode}`}
            </div>
            <div>
              {event.commentaire}
            </div>
          </div>
        </div>
        {
          isValidString(event.refDossier?.refComplete) &&
            <CaseContainer>
              <Name>
                Dossier
                {' '}
                {event.refDossier?.refComplete}
              </Name>
              {`${event.compagnie?.libelle} - ${event.origine?.libelle}`}
              <CaseButtonsContainer>
                {
                  hasCoordinates(event.coordonnees) &&
                    <CustomIconButton
                      Icon={FmdGoodRounded}
                      onClick={onLocationClick}
                      variant="outlined"
                      color="secondary"
                      label="Afficher sur la carte"
                    />
                }
                {
                  isValidString(event.tel) &&
                    <a
                      href={`tel:${event.tel}`}
                      target="_self"
                    >
                      <CustomIconButton
                        Icon={PhoneRounded}
                        variant="outlined"
                        color="secondary"
                        label="Appeler"
                      />
                    </a>
                }
                {
                  event.refDossier?.refComplete && (
                    <>
                      <DownloadOfflineCaseButton
                        caseId={event.refDossier?.refComplete}
                        variant="outlined"
                        color="secondary"
                      />
                      <OpenSidebarButton
                        caseId={event.refDossier?.refComplete}
                        variant="outlined"
                        color="secondary"
                      />
                      <GoToCasePageButton
                        caseId={event.refDossier?.refComplete}
                        variant="outlined"
                        color="secondary"
                      />
                      <OpenQuickActionsButton
                        caseId={event.refDossier?.refComplete}
                        color="secondary"
                      />
                    </>
                  )
                }
              </CaseButtonsContainer>
            </CaseContainer>
        }
      </CardContentContainer>
    </EventCard>
  )
}

export default PlanningCalendarEventDayView
