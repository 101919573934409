/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import ScrollableFiltersContainer from 'components/ScrollableFiltersContainer/ScrollableFiltersContainer'
import PlanningFilterButton from './PlanningFilterButton'

/* Type imports ------------------------------------------------------------- */
import type { TypeRdv } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface PlanningFiltersProps {
  filters: TypeRdv[];
  selectedFilters: string[];
  setSelectedFilters: (filter: ((pPrevVal: string[]) => string[])) => void;
}

const PlanningFilters: React.FC<PlanningFiltersProps> = ({ selectedFilters, setSelectedFilters, filters }) => {
  const getCleanLibelle = (libelle: string): string => {
    return libelle.split(' (')[0].replace('RDV', 'Rdv').replace('Rendez-vous', 'Rdv')
  }

  const onClickedFilter = (pClickedFilter: string): React.MouseEventHandler<HTMLButtonElement> => () => {
    if (selectedFilters.includes(pClickedFilter)) {
      setSelectedFilters((pPrevVal: string[]) => pPrevVal.filter( (pFilter: string) => pFilter !== pClickedFilter))
    } else {
      setSelectedFilters( (pPrevVal: string[]) => [ ...pPrevVal, pClickedFilter ])
    }
  }

  return (
    <ScrollableFiltersContainer>
      {
        filters.map((pFilter: TypeRdv): React.ReactNode => (
          <PlanningFilterButton
            key={pFilter.code}
            color={pFilter.color}
            text={getCleanLibelle(pFilter.libelle)}
            selected={selectedFilters.includes(pFilter.code)}
            onClick={onClickedFilter(pFilter.code)}
          />
        ))
      }
    </ScrollableFiltersContainer>
  )
}

export default PlanningFilters
