/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Component declaration ---------------------------------------------------- */
const ActionTitle = styled(FormBoldTitle)`
  font-size: 1.15rem;
`

export default ActionTitle
