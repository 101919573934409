/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Tab,
  Tabs as MuiTabs,
} from '@mui/material'

/* Styled components -------------------------------------------------------- */
const TabsContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  box-shadow: inset 0px -2px 0px 0px ${(props) => props.theme.colors.grey};
  -moz-box-shadow: inset 0px -2px 0px 0px ${(props) => props.theme.colors.grey};
  -webkit-box-shadow: inset 0px -2px 0px 0px ${(props) => props.theme.colors.grey};
`

/* Component declaration ---------------------------------------------------- */
interface TabsProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onChange'> {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabs: React.ReactNode[];
}

const Tabs: React.FC<TabsProps> = ({ value, onChange, tabs, ...rest }) => {
  return (
    <TabsContainer {...rest}>
      <MuiTabs
        value={value}
        onChange={onChange}
      >
        {
          tabs.map((label, index) => (
            <Tab
              key={`${label?.toString()}-${index}`}
              label={label}
            />
          ))
        }
      </MuiTabs>
    </TabsContainer>
  )
}

export default Tabs
