/* Type imports ------------------------------------------------------------- */
import {
  TypeDeReparation,
  Taxe,
  ReparabiliteMob,
  Justifications,
  TypeDeBien,
  TypePersonne,
  Responsabilite,
  ActionRecours,
  OuiNonIndetermine,
} from 'API/__generated__/Api'
import type {
  Dommage,
  RecoursTraveller,
  ReglementTraveller,
} from 'API/__generated__/Api'
import type {
  NewDommageRequest,
  NewPaymentRequest,
  NewPersonType,
  TravelerAndUtils,
} from 'types/TravelerForm'

/* TravelerFormDefaultValues  ----------------------------------------------- */
const newDamage: Dommage & NewDommageRequest = {
  id: '',
  personneConcernee: '',
  typeBien: TypeDeBien.Immobilier,
  typeDeReparation: { code: TypeDeReparation.AR, libelle: 'Auto Reparation' },
  libelle: '',
  valeur: {
    quantite: 0,
    uniteMesure: undefined, // CodeLabel
    prixUnitaire: 0,
    taxe: Taxe.TTC,
    tauxTva: { taux: 0, tva: { code: '', libelle: '' }},
    montantHT: 0,
    montantTTC: 0,
    montantTva: 0,
  },
  vetuste: {
    tauxVetuste: 0,
    age: 0,
    tauxLimiteVetuste: 0,
    dateAchat: null,
    montantVetuste: 0,
    montantVetusteRecuperable: 0,
    montantVetusteDeduite: 0,
    reparation: {
      reparabilite: { code: ReparabiliteMob.Reparable, libelle: ReparabiliteMob.Reparable },
      justificatif: { code: Justifications.SJ, libelle: Justifications.SJ },
    },
  },
  pieceCompteur: -1,
  natureDuBien: undefined, // CodeLabel
  detailDuBien: undefined, // CodeLabel
  pieceLibelle: '',
}

const newPerson: NewPersonType = {
  id: '',
  tiersLie: '',
  type: TypePersonne.Intervenant,
  libelle: '',
  nom: '',
  prenom: '',
  adresse: {
    adresse1: '',
    adresse2: '',
    adresse3: '',
    codePostal: '',
    ville: '',
    digicode: '',
    pays: '',
  },
  professionnel: false,
  police: '',
  reference: '',
  profession: '',
  qualite: undefined, // CodeLabel
  politesse: undefined, // CodeLabel
  role: undefined, // CodeLabel
  mandant: false,
  intervenants: [],
  dommagesMobilierDivers: [],
  pieces: [],
  recours: [],
  reglements: [],
  saisieRapide: true,
  canBeDeleted: true,
  qualifiants: [],
  indemnisation: {
    indemnisations: [],
    dommagesSinapps: [],
    dommagesBienSinapps: [],
    indemnisationsSinapps: [],
  },
  responsabiliteRecours: {
    responsabilite: Responsabilite.Aucune,
    tauxResponsabilite: 0,
    typeResponsabilites: [],
    lettreAcceptation: undefined, // CodeLabel
  },
  acteurStatus: {
    acteurId: '',
    convoque: false,
    present: true,
    represente: false,
    representePar: '',
  },
  recherche: {
    code: '',
    departement: '',
    telephone: '',
    ville: '',
    famille: '',
    pro: OuiNonIndetermine.Indetermine,
  },
}

const newRecourse: RecoursTraveller = {
  cibleRecours: {
    assureurCible: {
      id: '',
      nom: '',
      cabinet: '',
      refExpert: '',
    },
    id: '',
    nom: '',
    police: '',
  },
  compteur: 0,
  montant: 0,
  type: ActionRecours.Recours,
  aSubir: true,
  guid: '',
  neuf: true,
  idSinapps: '',
  motif: '',
}

const newPayment: ReglementTraveller & NewPaymentRequest = {
  acteurId: '',
  commentaire: '',
  id: '',
  idSinapps: '',
  recupTVA: undefined,
  type: undefined, // CodeLabel
  garantiesReglement: [],
  surPlace: true,
  emetteur: '',
  numeroCheque: '',
  dateReglement: new Date().toISOString(),
  codeTireur: '',
}

const initialTravelerValues: Partial<TravelerAndUtils> = {
  newDamage,
  newPayment,
  newPerson,
  newRecourse,
  disabled: false,
}

export type InitialTravelerType = typeof initialTravelerValues
export default initialTravelerValues
