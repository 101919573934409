/* Module imports ----------------------------------------------------------- */
import {
  createSinappsGUID,
  getGarantie,
} from './helpers/indemnisationSinnaps'

/* Type imports ------------------------------------------------------------- */
import {
  TypeDommageSinapps,
  type Dommage,
  type DommageSinapps,
  type Indemnisation,
  type CodeLabel,
} from './types/DommageIndemnisation'

/* Function declarations ---------------------------------------------------- */
export const getListeDommagesSinapps = (dommages: Dommage[], indemnisations: Indemnisation[], existingDommagesSinapps: DommageSinapps[]): DommageSinapps[] => {
  const dommagesSinapps: DommageSinapps[] = []
  const natureSinappsGUIDdictionary: { [key: string]: string } = {}

  dommages.forEach((dmg) => {
    if (dmg.sinapps.typeDommageSinapps === TypeDommageSinapps.Dommage && dmg.valeur.montantTTC > 0) {
      if (!natureSinappsGUIDdictionary[dmg.sinapps.natureDuBienSinapps.code]) {
        natureSinappsGUIDdictionary[dmg.sinapps.natureDuBienSinapps.code] = createSinappsGUID()
      }

      dommagesSinapps.push({
        ...dmg,
        ...dmg.valeur,
        ...dmg.vetuste,
        tauxTva: dmg.valeur.tauxTva.taux,
        montantVetusteHT: dmg.vetuste.montantVetuste / (1 + dmg.valeur.tauxTva.taux / 100),
        montantVetusteTTC: dmg.vetuste.montantVetuste,
        montantVetusteRecuperableHT: dmg.vetuste.montantVetusteRecuperable / (1 + dmg.valeur.tauxTva.taux / 100),
        montantVetusteRecuperableTTC: dmg.vetuste.montantVetusteRecuperable,
        montantVetusteDeduiteHT: dmg.vetuste.montantVetusteDeduite / (1 + dmg.valeur.tauxTva.taux / 100),
        montantVetusteDeduiteTTC: dmg.vetuste.montantVetusteDeduite,
        plafond: existingDommagesSinapps.find((d) => d.idDetailDommageSinapps === dmg.sinapps.idDetailDommageSinapps)?.plafond,
        idDommageSinapps: natureSinappsGUIDdictionary[dmg.sinapps.natureDuBienSinapps.code],
        idDetailDommageSinapps: dmg.sinapps.idDetailDommageSinapps || createSinappsGUID(),
        garantie: getGarantie(dmg, indemnisations),
        modeIndemnisation: dmg.typeDeReparation as CodeLabel,
        natureDuBien: dmg.sinapps.natureDuBienSinapps,
        detailDuBien: dmg.sinapps.detailDuBienSinapps,
      })
    }
  })

  return dommagesSinapps
}
