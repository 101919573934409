/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import { Tooltip } from '@mui/material'
import BackButton from 'components/BackButton/BackButton'
import OpenSidebarButton from 'components/IconButtons/OpenSidebarButton/OpenSidebarButton'
import OpenQuickActionsButton from 'components/IconButtons/OpenQuickActionsButton/OpenQuickActionsButton'
import GoToCasePageButton from 'components/IconButtons/GoToCasePageButton/GoToCasePageButton'
import ReportObjectiveButton from 'components/ReportObjectiveButton/ReportObjectiveButton'
import DownloadOfflineCaseButton from 'components/IconButtons/DownloadOfflineCaseButton/DownloadOfflineCaseButton'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import HeaderContainer from './HeadersComponents/HeaderContainer'
import HeaderGroupContainer from './HeadersComponents/HeaderGroupContainer'

/* Type imports ------------------------------------------------------------- */
import type { Pastille } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface BigHeaderContainerProps {
  hasChips?: boolean;
}

const BigHeaderContainer = styled(HeaderContainer)<BigHeaderContainerProps>`
  min-height: ${(props) => props.hasChips ? '82px' : 'undefined'};
`

const ChipsContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-top: -5px;
  text-transform: initial;
`

const TooltipText = styled.div`
  font-size: 1rem;
`

const ButtonTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -3px;
`

/* Component declaration ---------------------------------------------------- */
interface HeaderWithBackArrowTwoSmallButtonsProps {
  title: string;
  caseId: string;
  caseButton?: boolean;
  onClickBack: React.MouseEventHandler<HTMLButtonElement>;
  reportObjective?: string | null;
  company?: string;
  caseChips?: Pastille[];
}

const HeaderWithBackArrowTwoSmallButtons: React.FC<HeaderWithBackArrowTwoSmallButtonsProps> = ({
  title,
  caseId,
  caseButton = false,
  onClickBack,
  reportObjective,
  company,
  caseChips = [],
}) => {

  return (
    <BigHeaderContainer hasChips={caseChips.length > 0}>
      <HeaderGroupContainer>
        <BackButton
          id="header-back-button"
          onClick={onClickBack}
        >
          <ButtonTitleContainer>
            {title}
            <ChipsContainer>
              {
                caseChips.map((chip, index) => (
                  <Tooltip
                    key={`${chip.tooltip}-${index}`}
                    placement="bottom-end"
                    arrow
                    title={
                      <TooltipText>
                        {chip.tooltip}
                      </TooltipText>
                    }
                  >
                    <ColoredSquareChip
                      color="salmon"
                      customColor={chip.couleur}
                      smaller
                    >
                      {chip.typePastille?.libelle}
                    </ColoredSquareChip>
                  </Tooltip>
                ))
              }
            </ChipsContainer>
          </ButtonTitleContainer>
        </BackButton>
      </HeaderGroupContainer>
      <HeaderGroupContainer>
        {
          isValidString(reportObjective) &&
            <ReportObjectiveButton
              reportObjective={reportObjective}
              company={company || ''}
            />
        }
        <DownloadOfflineCaseButton
          caseId={caseId}
          variant="outlined"
          color="secondary"
        />
        <OpenSidebarButton
          caseId={caseId}
          variant="outlined"
          color="secondary"
        />
        {
          caseButton && (
            <GoToCasePageButton
              caseId={caseId}
              variant="outlined"
              color="secondary"
            />
          )
        }
        <OpenQuickActionsButton
          caseId={caseId}
          color="secondary"
        />
      </HeaderGroupContainer>
    </BigHeaderContainer>
  )
}

export default HeaderWithBackArrowTwoSmallButtons
