/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseEventDocumentsQuery,
  useGetEventDocumentsFamilleActionListQuery,
  usePostNewEventDocumentMutation,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import {
  useAppDispatch,
  useSelectedReport,
} from 'store/hooks'
import { setCourriers } from 'store/slices/courrierSlice'
import { useIsConnected } from 'helpers/hooks/useIsConnected'
import { isApiResponse } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { Select } from 'formik-mui'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import CheckableCollapseCard from 'components/CheckableCollapseCard/CheckableCollapseCard'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import DocumentList from 'pages/QuickActionPages/QaCourrierPage/DocumentList'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikHelpers,
  FormikContextType,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { ApiResponse } from 'helpers/fetchHelpers'
import { DocumentTypes } from 'types/Document'
import type { DataDocument } from 'API/__generated__/Api'
import {
  TypeEnregistrementCourrier,
  TypeEvenementiel,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface CourrierProperties {
  codeAction: string;
  selected: boolean;
}

interface CourrierRequest {
  courriers: CourrierProperties[];
}

const courrierSchema = Yup.object().shape<Shape<CourrierRequest>>({
  courriers: Yup.array(Yup.object().shape<Shape<CourrierProperties>>({
    codeAction: Yup.string().when('selected', {
      is: true,
      then: (schema) => schema.required('Le type de courrier est obligatoire'),
    }),
    selected: Yup.boolean(),
  })).required(),
}).required()

type NewCourrierForm = FormikContextType<CourrierRequest>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const GenerateButtonContainer = styled.div`
  text-align: center;

  button {
    width: 120px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface DocumentsModalProps {
  open: boolean;
  handleClose: () => void;
  disabled: boolean;
  mandant: string;
}

const DocumentsModal: React.FC<DocumentsModalProps> = ({
  open,
  handleClose,
  disabled,
  mandant,
}) => {
  const isConnected = useIsConnected()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { caseId = '' } = useParams<{caseId: string}>()
  const selectedReport: string | null = useSelectedReport(caseId)

  const {
    currentData: eventDocuments = [],
    isFetching: isFetchingEventDocuments,
  } = useGetCaseEventDocumentsQuery({ dossier: caseId, avecBrouillons: true })
  const {
    currentData: familleActionList = [],
    isFetching: isFetchingFamilleActionList,
  } = useGetEventDocumentsFamilleActionListQuery({ dossier: caseId, filtreEvenementiel: TypeEvenementiel.Rapport })
  const [
    submitNewDocument,
  ] = usePostNewEventDocumentMutation()

  const sendCourriers = async (courriers: CourrierProperties[]) => {
    const allData: DataDocument[] = []
    for (let index = 0; index < courriers.length; index++) {
      if (courriers[index].selected) {

        const response: ApiResponse<DataDocument> = await submitNewDocument({
          dossier: caseId,
          data: {
            codeAction: courriers[index].codeAction,
            compteurSequence: parseInt(selectedReport?.split('-')[selectedReport?.split('-').length - 1] || '1') || 1,
            destinataire: { id: mandant },
            typeEnregistrement: TypeEnregistrementCourrier.Visualisation,
          },
        })
        if (isApiResponse<DataDocument>(response)) {
          allData.push(response.data)
        } else {
          toast.error(`Une erreur est survenue lors du chargement du document ${DocumentTypes[index].libelle}.`)
        }
      }
    }
    if (allData.length > 0) {
      dispatch(setCourriers(allData))
      navigate(`/dossiers/${caseId}/traveller/actions/document`)
    }
  }

  const onSubmit = (values: CourrierRequest, { setSubmitting }: FormikHelpers<CourrierRequest>): void => {
    sendCourriers(values.courriers).then(() => setSubmitting(false)).catch(console.error)
  }

  const formikForm: NewCourrierForm = useForm<CourrierRequest>(
    {
      initialValues: {
        courriers: DocumentTypes.map(() => ({ codeAction: '', selected: false })),
      },
      validationSchema: courrierSchema,
      onSubmit: onSubmit,
    },
  )

  const isLoading = useMemo(() => isFetchingEventDocuments || isFetchingFamilleActionList, [ isFetchingEventDocuments, isFetchingFamilleActionList ])

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      maxWidth="md"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Documents
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContent>
          <LoadingOverlay isLoading={isLoading}>
            {
              [ ...eventDocuments ]
                .filter((value) => DocumentTypes.some((type) => type.code === value.familleAction?.code))
                .sort((a, b) => b.dateCreation?.localeCompare(a.dateCreation || '') || 0)
                .map((value, index) => (
                  <DocumentList
                    key={`${value.id}-${index}`}
                    document={value}
                    caseId={caseId}
                    isConnected={isConnected}
                  />
                ))
            }
            {
              DocumentTypes.map((value, index) => (
                <CheckableCollapseCard
                  key={value.code}
                  checked={formikForm.values.courriers?.[index]?.selected || false}
                  onChange={() => formikForm.setFieldValue(`courriers[${index}].selected`, !formikForm.values.courriers[index].selected)}
                  label={value.libelle}
                  addType
                >
                  <Field
                    component={Select}
                    name={`courriers[${index}].codeAction`}
                    size="small"
                    disabled={disabled}
                    displayEmpty
                    renderValue={verifySelectFieldValue(formikForm.values.courriers[index]?.codeAction)}
                  >
                    {
                      familleActionList.find((famille) => famille.code === value.code)?.actions?.map((value, index) => (
                        <MenuItem
                          value={value.code}
                          key={`${value.code}-${index}`}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </CheckableCollapseCard>
              ))
            }
            <GenerateButtonContainer>
              <Button
                variant="contained"
                type="submit"
                disabled={disabled || !formikForm.values.courriers.some((courrier) => courrier.selected === true) || formikForm.isSubmitting}
              >
                {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Générer'}
              </Button>
            </GenerateButtonContainer>
          </LoadingOverlay>
        </DialogContent>
      </Form>
    </Dialog>
  )
}

export default DocumentsModal
