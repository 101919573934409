/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useAppDispatch,
  useSelectedReport,
} from 'store/hooks'
import {
  setCaseReportSummary,
  setCaseSelectedReport,
} from 'store/slices/travelerSlice'
import {
  useLazyGetCaseReportsQuery,
  usePostNewReportMutation,
} from 'store/api'
import { isApiResponse } from 'helpers/fetchHelpers'
import { useIsConnected } from 'helpers/hooks/useIsConnected'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Collapse,
} from '@mui/material'
import HeaderContainer from 'layouts/MainLayout/Headers/HeadersComponents/HeaderContainer'
import ReportMenuItem from './ReportMenuItem/ReportMenuItem'

/* Type imports ------------------------------------------------------------- */
import {
  type Traveller,
  type RapportResume,
  EtatRapport,
} from 'API/__generated__/Api'
import { toast } from 'react-toastify'

/* Styled components -------------------------------------------------------- */
const ClickableNav = styled.nav`
  cursor: pointer;
`

interface ReportMenuCollapseProps {
  maxheight?: number;
}

const ReportMenuCollapse = styled(Collapse)<ReportMenuCollapseProps>`
  position: absolute;
  width: 100%;
  overflow-y: scroll;
  z-index: 204;
  max-height: ${(props) => props.maxheight};
`

const NewReportButton = styled(Button)`
  margin-right: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface ReportMenuProps {
  caseId: string;
  children?: React.ReactNode;
  reports: RapportResume[];
}

const ReportMenu: React.FC<ReportMenuProps> = ({ caseId, reports, children }) => {
  const dispatch = useAppDispatch()
  const isConnected = useIsConnected()
  const selectedReport: string | null = useSelectedReport(caseId)
  const [ selectedReportItemRef, setSelectedReportItemRef ] = useState<HTMLDivElement | null>(null)
  const [ menuOpen, setMenuOpen ] = useState<boolean>(false)

  const [ submitNewReport ] = usePostNewReportMutation()
  const [ getCaseReportList ] = useLazyGetCaseReportsQuery()

  const onMenuClick: React.MouseEventHandler<HTMLDivElement> = () => {
    setMenuOpen(!menuOpen)
  }

  const onCreateReportClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      const data = await submitNewReport(caseId)
      if (isApiResponse<Traveller>(data)) {
        const response = await getCaseReportList(caseId)
        if (isApiResponse<RapportResume[]>(response)) {
          response.data.forEach((report) => {
            dispatch(setCaseReportSummary({ caseId, reportId: report.id, summary: report }))
          })
          dispatch(setCaseSelectedReport({ caseId, selectedReport: response.data[response.data.length - 1].id }))
          const lastReportId = [ ...response.data ].reverse()[0].id
          dispatch(setCaseSelectedReport({ caseId, selectedReport: lastReportId }))
          setMenuOpen(!menuOpen)
        } else {
          toast.error('Erreur lors de la récupération du nouveau rapport.')
        }
      } else {
        toast.error("Erreur lors de la création d'un nouveau rapport.")
      }
    } catch(error) {
      toast.error("Erreur lors de la création d'un nouveau rapport.")
      console.error(error)
    }
  }

  useEffect(() => {
    setMenuOpen(false)
  }, [ selectedReport ])

  if (reports.length === 0) {
    return (
      <nav>
        <ReportMenuItem caseId={caseId}>
          <NewReportButton
            variant="contained"
            onClick={onCreateReportClick}
            disabled={!isConnected}
          >
            Nouveau Rapport
          </NewReportButton>
        </ReportMenuItem>
      </nav>
    )
  }

  return (
    <ClickableNav>
      <HeaderContainer
        onClick={onMenuClick}
        ref={setSelectedReportItemRef}
      >
        {
          selectedReport &&
            <ReportMenuItem
              caseId={caseId}
              reportId={selectedReport}
              selected
              isOpen={menuOpen}
              reportStatus={reports.find((report) => report.id === selectedReport)?.etat?.code}
            >
              {children}
            </ReportMenuItem>
        }
      </HeaderContainer>
      <ReportMenuCollapse
        in={menuOpen}
        maxheight={selectedReportItemRef !== null ? 9 * selectedReportItemRef.clientHeight : undefined}
      >
        {
          reports.filter((pReport) => pReport.id !== selectedReport)
            .map((pReport, pIndex: number) => (
              <ReportMenuItem
                caseId={caseId}
                key={`report-menu-item-${pReport.id}-index-${pIndex}`}
                reportId={pReport.id}
                reportStatus={reports.find((report) => report.id === pReport.id)?.etat?.code}
              />
            ))
        }
        {
          !reports.find((report) => report.etat.code !== EtatRapport.Cloture) &&
            <ReportMenuItem
              caseId={caseId}
              reportId=""
            >
              <NewReportButton
                variant="contained"
                onClick={onCreateReportClick}
              >
                Nouveau Rapport
              </NewReportButton>
            </ReportMenuItem>
        }
      </ReportMenuCollapse>
    </ClickableNav>
  )
}

export default ReportMenu
