/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import ActionTitle from 'components/ActionTitle/ActionTitle'
import HeaderContainer from './HeaderContainer'

/* Styled components -------------------------------------------------------- */
const HeaderDiv = styled.div`
  margin-left: auto;
  margin-right: 1rem;
`

/* Component declaration ---------------------------------------------------- */
interface HeaderTravelerActionProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

const HeaderTravelerAction: React.FC<HeaderTravelerActionProps> = ({ title, children }) => {
  return (
    <HeaderContainer justifyContent="start">
      <ActionTitle>
        {title}
      </ActionTitle>
      <HeaderDiv>
        {children}
      </HeaderDiv>
    </HeaderContainer>
  )
}

export default HeaderTravelerAction
