/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Collapse,
} from '@mui/material'
import {
  setCaseIsReadyForOffline,
  type TravelerCase,
} from 'store/slices/travelerSlice'
import { useAppDispatch } from 'store/hooks'
import OfflineDataManagerReport from './OfflineDataManagerReport'

/* Styled components -------------------------------------------------------- */
const CaseTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
`

const Container = styled.div`
  margin-bottom: 20px;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface OfflineDataManagerCaseProps {
  caseId: string;
  travelerCase: TravelerCase;
}

const OfflineDataManagerCase: React.FC<OfflineDataManagerCaseProps> = ({
  caseId,
  travelerCase,
}) => {
  const [ expanded, setExpanded ] = useState<boolean>(true)
  const dispatch = useAppDispatch()

  const onClearCase = () => {
    dispatch(setCaseIsReadyForOffline({ caseId, isReadForOffline: false }))
  }

  return (
    <Container>
      <CaseTitle onClick={() => setExpanded(!expanded)}>
        {caseId}
        <ButtonsContainer onClick={(e) => e.stopPropagation()}>
          <Button
            variant="contained"
          >
            Synchroniser
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={onClearCase}
          >
            Enlever du cache
          </Button>
        </ButtonsContainer>
      </CaseTitle>
      <Collapse
        in={expanded}
      >
        {
          Object.entries(travelerCase.reports).map(([ reportId ]) => (
            <OfflineDataManagerReport
              key={reportId}
              reportId={reportId}
              travelerReport={travelerCase.reports[reportId]}
            />
          ))
        }
      </Collapse>
    </Container>
  )
}

export default OfflineDataManagerCase
